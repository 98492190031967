//Dependencies
import React from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

//Assets
import "../stylesheets/PageLoader.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PageLoader = ({ loading }) => {
  return (
    <div className="loader-section">
      <PuffLoader
        color={"#e20713"}
        loading={loading}
        css={override}
        size={80}
      />
      <h2>Cargando...</h2>
    </div>
  );
};

export default PageLoader;
