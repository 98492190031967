//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setCurrencies,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearCurrencies,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "currencies";

const Currencies = (props) => {
  const {
    setHasMore,
    setNextPage,
    setCurrencies,
    setIsLoading,
    clearCurrencies,
  } = props;

  const { id } = useParams();
  const CURRENCIES_URL = getAll.currencies(id);

  useEffect(() => {
    clearCurrencies();
    setIsLoading(true);
    axios
      .get(CURRENCIES_URL)
      .then((res) => {
        const { currencies } = res.data;

        setCurrencies(currencies);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setCurrencies,
  clearCurrencies,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(Currencies);
