//Dependencies
import React from "react";

//Components
import CountryForm from "../components/CountryForm";

const CountryNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo País</h3>
      </div>
      <CountryForm action={"create"} />
    </section>
  );
};

export default CountryNew;
