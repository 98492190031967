//Dependencie
import React, { useEffect } from "react";
import { connect } from "react-redux";

//Assets
import * as actions from "../actions";

//Utils
import { SetToggleAttribute } from "../utils/SetToggleAttribute";
import { isBranchUser } from "../utils/managment";

const ButtonToggle = (props) => {
  const { data, entity, att, filteredItems, loggedUser, activeMenu } = props;
  const canUpdateByRole =
    (isBranchUser(loggedUser.role) &&
      Number(loggedUser.branch_id) === Number(data.branch_id)) ||
    !isBranchUser(loggedUser.role);

  const handleChangeAttribute = () => {
    if (canUpdateByRole) {
      data[att] = !data[att];
      if (loggedUser.restaurant_id) {
        data.restaurant_id = loggedUser.restaurant_id;
      }
      if (loggedUser.branch_id) {
        data.branch_id = loggedUser.branch_id;
      }

      const canEditBranch =
        loggedUser.role === "super_admin" && entity === "branches";
      if (canEditBranch || entity !== "branches") {
        SetToggleAttribute(
          data,
          entity,
          att,
          filteredItems,
          props,
          loggedUser.role,
        );
      }
    }
  };

  const handleClassName = () => {
    return data[att] ? "toggle-button_green" : "toggle-button_red";
  };

  const handleButtonText = (att) => {
    let buttonText;

    switch (att) {
      case "is_visible":
        buttonText = data.is_visible ? "VISIBLE" : "OCULTO";
        break;
      case "active":
        buttonText = data.active ? "ACTIVO" : "INACTIVO";
        break;
      case "is_recommended":
        buttonText = data.is_recommended ? "SI" : "NO";
        break;
      case "branch_id":
        buttonText = data.branch_id ? "SUCURSAL" : "RESTAURANT";
        break;
      default:
        break;
    }

    return buttonText;
  };

  useEffect(() => {}, [activeMenu]);

  return (
    <span onClick={() => handleChangeAttribute()} className={handleClassName()}>
      {handleButtonText(att)}
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredItems: state.filteredItems,
    loggedUser: state.loggedUser,
    activeMenu: state.activeMenu,
  };
};

export default connect(mapStateToProps, actions)(ButtonToggle);
