//Dependencies
import React from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

//Assets
import "../stylesheets/spinner.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = ({ loading }) => {
  return (
    <div className="spinner-section">
      <PuffLoader
        color={"#e20713"}
        loading={loading}
        css={override}
        size={60}
      />
    </div>
  );
};

export default Spinner;
