//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TimePicker from "rc-time-picker";
import moment from "moment";
import Select from "react-select";

//Assets
import "rc-time-picker/assets/index.css";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import ClearIcon from "@material-ui/icons/Clear";

//Utils
import { days_options } from "../utils/selectValues";
import * as actions from "../actions";

const ScheduleItem = ({
  item,
  itemIndex,
  setValue,
  setScheduleItems,
  scheduleItems,
  removeSchedule,
  schedules,
  register,
  readOnly = false,
}) => {
  const { t, i18n } = useTranslation();
  const [scheduleDaySelect, setScheduleDaySelect] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);
  const [dayFrom, setDayFrom] = useState();
  const [dayTo, setDayTo] = useState();

  useEffect(() => {
    const daysTransformed = dayOptions.filter((op) => op.value == item.day);
    setScheduleDaySelect(daysTransformed);
    setDayFrom(moment(item.from || "08:00:00", "hh:mm:ss"));
    setDayTo(moment(item.to || "23:00:00", "hh:mm:ss"));
    setValue("day", item.day);
    setValue("from", moment(item.from || "08:00:00", "hh:mm:ss"));
    setValue("to", moment(item.to || "23:00:00", "hh:mm:ss"));
  }, [dayOptions, item, schedules]);

  useEffect(() => {
    setDayOptions(days_options?.map((day) => ({
                label: t(`commons.days.${day.label}`),
                value: day.value,
              })))
  }, [i18n.language])

  const handleChange = (value, action, setStateFunction = null) => {
    const inputRef = action?.name || value.target.name;

    if (setStateFunction) setStateFunction(value);

    let itemsUpdated = [...scheduleItems];
    const index = itemsUpdated.findIndex((i) => {
      if (item.id) {
        return i.id === item.id;
      } else {
        return i.auxId === item.auxId;
      }
    });
    itemsUpdated[index][inputRef] = value.format
      ? value.format("HH:mm:ss")
      : value.value;

    setValue(inputRef, value.format ? value.format("HH:mm:ss") : value.value);
    setScheduleItems([...itemsUpdated]);
  };

  const handleRemoveItem = (auxItemId) => {
    const newItems = scheduleItems.map((item) => {
      if (item.id === auxItemId || item.auxId === auxItemId) {
        item._destroy = true;
      }
      return item;
    });
    setScheduleItems([...newItems]);
  };

  return (
    <>
      <section className="row">
        <section className="schedule-item">
          <section className="form-group w-30">
            <Select
              name="day"
              isDisabled={readOnly}
              value={scheduleDaySelect}
              placeholder={t("commons.placeholders.select")}
              options={dayOptions}
              onChange={(value, action) => {
                handleChange(value, action, setScheduleDaySelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
          <section className="w-30 mb-12">
            <TimePicker
              disabled={readOnly}
              showSecond={false}
              name="from"
              value={dayFrom}
              onChange={(value) => {
                handleChange(value, { name: "from" }, setDayFrom);
              }}
            />
          </section>
          <section className="w-30 mb-12">
            <TimePicker
              showSecond={false}
              disabled={readOnly}
              name="to"
              value={dayTo}
              onChange={(value) => {
                handleChange(value, { name: "to" }, setDayTo);
              }}
            />
          </section>
          {!readOnly && (
            <section
              className="row clear-icon__container"
              onClick={() => handleRemoveItem(item.id || item.auxId)}
            >
              <ClearIcon />
            </section>
          )}
        </section>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    schedules: state.schedules,
  };
};

export default connect(mapStateToProps, actions)(ScheduleItem);
