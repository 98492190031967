// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "../components/ImageUploader";

//Assets
import "../stylesheets/AccompanimentForm.css";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import * as actions from "../actions";

//Utils
import { create, update } from "../utils/services";
import { is_visible_options } from "../utils/selectValues";
import {
  modalAdvertisingSuccessful,
  modalAdvertisingError,
} from "../utils/modals";

const ENTITY = "advertisings";

const AdvertisingForm = (props) => {
  const {
    id,
    image,
    branch_id,
    name,
    translations,
    is_visible,
    url,
    button_text,
    action,
    updateAdvertising,
    loggedUser,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.branch_id;
  const readOnly = loggedUser.role === "branch_user" && !branch_id && !!id;
  const [advertisingImage, setAdvertisingImage] = useState(image);
  const [required, setRequired] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState([
    { value: 1, label: "Si" },
  ]);
  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    const advertising = {
      ...data,
      restaurant_id: RESTAURANT_ID,
      branch_id: BRANCH_ID,
      is_visible: isVisibleSelect[0]?.value || isVisibleSelect?.value,
      translations_attributes: [
        {
          att: "name",
          id: translations
            ? translations.find(
                (t) => t.att === "name" && t.locale === loggedUser.primary_lang,
              )?.id
            : null,
          description: data.name,
          locale: loggedUser.primary_lang,
        },
        {
          att: "button_text",
          id: translations
            ? translations.find(
                (t) =>
                  t.att === "button_text" &&
                  t.locale === loggedUser.primary_lang,
              )?.id
            : null,
          description: data.buttonText,
          locale: loggedUser.primary_lang,
        },
      ],
    };
    if(!advertisingImage) {
      setRequired(true)
    }
    else {
      showLoadingOverlay(true);
      if (action === "create") {
        axios
          .post(create[ENTITY](RESTAURANT_ID), { advertising })
          .then(() =>
            modalAdvertisingSuccessful("creado", data.name, t, () =>
              history.goBack(),
            ),
          )
          .then(() => e.target.reset())
          .catch(() => modalAdvertisingError("crear", data.name, t))
          .finally(() => showLoadingOverlay(false));
      } else if (action === "edit") {
        axios
          .put(update[ENTITY](RESTAURANT_ID, id), { advertising })
          .then(() => {
            data.id = id;
            updateAdvertising(advertising);
          })
          .then(() =>
            modalAdvertisingSuccessful("actualizado", data.name, t, () =>
              history.goBack(),
            ),
          )
          .catch(() => {
            modalAdvertisingError("actualizar", data.name, t);
          })
          .finally(() => showLoadingOverlay(false));
      }
    }
  };

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value),
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("is_visible");
    register("url");
    register("name");
    register("buttonText");
    register("image");

    if (action === "edit") {
      const isVisibleTransformed = is_visible_options.filter(
        ({ value }) => value == is_visible,
      );

      setIsVisibleSelect(isVisibleTransformed);
    }
  }, []);

  useEffect(() => {
    if(advertisingImage) {
      setRequired(false)
    }
  }, [advertisingImage])

  return (
    <section>
      <form
        className="cartear-accompaniment-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <section className="row">
          <section style={{ width: "30vw", color: "red", fontWeight: "bold" }}>
            {t("commons.imageUploader.advertising.warning")}
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
              setCategoryImage={setAdvertisingImage}
              readOnly={readOnly}
              required={required}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label className="cartear-form-label" htmlFor="is_visible">
              {t("forms.advertising.isVisible")}
            </label>
            <Select
              isDisabled={readOnly}
              name="is_visible"
              value={isVisibleSelect}
              placeholder={t("commons.placeholders.select")}
              options={is_visible_options}
              onChange={(value, action) => {
                handleChange(value, action, setIsVisibleSelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
        </section>
        <section className="accompaniment-form-container">
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label">
                {t("forms.advertising.name")}
              </label>
              <input
                readOnly={readOnly}
                name="name"
                maxLength={50}
                className="form-control cartear-form-input"
                defaultValue={name}
                ref={register({
                  required: true,
                  message: t("forms.inputRequired"),
                })}
              />
            </section>
          </section>
        </section>
        <section className="accompaniment-form-container">
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label">
                {t("forms.advertising.buttonText")}
              </label>
              <input
                readOnly={readOnly}
                name="buttonText"
                maxLength={50}
                className="form-control cartear-form-input"
                defaultValue={button_text}
                ref={register}
              />
            </section>
          </section>
        </section>
        <section className="accompaniment-form-container">
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label">
                {t("forms.advertising.url")}
              </label>
              <input
                readOnly={readOnly}
                name="url"
                maxLength={50}
                className="form-control cartear-form-input"
                defaultValue={url}
                ref={register}
              />
            </section>
          </section>
        </section>
        <section className="row">
          {!readOnly && (
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value={t("commons.buttons.save")}
              />
            </section>
          )}
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(AdvertisingForm);
