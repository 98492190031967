const commons = {
  commons: {
    actions: {
      edit: "edit",
      edited: "edited",
      create: "create",
      created: "created",
    },
    imageUploader: {
      product: {
        warning: "It prefer square image with ratio 1:1",
      },
      category: {
        warning: "It prefer rectangle image with ratio 7:1",
      },
      advertising: {
        warning: "It prefer rectangle image with ratio 9:16",
      },
    },
    languages: {
      es: "Spanish",
      en: "Inglish",
    },
    buttons: {
      save: "SAVE",
      add: "ADD",
    },
    placeholders: {
      select: "Select",
    },
    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },
    lookAndFeel: {
      generalText: "General texts typography",
      dishesTitle: "Title dishes typography",
      descriptionDishes: "Description dishes typography",
      categories: "Categories typography",
    },
  },
};

export default commons;
