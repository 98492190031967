//Dependencies
import React from "react";
import { useParams } from 'react-router-dom';

//Components
import BranchForm from "../components/BranchForm";

const BranchNew = () => {
  const { id } = useParams();

  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva Sucursal</h3>
      </div>
      <BranchForm action={"create"} restaurant_id={id}/>
    </section>
  );
};

export default BranchNew;
