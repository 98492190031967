//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setCountries,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearCountries,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "countries";

const Countries = (props) => {
  const {
    setHasMore,
    setNextPage,
    setCountries,
    setIsLoading,
    clearCountries,
  } = props;

  const COUNTRIES_URL = getAll.countries;

  useEffect(() => {
    clearCountries();
    setIsLoading(true);
    axios
      .get(COUNTRIES_URL)
      .then((res) => {
        const { countries } = res.data;

        setCountries(countries);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setCountries,
  clearCountries,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(Countries);
