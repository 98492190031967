// Function to check if entities has next page
export const CheckHasMore = (next, last, setHasMore, setNextPage) => {
  if (next) {
    setHasMore(true);
    setNextPage(next);
  } else if (last) {
    setHasMore(true);
    setNextPage(last);
  } else {
    setHasMore(false);
  }
};
