import React, { useState, useEffect } from "react";

const OrderStatus = ({ status }) => {
  const [statusName, setStatusName] = useState("");

  useEffect(() => {
    switch (status) {
      case "pending":
        setStatusName("Pendiente");
        break;
      case "preparing":
        setStatusName("En preparación");
        break;
      case "confirmed":
        setStatusName("Finalizado");
        break;
      case "canceled":
        setStatusName("Cancelado");
        break;
    }
  }, [status]);

  return (
    <section className="order__status">
      <span className={`order__status order__status-${status}`}>
        {statusName}
      </span>
    </section>
  );
};

export default OrderStatus;
