//Dependencies
import React, { useState, useEffect } from "react";
import Select from "react-select";

//Components
import Pagos360 from "../components/Pagos360";
import Paypal from "../components/Paypal";

//Assets
import "../stylesheets/PlanAndPaymentsHire.css";
import logo from "../images/logo-login.png";

//Utils
import cartearSelectStyles from "../utils/cartearSelectStyles";

const PlanAndPaymentHire = ({
  name,
  price,
  setIndexPlan,
  id,
  plan_countries,
  plan_promotions,
  billings,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [suscriptionPeriod, setSuscriptionPeriod] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [total_price, setTotalPrice] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [paymentPlatform, setPaymentPlatform] = useState([]);

  useEffect(() => {
    let payment_platforms = [];

    plan_countries.forEach((pc) => {
      pc.payment_platforms.forEach((pp) => {
        if (!payment_platforms.includes(pp)) {
          payment_platforms.push(pp);
        }
      });
    });

    const PYMT_PLTF_XFORMED = payment_platforms.map((pp) => ({
      value: pp.toLowerCase(),
      label: pp,
    }));
    setPaymentPlatform(PYMT_PLTF_XFORMED);
  }, []);

  const suscription_periods = plan_promotions
    .sort((a, b) => (a.period > b.period ? 1 : -1))
    .map(({ id, period, discount }) => ({
      value: id,
      label: `${period} ${period > 1 ? "Meses" : "Mes"} ${
        discount > 0 ? `(${discount}% de Descuento)` : ""
      }`,
    }));

  const handleChangePaymentMethod = (value) => {
    setPaymentMethod(value.value);
  };

  const handleChangePeriod = (value) => {
    setSuscriptionPeriod(value.value);

    const prom = plan_promotions.find((pp) => pp.id == value.value);
    const total_price = parseFloat(
      plan_countries[0].price * (1 - prom.discount / 100)
    ).toFixed(2);
    const currency_symbol = plan_countries[0].currency.symbol;

    setPromotion(prom);
    setTotalPrice(total_price);
    setCurrencySymbol(currency_symbol);
  };

  return (
    <section className="container">
      <h4 className="back-button" onClick={() => setIndexPlan(-1)}>
        Volver
      </h4>
      <div className="form-title">
        <h3>{name}</h3>
      </div>
      <section className="panels-container">
        <section className="left-panel">
          <section className="row">
            <section className="form-group">
              <label>Método de Pago</label>
              <Select
                placeholder="Seleccione"
                options={paymentPlatform}
                onChange={(value, action) => {
                  handleChangePaymentMethod(value, action);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>Tiempo de Suscripción</label>
              <Select
                placeholder="Seleccione"
                options={suscription_periods}
                onChange={(value, action) => {
                  handleChangePeriod(value, action);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
          <section className="mt-48">
            {paymentMethod === "pagos360" && suscriptionPeriod && (
              <Pagos360
                total_price={parseFloat(
                  total_price * promotion?.period
                ).toFixed(2)}
                plan_country={plan_countries[0]}
                plan_name={name}
                promotion={promotion}
              />
            )}
            {paymentMethod === "paypal" && suscriptionPeriod && (
              <Paypal
                plan_country={plan_countries[0]}
                paypal_plans={billings}
                promotion={promotion}
                total_price={parseFloat(
                  total_price * promotion?.period
                ).toFixed(2)}
              />
            )}
          </section>
        </section>
        {suscriptionPeriod && (
          <section className="right-panel">
            <section className="checkout-container">
              <section className="row justify-content-center mb-32">
                <img src={logo} alt="Logo Cartear" />
              </section>
              <section>
                <section className="row">
                  <section className="form-group checkout-detail">
                    <label>Nombre del Plan </label>
                    <label className="checkout-detail__value">{name}</label>
                  </section>
                </section>
                <section className="row">
                  <section className="form-group checkout-detail">
                    <label>Cantidad de Meses </label>
                    <label className="checkout-detail__value">
                      {`${promotion?.period} ${
                        promotion?.period > 1 ? "Meses" : "Mes"
                      }`}
                    </label>
                  </section>
                </section>
                {promotion?.discount > 0 && (
                  <>
                    <section className="row">
                      <section className="form-group checkout-detail">
                        <label>Precio del Plan</label>
                        <label className="checkout-detail__value">
                          {`${currencySymbol} ${plan_countries[0].price} x ${
                            promotion?.period
                          } ${
                            promotion?.period > 1 ? "Meses" : "Mes"
                          } = ${currencySymbol} ${parseFloat(
                            plan_countries[0].price * promotion?.period
                          ).toFixed(2)}`}
                        </label>
                      </section>
                    </section>
                    <section className="row">
                      <section className="form-group checkout-detail discount">
                        <label>Ahorrás el monto de </label>
                        <label className="checkout-detail__value text-green">
                          {`${currencySymbol}
                          ${parseFloat(
                            plan_countries[0].price - total_price
                          ).toFixed(2)} x ${promotion?.period} ${
                            promotion?.period > 1 ? "Meses" : "Mes"
                          } = ${currencySymbol} ${parseFloat(
                            (plan_countries[0].price - total_price) *
                              promotion?.period
                          ).toFixed(2)}`}
                        </label>
                      </section>
                    </section>
                  </>
                )}
                <section className="row">
                  <section className="form-group checkout-detail">
                    <label>Total</label>
                    <label className="checkout-detail__value">
                      {`${currencySymbol} ${parseFloat(
                        total_price * promotion?.period
                      ).toFixed(2)}`}
                    </label>
                  </section>
                </section>
              </section>
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

export default PlanAndPaymentHire;
