export const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      color: "#505050",
    },
  },
  cells: {
    style: {
      width: "15vw",
      padding: "10px",
    },
  },
};
