//Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "billing";

const Billing = (props) => {
  const {
    setHasMore,
    setNextPage,
    setBilling,
    setFilteredItems,
    setIsLoading,
    clearBilling,
  } = props;

  const BILLING_URL = getAll.billing;

  useEffect(() => {
    // clearBilling();
    setIsLoading(true);
    axios
      .get(BILLING_URL)
      .then((res) => {
        const { billing } = res.data;

        // setBilling(billing);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setFilteredItems([]))
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

export default connect(null, actions)(Billing);
