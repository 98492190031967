//Dependencies
import React from "react";

//Assets
import "../stylesheets/subCard.css";

const SubCard = ({ dishes, menus, users, name }) => {
  return (
    <div className="subcard">
      <div className="subcard-header d-flex justify-content-center">
        <span>{name}</span>
      </div>
      <div className="subcard-body">
        {menus >= 0 && <span>Nuúmero de Menús: {menus}</span>}
        {dishes >= 0 && <span>Número de Platos: {dishes}</span>}
        {users >= 0 && <span>Número de Usuarios: {users}</span>}
      </div>
    </div>
  );
};

export default SubCard;
