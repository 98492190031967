//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import UserProfileForm from "../components/UserProfileForm";
import PageLoader from "../components/PageLoader";

//Assets
import { setSelectedEntity, setCurrentSection } from "../actions";

//Utils
import { get } from "../utils/services";

const UserProfile = (props) => {
  const { loggedUser, setSelectedEntity, selectedEntity, setCurrentSection } = props;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCurrentSection("Perfil");
    axios
      .get(get.users(id, loggedUser.restaurant_id, loggedUser.branch_id))
      .then((res) => {
        setSelectedEntity(res.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <section className="container">
      {isLoading ? (
        <section className="w-100 d-flex justify-content-center">
          <PageLoader loading={true} />
        </section>
      ) : (
        <>
          <div className="form-title">
            <h3>Editar Perfil</h3>
          </div>
          <UserProfileForm action={"edit"} {...selectedEntity} />
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    selectedEntity: state.selectedEntity,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
  setCurrentSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
