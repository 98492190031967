//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Assets
import "../stylesheets/VariantForm.css";

//Utils
import { get, update } from "../utils/services";
import {
  set_entity_limit_options,
  suscription_periods,
} from "../utils/selectValues";
import { modalPlanSuccessful, modalPlanError } from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import * as actions from "../actions";

const PlanPromotionForm = (props) => {
  const { id, period, discount, action, showLoadingOverlay } = props;

  const { id: plan_id } = useParams();
  const DISCOUNT = set_entity_limit_options([], 0, 100, 5, "%");

  const [discountSelect, setDiscountSelect] = useState([]);
  const [periodSelect, setPeriodSelect] = useState([]);
  const [mainPlan, setMainPlan] = useState([]);

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);

    const plan_promotions = { ...data, id: id };

    const plan = {
      ...mainPlan,
      plan_promotions_attributes: [plan_promotions],
    };

    axios
      .put(update.plans(plan_id), { plan })
      .then(() => {
        modalPlanSuccessful("actualizado", mainPlan.name, () =>
          history.goBack()
        );
      })
      .then(() => e.target.reset())
      .catch(() => modalPlanError("actualizar", mainPlan.name))
      .finally(() => showLoadingOverlay(false));
  };

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value)
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("period");
    register("discount");

    axios.get(get.plans(plan_id)).then((res) => setMainPlan(res.data));

    if (action === "edit") {
      const periodTransformed = suscription_periods.filter(
        ({ value }) => value == period
      );

      const discountTransformed = DISCOUNT.filter(
        ({ value }) => value == discount
      );

      setPeriodSelect(periodTransformed);
      setDiscountSelect(discountTransformed);
    }
  }, []);

  return (
    <section>
      <form className="cartear-variant-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="variant-form-container">
          <section className="row">
            <section className="form-group">
              <section className="form-group">
                <label htmlFor="discount">Descuento</label>
                <Select
                  name="discount"
                  value={discountSelect}
                  placeholder="Seleccione"
                  options={DISCOUNT.map((discount) => ({
                    label: discount.label,
                    value: discount.value,
                  }))}
                  onChange={(value, action) =>
                    handleChange(value, action, setDiscountSelect)
                  }
                  styles={cartearSelectStyles}
                />
              </section>
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label" htmlFor="period">
                Cantidad de Meses
              </label>
              <Select
                name="period"
                value={periodSelect}
                placeholder="Seleccione"
                options={suscription_periods}
                onChange={(value, action) => {
                  handleChange(value, action, setPeriodSelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    entityItems: state.entityItems,
  };
};

export default connect(mapStateToProps, actions)(PlanPromotionForm);
