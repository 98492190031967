//Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getAll } from "../utils/services";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setPaymentMethods,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearPaymentMethods,
} from "../actions";

//Utils
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "payment_methods";

const PaymentMethods = (props) => {
  const {
    setHasMore,
    setNextPage,
    setPaymentMethods,
    setIsLoading,
    clearPaymentMethods,
  } = props;

  const PAYMENT_METHODS_URL = getAll.payment_methods;

  useEffect(() => {
    clearPaymentMethods();
    setIsLoading(true);
    axios
      .get(PAYMENT_METHODS_URL)
      .then((res) => {
        const {
          payment_methods,
          meta: {
            links: { next, last },
          },
        } = res.data;
        setPaymentMethods(payment_methods);

        CheckHasMore(next, last, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setPaymentMethods,
  clearPaymentMethods,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(PaymentMethods);
