import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

const ColorPicker = ({ color, handleColor, position }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: color?.rgb
          ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
          : color,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        top: "300px",
        marginLeft: "400px",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      label: {
        width: "50px",
      },
    },
  });

  return (
    <div>
      <section className="row">
        <label style={styles.label}>{color?.hex || color}</label>
        <section className="separator"></section>
        <div style={styles.swatch} onClick={() => setDisplayColorPicker(true)}>
          <div style={styles.color} />
        </div>
      </section>
      {displayColorPicker && (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => setDisplayColorPicker(false)}
          />
          {color ? (
            <SketchPicker
              color={color}
              onChange={(color) => handleColor(color, position)}
            />
          ) : (
            <SketchPicker onChange={(color) => handleColor(color, position)} />
          )}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
