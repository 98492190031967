const commons = {
  commons: {
    actions: {
      edit: "editar",
      edited: "editado",
      create: "crear",
      created: "creado",
      updated: "actualizado",
      update: "actualizar",
    },
    imageUploader: {
      product: {
        warning: "Se recomienda una foto cuadrada 1:1",
      },
      category: {
        warning: "Se recomienda una foto rectangular 7:1",
      },
      advertising: {
        warning: "Se recomienda una foto rectangular 9:16",
      },
    },
    languages: {
      es: "Español",
      en: "Inglés",
    },
    buttons: {
      save: "GUARDAR",
      add: "AGREGAR",
    },
    placeholders: {
      select: "Seleccione",
    },
    days: {
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      sunday: "Domingo",
    },
    lookAndFeel: {
      generalText: "Tipografía para textos generales",
      dishesTitle: "Tipografía para título de los platos",
      descriptionDishes: "Tipografía para descripción de los platos",
      categories: "Tipografía para categorías",
    },
  },
};

export default commons;
