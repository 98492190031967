//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Assets
import "../stylesheets/CurrencyForm.css";
import * as actions from "../actions";

//Utils
import { create, update } from "../utils/services";
import { modalCurrencySuccessful, modalCurrencyError } from "../utils/modals";

const ENTITY = "currencies";

const CurrencyForm = (props) => {
  const {
    id,
    translations,
    code,
    symbol,
    action,
    updateCurrency,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const name = translations ? translations[0]?.description : null;

  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const currency = {
      ...data,
      translations_attributes: [
        {
          att: "value",
          id:
            translations?.length > 0
              ? translations.filter(({ locale }) => locale == "es")[0]?.id
              : null,
          description: data.translations_attributes[0].description,
          locale: "es",
        },
      ],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY], { currency })
        .then(() =>
          modalCurrencySuccessful(
            "creado",
            data.translations_attributes[0].description,
            t,
            () => history.goBack()
          )
        )
        .then(() => e.target.reset())
        .catch(() =>
          modalCurrencyError(
            "crear",
            data.translations_attributes[0].description,
            t
          )
        )
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](null, id), { currency })
        .then(() => {
          data.id = id;
          updateCurrency(data);
        })
        .then(() =>
          modalCurrencySuccessful(
            "actualizado",
            data.translations_attributes[0].description,
            t,
            () => history.goBack()
          )
        )
        .catch(() =>
          modalCurrencyError(
            "actualizar",
            data.translations_attributes[0].description,
            t
          )
        )
        .finally(() => showLoadingOverlay(false));
    }
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="row">
          <section className="currency-main-info">
            <section className="currency-name">
              <label>Nombre la divisa</label>
              <input
                name="translations_attributes[0][description]"
                className="form-control"
                defaultValue={name}
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
              <input
                type="hidden"
                name="translations_attributes[0][att]"
                value="value"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][locale]"
                value="es"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][translatable_type]"
                value="Currency"
                ref={register}
              />
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <section className="currency-name">
              <label>Símbolo</label>
              <input
                name="symbol"
                className="form-control"
                defaultValue={symbol}
                ref={register}
              />
            </section>
          </section>
          <section className="currency-separator"></section>
          <section className="form-group">
            <section className="currency-name">
              <label>Código</label>
              <input
                name="code"
                className="form-control"
                defaultValue={code}
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(CurrencyForm);
