import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

//Utils
import { update } from "../utils/services";
import {
  modalTableSuccessful,
  modalTableError,
  modalMultiTableSuccessful,
  modalMultiTableError,
  modalEntityAlreadyExist,
} from "../utils/modals";

const UpdateOrderStatusModal = ({
  oldStatus,
  newStatus,
  setNewStatus,
  restaurant_id,
  branch_id,
  table_id,
  order_id,
}) => {
  const { t } = useTranslation();
  const handleUpdateStatus = () => {
    setNewStatus(newStatus);

    const order = {
      table_id: table_id,
      state: newStatus.value,
    };

    axios
      .put(update.orders(restaurant_id, branch_id, table_id, order_id), order)
      .then(() => handleCloseModal());
  };

  const handleCloseModal = () => {
    const closeButton = document.querySelector(".swal2-close");
    closeButton.click();
  };

  const handleGetStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pendiente";
      case "preparing":
        return "En preparación";
      case "confirmed":
        return "Finalizado";
      case "canceled":
        return "Cancelado";
    }
  };

  return (
    <div>
      <section className="form-group d-flex justify-content-center align-items-center">
        <p>
          ¿Está seguro que quiere cambiar el pedido del estado{" "}
          <span className="text-bold">
            {handleGetStatus(oldStatus?.value || oldStatus)}
          </span>{" "}
          al estado{" "}
          <span className="text-bold">
            {handleGetStatus(newStatus?.value || newStatus)}
          </span>{" "}
          ?
        </p>
      </section>
      <section className="d-flex justify-content-center align-items-center">
        <div className="secondary-button mr-16" onClick={handleUpdateStatus}>
          Confirmar
        </div>
        <div className="primary-button ml-16" onClick={handleCloseModal}>
          Cancelar
        </div>
      </section>
    </div>
  );
};

export default UpdateOrderStatusModal;
