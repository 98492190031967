//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";

//Components
import BranchForm from "../components/BranchForm";
import Spinner from "../components/Spinner";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const BranchEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const { t } = useTranslation();
  const branch_id = loggedUser.branch_id || selectedEntity.id;
  const loggedRestaurantId =
    loggedUser.restaurant_id || selectedEntity.restaurant_id;
  const { restaurant_id, id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(get.branches(branch_id || id, restaurant_id || loggedRestaurantId))
      .then((res) => {
        setSelectedEntity(res.data);
      })
      .then(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <section className="container">
      <div className="form-title">
        <h3>{t("entities.branches.edit")}</h3>
      </div>
      <BranchForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchEdit);
