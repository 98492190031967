//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import RestaurantForm from "../components/RestaurantForm";
import Spinner from "../components/Spinner";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const RestaurantEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const restaurant_id = loggedUser.restaurant_id;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(get.restaurants(id || restaurant_id)).then((res) => {
      setSelectedEntity(res.data);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <section className="container">
      <div className="form-title">
        <h3>Editar Restaurante</h3>
      </div>
      <RestaurantForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantEdit);
