//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import "../stylesheets/ModelsIcons.css";
import ComputerIcon from "@material-ui/icons/Computer";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";

const ModelsIcons = (props) => {
  const { data } = props;

  return (
    <section className="models-container">
      <span
        className={`${
          data.url_3d_web ? "isUploaded-button" : "isNotUploaded-button"
        } models`}
      >
        <ComputerIcon />
      </span>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredItems: state.filteredItems,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModelsIcons);
