import React, { useState } from "react";
import { Collapse } from "react-collapse";

//Assets
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const OrderInfo = ({ id, consumables, owner_description }) => {
  const [collapseIsOpened, setCollapseIsOpened] = useState(true);

  return (
    <section className="order">
      <h3>Pedido {id}:</h3>
      <section className="dishes__container">
        {consumables.map((consumable) =>
          consumable.dish ? (
            <section className="dish">
              <section className="dish-name">
                {consumable.dish.title} (x{consumable.quantity})
              </section>
              <section className="dish-separator"></section>
              <section className="dish-price">
                ${(consumable.dish.price * consumable.quantity).toFixed(2)}
              </section>
            </section>
          ) : consumable.dish_topping ? (
            <section className="dish-subitem">
              <section className="dish-name">
                {consumable.dish_topping.topping.name} (x
                {consumable.quantity})
              </section>
              <section className="dish-separator"></section>
              <section className="dish-price">
                $
                {(
                  consumable.dish_topping.topping.price * consumable.quantity
                ).toFixed(2)}
              </section>
            </section>
          ) : consumable.dish_variant ? (
            <section className="dish-subitem">
              <section className="dish-name">
                {consumable.dish_variant.variant.name} (x
                {consumable.quantity})
              </section>
              <section className="dish-separator"></section>
              <section className="dish-price">
                $
                {(
                  consumable.dish_variant.variant.price * consumable.quantity
                ).toFixed(2)}
              </section>
            </section>
          ) : consumable.dish_accompaniment ? (
            <section className="dish-subitem">
              <section className="dish-name">
                {consumable.dish_accompaniment.accompaniment.name}
                (x
                {consumable.quantity})
              </section>
              <section className="dish-separator"></section>
              <section className="dish-price">
                $
                {(
                  consumable.dish_accompaniment.accompaniment.price *
                  consumable.quantity
                ).toFixed(2)}
              </section>
            </section>
          ) : (
            <></>
          )
        )}
      </section>
      {owner_description && (
        <>
          <section
            className="order-header d-flex align-items-center justify-content-space-between"
            onClick={() => setCollapseIsOpened((prevState) => !prevState)}
          >
            <section
              className={`collapse-icon-${collapseIsOpened ? "close" : "open"}`}
            >
              {collapseIsOpened ? <RemoveIcon /> : <AddIcon />}
            </section>
            <section className="order-name">
              <h3>Notas</h3>
            </section>
            <section className="order-separator w-100"></section>
          </section>
          <Collapse isOpened={collapseIsOpened}>
            <section className="px-40">
              <span>{owner_description}</span>
            </section>
          </Collapse>
        </>
      )}
    </section>
  );
};

export default OrderInfo;
