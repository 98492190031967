//Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "./ImageUploader";

//Assets
import "../stylesheets/RestaurantForm.css";
import * as actions from "../actions";

//Utils
import { create, update } from "../utils/services";
import { modalAllergenSuccessful, modalAllergenError } from "../utils/modals";

const ENTITY = "allergens";

const AllergenForm = (props) => {
  const {
    id,
    translations,
    image,
    action,
    updateAllergen,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const name =
    translations?.filter(({ locale }) => locale === "es")[0]?.description ||
    null;

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  useEffect(() => {
    register("image");
  }, []);

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const allergen = {
      ...data,
      translations_attributes: [
        {
          att: "name",
          id: translations
            ? translations.filter(({ locale }) => locale === "es")[0]?.id
            : null,
          description: data.name,
          locale: "es",
        },
      ],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY], { allergen })
        .then(() =>
          modalAllergenSuccessful("creado", data.name, t, () => history.goBack())
        )
        .then(() => e.target.reset())
        .catch(() => modalAllergenError("crear", data.name, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](null, id), { allergen })
        .then(() => {
          data.id = id;
          updateAllergen(data);
        })
        .then(() =>
          modalAllergenSuccessful("actualizado", data.name, t, () =>
            history.goBack()
          )
        )
        .catch(() => modalAllergenError("actualizar", data.name, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="restaurant-form-container">
          <section className="row">
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
            />
            <section className="restaurant-main-info">
              <section className="restaurant-name">
                <label>{t("forms.allergen.title")}</label>
                <input
                  name="name"
                  className="form-control"
                  defaultValue={name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value={t("commons.buttons.save")}
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(AllergenForm);
