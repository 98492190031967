//Dependencies
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import axios from "axios";

const containerStyle = {
  height: "400px",
  width: "100%",
  marginBottom: "32px",
  background: "#f7f8fc",
};

const Map = (props) => {
  const { address, lat, lng } = props.address;
  const zoom = 17;
  const [time, setTime] = useState(0);
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState({ lat: lat || -33.4937573, lng: lng || -70.6519523 });
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleZoomChanged = () => {
    if(map && time === 0) {
      map.setZoom(zoom);
      setTime(time + 1);
    }
  }

  useEffect(() => {
    if(lat && lng) {
      setCenter({ lat, lng});
    }
  }, [address, lat, lng])

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={() => handleZoomChanged()}
    >
      {address && <Marker position={{ lat: lat || -33.4937573, lng: lng || -70.6519523}} />}
    </GoogleMap>
  );
};

export default Map;
