import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Components
import PageLoader from "../components/PageLoader";
import TableOrderInfo from "../components/TableOrderInfo";
import OrderStatus from "../components/OrderStatus";
import UpdateOrderStatusModal from "../components/UpdateOrderStatusModal";
import ChangeOrderTableModal from "../components/ChangeOrderTableModal";

//Assets
import "../stylesheets/TableSummary.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import cartearSelectStyles from "../utils/cartearSelectStyles";

//Utils
import { order_status_options } from "../utils/selectValues";
import { get, update } from "../utils/services";

const swal = withReactContent(Swal);

const TableSummary = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [table, setTable] = useState({});
  const { restaurant_id, branch_id } = useSelector((state) => state.loggedUser);
  const { id: table_id } = useParams();

  useEffect(() => {
    axios
      .get(get.tables(restaurant_id, branch_id, table_id))
      .then((res) => setTable(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <section className="restaurant-summary__container">
        <section className="restaurant-summary__title">
          <h2>Pedidos {table.name}</h2>
        </section>
        {table.current_orders?.map((order) => (
          <TableOrderInfo
            order={order}
            orderStatus={{ value: order.state }}
            table={table}
          />
        ))}
      </section>
    </>
  );
};

export default TableSummary;
