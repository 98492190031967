//Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "../components/ImageUploader";

//Assets
import "../stylesheets/CategoryForm.css";
import { updateCategory, showLoadingOverlay } from "../actions";

//Utils
import { create, update } from "../utils/services";
import { modalCategorySuccessful, modalCategoryError } from "../utils/modals";

const ENTITY = "categories";

const CategoryForm = (props) => {
  const {
    id,
    branch_id,
    image,
    name,
    name_id,
    description,
    description_id,
    translations,
    action,
    updateCategory,
    loggedUser,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const [categoryImage, setCategoryImage] = useState(image);
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.branch_id;
  const readOnly = loggedUser.role === "branch_user" && !branch_id && !!id;

  const { register, handleSubmit, setValue, getValues } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const category = {
      ...data,
      restaurant_id: RESTAURANT_ID,
      branch_id: BRANCH_ID,
      translations_attributes: [
        {
          att: "name",
          id: name_id,
          description: data.name,
          locale: loggedUser.primary_lang,
        },
        {
          att: "description",
          id: description_id,
          description: data.description,
          locale: loggedUser.primary_lang,
        },
      ],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY](RESTAURANT_ID), { category })
        .then(() =>
          modalCategorySuccessful("creado", data.name, t, () =>
            history.goBack(),
          ),
        )
        .then(() => e.target.reset())
        .catch(() => modalCategoryError("crear", data.name, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      console.log(category);
      axios
        .put(update[ENTITY](RESTAURANT_ID, id), { category })
        .then(() => {
          data.id = id;
          updateCategory(data);
        })
        .then(() =>
          modalCategorySuccessful("actualizado", data.name, t, () =>
            history.goBack(),
          ),
        )
        .catch(() => modalCategoryError("actualizar", data.name))
        .finally(() => showLoadingOverlay(false));
    }
  };

  useEffect(() => {
    register("image");
  }, []);

  useEffect(() => {
    const categoryPreviewImage = document.getElementsByClassName(
      "previewimage__large-image",
    );

    if (categoryPreviewImage.length > 0)
      categoryPreviewImage[0].src = categoryImage;
  }, [categoryImage]);

  return (
    <section>
      <form className="cartear-category-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="category-form-container">
          <section className="category-form-container">
            <section className="row">
              <section
                style={{ width: "30vw", color: "red", fontWeight: "bold" }}
              >
                {t("commons.imageUploader.category.warning")}
                <ImageUploader
                  inputName={"image"}
                  image={image}
                  handleSetValue={setValue}
                  setCategoryImage={setCategoryImage}
                  readOnly={readOnly}
                />
              </section>
              <section className="category-main-info">
                <section className="previewimage__large">
                  {categoryImage && (
                    <img className="previewimage__large-image" />
                  )}
                </section>
              </section>
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label">
                {t("forms.category.title")}
              </label>
              <input
                readOnly={readOnly}
                name="name"
                maxLength={50}
                className="form-control"
                defaultValue={name}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="w-100">
              <label>{t("forms.category.description")}</label>
              <textarea
                name="description"
                maxLength={50}
                className="form-control category-section__description"
                defaultValue={description}
                readOnly={readOnly}
                ref={register}
                required={false}
              />
            </section>
          </section>
        </section>

        <section className="row">
          {!readOnly && (
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value={t("commons.buttons.save")}
              />
            </section>
          )}
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  updateCategory,
  showLoadingOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
