//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import "../stylesheets/SuggestionShow.css";
import * as actions from "../actions";

const SuggestionShow = (props) => {
  const { selectedEntity } = props;

  return (
    <section className="suggestion__container">
      <section className="suggestion__title">Sugerencia</section>
      <section className="suggestion__body">
        <section className="suggestion__body-top">
          <section className="suggestion-user-data">
            <section className="user-id">ID: 0000{selectedEntity.id}</section>
            <section className="user-mail">
              {selectedEntity.email || "mail@example.com"}
            </section>
          </section>
          <section className="suggestion-date">
            {selectedEntity.date || "10/07/2021"}
          </section>
        </section>
        <section className="suggestion__body-message">
          "{selectedEntity.body}".
        </section>
      </section>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(SuggestionShow);
