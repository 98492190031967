//Custom select styles

const cartearSelectStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    overflow: 'auto',
    backgroundColor: "#F7F8FC",
    boxShadow: isFocused ? "0px 0px 0px 1px #EE7B5A" : "none",
    border: isFocused ? "none" : isSelected ? "none" : "none",
    borderColor: "#EE7B5A !important",
    height: "50px",
    marginTop: "10px",
    marginBottom: "10px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isFocused ? "white" : isSelected ? "white" : "#505050",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#EE7B5A"
        : isFocused
        ? "#EE7B5A"
        : null,
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#EE7B5A" : "#EE7B5A"),
        color: "white",
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#F4DFE3",
    color: "#E4212E",
    border: "1px solid #E4212E",
    borderRadius: "10px",
  }),
  multiValueLabel: (styles) => ({ ...styles, color: "#E4212E" }),
  multiValueRemove: (styles) => ({
    ...styles,
    borderRadius: "10px",
    ":hover": { cursor: "pointer" },
  }),
};

export default cartearSelectStyles;
