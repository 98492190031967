//Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAll } from "../utils/services";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setPlanPromotions,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearPlanPromotions,
} from "../actions";

//Utils
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "plan_promotions";

const PlanPromotions = (props) => {
  const {
    setHasMore,
    setNextPage,
    setPlanPromotions,
    setIsLoading,
    clearPlanPromotions,
  } = props;

  const { id } = useParams();
  const PLANS_URL = getAll.plans;

  useEffect(() => {
    clearPlanPromotions();
    setIsLoading(true);
    axios
      .get(PLANS_URL)
      .then((res) => {
        const {
          plans,
          meta: {
            links: { next, last },
          },
        } = res.data;

        const PROMOTIONS = plans.find((plan) => plan.id == id).plan_promotions;
        setPlanPromotions(PROMOTIONS);

        CheckHasMore(next, last, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setPlanPromotions,
  clearPlanPromotions,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(PlanPromotions);
