//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "../components/ImageUploader";
import ColorPicker from "../components/ColorPicker";

//Assets
import "../stylesheets/RestaurantForm.css";

//Utils
import { getAll, create, update } from "../utils/services";
import * as actions from "../actions";

import {
  modalRestaurantSuccessful,
  modalRestaurantError,
} from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";
//import FontPicker from "../utils/FontPicker";

const ENTITY = "restaurants";

const RestaurantForm = (props) => {
  const {
    id,
    name,
    allow_custom_design,
    translations,
    description,
    image,
    country,
    currency,
    restaurant_languages,
    setting,
    action,
    updateRestaurant,
    setSelectedEntity,
    setCurrentSection,
    loggedUser,
    setLoggedUser,
    showLoadingOverlay,
  } = props;

  const fonts = [
    "Montserrat",
    "OpenSans",
    "Playfair",
    "PlaypenSans",
    "Roboto",
    "Cormorant Garamond",
    "Libre Baskerville",
    "Bodoni Moda",
    "Libre Caslon Text",
    "Lato",
    "Merriweather",
    "Fira Sans",
    "Poppins",
    "Inter",
    "Oswald",
    "Raleway",
  ];
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [fontFamily, setFontFamily] = useState({
    generalFont: { value: setting?.general_font, label: setting?.general_font },
    dishesTitleFont: {
      value: setting?.dishes_title_font,
      label: setting?.dishes_title_font,
    },
    dishesDescriptionFont: {
      value: setting?.dishes_description_font,
      label: setting?.dishes_description_font,
    },
    categoriesFont: {
      value: setting?.categories_font,
      label: setting?.categories_font,
    },
  });
  const [secondLanguages, setSecondLanguages] = useState([]);
  const [countrySelect, setCountrySelect] = useState([]);
  const [mainLanguageSelect, setMainLanguageSelect] = useState([]);
  const [secondaryLanguagesSelect, setSecondaryLanguagesSelect] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currencySelect, setCurrencySelect] = useState([]);
  const [font, setFont] = useState(setting?.font || "");
  const [showCustomColors, setShowCustomColors] = useState(
    allow_custom_design || false,
  );
  const [hideDishImage, setHideDishImage] = useState(
    setting ? setting.hide_dish_image || false : false,
  );
  const [colors, setColors] = useState(
    setting
      ? [
          setting.dish_color,
          setting.general_text_color,
          setting.background_color_main_button,
          setting.text_color_main_button,
          setting.background_color_sidebar,
          setting.text_color_sidebar,
          setting.background_color_scroll_categories,
          setting.text_color_categories,
          setting.text_color_viewer,
        ]
      : new Array(9).fill(null),
  );

  const handleColor = (color, position) => {
    const newColors = [...colors];
    newColors[position] = color;
    setColors(newColors);
  };
  const styledFont = (font) => {
    return font ? font.replaceAll(" ", "_") : "";
  };

  const { register, handleSubmit, setValue, getValues } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let restaurant;

    restaurant = {
      ...data,
      country_id: countrySelect[0]?.value || countrySelect.value,
      restaurant_languages_attributes: [
        {
          id: mainLanguageSelect.id,
          language_id: mainLanguageSelect.value,
          primary: true,
        },
        ...secondaryLanguagesSelect.map((lang) => ({
          id: lang.id,
          language_id: lang.value,
          primary: false,
          _destroy: lang._destroy ? true : false,
        })),
      ],
      restaurant_currencies_attributes: [
        {
          currency_id: currencySelect.value,
          id: currency?.restaurant_currency_id,
        },
      ],
      setting_attributes: {
        id: setting?.id,
        dish_color: colors[0]?.hex || colors[0],
        general_text_color: colors[1]?.hex || colors[1],
        background_color_main_button: colors[2]?.hex || colors[2],
        text_color_main_button: colors[3]?.hex || colors[3],
        background_color_sidebar: colors[4]?.hex || colors[4],
        text_color_sidebar: colors[5]?.hex || colors[5],
        background_general_image: data.background_general_image,
        background_3d_image: data.background_3d_image,
        background_desktop_image: data.background_desktop_image,
        hide_dish_image: data.hide_dish_image,
        background_color_scroll_categories: colors[6]?.hex || colors[6],
        text_color_categories: colors[7]?.hex || colors[7],
        text_color_viewer: colors[8]?.hex || colors[8],
        general_font: fontFamily.generalFont?.value,
        dishes_title_font: fontFamily.dishesTitleFont?.value,
        dishes_description_font: fontFamily.dishesDescriptionFont?.value,
        categories_font: fontFamily.categoriesFont?.value,
      },
    };

    const pushHistory = () => {
      if (loggedUser.role === "super_admin") {
        setCurrentSection("Restaurants");
        history.push("/restaurants");
      } else if (loggedUser.role === "restaurant_admin") {
        setCurrentSection("Dashboard");
        history.push("/");
      }
    };

    if (action === "create") {
      axios
        .post(create.restaurants, { restaurant })
        .then(() => {
          modalRestaurantSuccessful("creado", data.name, t);
          e.target.reset();
          pushHistory();
        })
        .catch(() => modalRestaurantError("crear", data.name, t))
        .finally(() => {
          showLoadingOverlay(false);
        });
    } else if (action === "edit") {
      axios
        .put(update.restaurants(null, id), { restaurant })
        .then((res) => {
          data.id = id;
          updateRestaurant(data);
          setSelectedEntity(res.data);
          axios.defaults.headers.common["user-language"] =
            res.data.primary_code || "es";
          if (window.localStorage.loginAs === "true") {
            const restaurantCached = JSON.parse(
              window.localStorage.getItem("restaurant"),
            );
            window.localStorage.setItem(
              "restaurant",
              JSON.stringify({
                ...restaurantCached,
                country_code: res.data.country_code,
                codes: res.data.codes,
                primary_code: res.data.primary_code,
              }),
            );
          }
          if (
            id == loggedUser.restaurant_id &&
            loggedUser.role === "restaurant_admin"
          ) {
            setLoggedUser({
              ...loggedUser,
              primary_lang: languages.find(
                ({ id }) => id == mainLanguageSelect.value,
              ).code,
              codes: res.data.codes,
            });
          }
          modalRestaurantSuccessful("actualizado", data.name, t);
          pushHistory();
        })
        .catch(() => modalRestaurantError("actualizar", data.name, t))
        .finally(() => {
          showLoadingOverlay(false);
        });
    }
  };

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value),
      );
    } else setValue(inputRef, value.value);

    if (action.action === "remove-value") {
      const newValues = secondaryLanguagesSelect.map((l) => {
        if (l.value === action.removedValue.value) {
          l["_destroy"] = true;
        }
        return l;
      });
      setStateFunction(newValues);
    } else if (action.action === "changeMainLanguage") {
      value.id = mainLanguageSelect.id;
      setStateFunction(value);
    } else {
      setStateFunction(value);
    }
  };

  useEffect(() => {
    register("image");
    register("background_general_image");
    register("background_3d_image");
    register("background_desktop_image");
    register("allow_custom_design");
    register("hide_dish_image");
    register("country_id");
    register("translations_attributes[0][locale]");
    register("restaurant_languages_attributes[]");
    register("currency_id");

    axios
      .get(getAll.countries)
      .then((res) => {
        setCountries(res.data.countries);
      })
      .then(() => {
        axios.get(getAll.languages).then((res) => {
          setLanguages(res.data["languages"]);
          let secondaryLanguages = res.data["languages"];
          if (action === "edit") {
            if (!!restaurant_languages) {
              const SEC_LANGS_XFORMED = restaurant_languages
                .filter(({ primary }) => !primary)
                .map(({ id, language_id, value }) => ({
                  value: language_id,
                  label: value,
                  id: id,
                }));
              const MN_LANG_XFORMED = restaurant_languages
                .filter(({ primary }) => primary)
                .map(({ id, language_id, value }) => ({
                  value: language_id,
                  label: value,
                  id: id,
                }))[0];

              setMainLanguageSelect(MN_LANG_XFORMED);
              secondaryLanguages = secondaryLanguages.filter(
                (l) => l.id !== MN_LANG_XFORMED.value,
              );
              setSecondaryLanguagesSelect(SEC_LANGS_XFORMED);
            }
          }
          setSecondLanguages(secondaryLanguages);
        });
      })
      .then(() => {
        axios.get(getAll.currencies()).then((res) => {
          setCurrencies(res.data["currencies"]);
        });
      })
      .then(() => {});
  }, []);

  useEffect(() => {
    if (countries.length > 0 && !!country) {
      const CTRY_XFORMED = countries
        .filter(({ id }) => id === country.id)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      setCountrySelect(CTRY_XFORMED);
    }
  }, [countries]);

  useEffect(() => {
    if (currency) {
      setCurrencySelect({ value: currency.id, label: currency.name });
    }
  }, [currency]);

  useEffect(() => {
    if (restaurant_languages) {
      let secondaryLanguages = restaurant_languages.filter((l) => !l.primary);
      secondaryLanguages = secondaryLanguages.map((sl) => ({
        value: sl.language_id,
        label: sl.value,
        id: sl.id,
      }));
      setSecondaryLanguagesSelect([...secondaryLanguages]);
    }
  }, []);

  useEffect(() => {
    if (mainLanguageSelect.value) {
      const newLanguages = languages.filter(
        (language) => language.id !== mainLanguageSelect.value,
      );
      const newSecondaryLanguages = secondaryLanguagesSelect.filter(
        (language) => language.value !== mainLanguageSelect.value,
      );
      setSecondLanguages([...newLanguages]);
      setSecondaryLanguagesSelect([...newSecondaryLanguages]);
    }
  }, [mainLanguageSelect]);

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="restaurant-form-container">
          <section className="row">
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
            />
            <section className="restaurant-main-info">
              <section className="restaurant-name">
                <label>Nombre del Restaurante</label>
                <input
                  name="name"
                  className="form-control"
                  defaultValue={name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="restaurant-description">
                <label>Descripción</label>
                <textarea
                  name="translations_attributes[0][description]"
                  className="form-control"
                  defaultValue={description}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
                <input
                  type="hidden"
                  name="translations_attributes[0][att]"
                  value="description"
                  ref={register}
                />
                <input
                  type="hidden"
                  name="translations_attributes[0][id]"
                  value={
                    !!translations && translations.length > 0
                      ? translations.filter(
                          ({ locale, att }) =>
                            locale === loggedUser.primary_lang &&
                            att === "description",
                        )[0]?.id
                      : ""
                  }
                  ref={register}
                />
                <input
                  type="hidden"
                  name="translations_attributes[0][locale]"
                  value={loggedUser.primary_lang || "es"}
                  ref={register}
                />
              </section>
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label htmlFor="country_id">País</label>
            <Select
              name="country_id"
              value={countrySelect}
              placeholder="Seleccione"
              options={countries.map((country) => ({
                label: country.name,
                value: country.id,
              }))}
              onChange={(value, action) => {
                handleChange(value, action, setCountrySelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label htmlFor="mainLanguages">Idioma Principal</label>
            <Select
              name="translations_attributes[0][locale]"
              value={mainLanguageSelect}
              placeholder="Seleccione"
              options={languages.map((language) => ({
                label: language.value,
                value: language.id,
              }))}
              onChange={(value, action) => {
                action.action = "changeMainLanguage";
                handleChange(value, action, setMainLanguageSelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
          <section className="separator"></section>
          <section className="form-group">
            <label htmlFor="secondaryLanguages">
              Idiomas Secundarios
              <span className="helper-text">(Podés elegir más de uno)</span>
            </label>
            <Select
              name="restaurant_languages_attributes[]"
              value={secondaryLanguagesSelect.filter((l) => !l._destroy)}
              isMulti
              placeholder="Seleccione"
              options={secondLanguages.map((language) => ({
                label: language.value,
                value: language.id,
              }))}
              onChange={(value, action) => {
                handleChange(value, action, setSecondaryLanguagesSelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label htmlFor="currency">Moneda</label>
            <Select
              name="currency_id"
              value={currencySelect}
              placeholder="Seleccione"
              options={currencies.map((currency) => ({
                label: currency.name,
                value: currency.id,
              }))}
              onChange={(value, action) => {
                handleChange(value, action, setCurrencySelect);
              }}
              styles={cartearSelectStyles}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <input
              type="checkbox"
              name="allow_custom_design"
              onChange={() => setShowCustomColors(!showCustomColors)}
              checked={showCustomColors}
              style={{ width: "20px " }}
              ref={register}
            />
            <label htmlFor="allow_custom_design">
              Personalizá el diseño de tu menú
            </label>
          </section>
        </section>
        {showCustomColors && (
          <section className="row">
            <section>
              <section className="form-group">
                <input
                  type="checkbox"
                  name="hide_dish_image"
                  onChange={() => setHideDishImage(!hideDishImage)}
                  checked={hideDishImage}
                  style={{ width: "20px " }}
                  ref={register}
                />
                <label htmlFor="allow_custom_design">
                  Ocultar foto de los platos en pantalla de menú inicial
                </label>
              </section>
              <section className="row">
                <section className="form-group">
                  <label
                    htmlFor="country_id"
                    style={{
                      fontFamily: styledFont(fontFamily?.generalFont?.value),
                    }}
                  >
                    {t("commons.lookAndFeel.generalText")}
                  </label>
                  <Select
                    name="font_family"
                    value={fontFamily.generalFont}
                    placeholder="Seleccione"
                    options={fonts?.map((font) => ({
                      label: font,
                      value: font,
                    }))}
                    onChange={(value) => {
                      setFontFamily({ ...fontFamily, generalFont: value });
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
              <section className="row">
                <section className="form-group">
                  <label
                    htmlFor="country_id"
                    style={{
                      fontFamily: styledFont(
                        fontFamily?.dishesTitleFont?.value,
                      ),
                    }}
                  >
                    {t("commons.lookAndFeel.dishesTitle")}
                  </label>
                  <Select
                    name="font_family"
                    value={fontFamily.dishesTitleFont}
                    placeholder="Seleccione"
                    options={fonts?.map((font) => ({
                      label: font,
                      value: font,
                    }))}
                    onChange={(value) => {
                      setFontFamily({ ...fontFamily, dishesTitleFont: value });
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
              <section className="row">
                <section className="form-group">
                  <label
                    htmlFor="country_id"
                    style={{
                      fontFamily: styledFont(
                        fontFamily?.dishesDescriptionFont?.value,
                      ),
                    }}
                  >
                    {t("commons.lookAndFeel.descriptionDishes")}
                  </label>
                  <Select
                    name="font_family"
                    value={fontFamily.dishesDescriptionFont}
                    placeholder="Seleccione"
                    options={fonts?.map((font) => ({
                      label: font,
                      value: font,
                    }))}
                    onChange={(value) => {
                      setFontFamily({
                        ...fontFamily,
                        dishesDescriptionFont: value,
                      });
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
              <section className="row">
                <section className="form-group">
                  <label
                    htmlFor="country_id"
                    style={{
                      fontFamily: styledFont(fontFamily?.categoriesFont?.value),
                    }}
                  >
                    {t("commons.lookAndFeel.categories")}
                  </label>
                  <Select
                    name="font_family"
                    value={fontFamily.categoriesFont}
                    placeholder="Seleccione"
                    options={fonts?.map((font) => ({
                      label: font,
                      value: font,
                    }))}
                    onChange={(value) => {
                      setFontFamily({ ...fontFamily, categoriesFont: value });
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
              <section className="form-group">
                <label>
                  Color para fondo de los platos en pantalla del menú
                </label>
                <ColorPicker
                  color={colors[0]}
                  handleColor={handleColor}
                  position={0}
                />
              </section>
              <section className="form-group">
                <label>Color para textos generales</label>
                <ColorPicker
                  color={colors[1]}
                  handleColor={handleColor}
                  position={1}
                />
              </section>
              <section className="form-group">
                <label>Color para fondo de botones principales</label>
                <ColorPicker
                  color={colors[2]}
                  handleColor={handleColor}
                  position={2}
                />
              </section>
              <section className="form-group">
                <label>Color para texto de botones principales</label>
                <ColorPicker
                  color={colors[3]}
                  handleColor={handleColor}
                  position={3}
                />
              </section>
              <section className="form-group">
                <label>Color para fondo del menú lateral</label>
                <ColorPicker
                  color={colors[4]}
                  handleColor={handleColor}
                  position={4}
                />
              </section>
              <section className="form-group">
                <label>Color para textos menú lateral</label>
                <ColorPicker
                  color={colors[5]}
                  handleColor={handleColor}
                  position={5}
                />
              </section>
              <section className="form-group">
                <label>Color para fondo categorías escroleables</label>
                <ColorPicker
                  color={colors[6]}
                  handleColor={handleColor}
                  position={6}
                />
              </section>
              <section className="form-group">
                <label>
                  Color para el texto de los banner de las categorias
                </label>
                <ColorPicker
                  color={colors[7]}
                  handleColor={handleColor}
                  position={7}
                />
              </section>
              <section className="form-group">
                <label>Color para el texto del visor 3D</label>
                <ColorPicker
                  color={colors[8]}
                  handleColor={handleColor}
                  position={8}
                />
              </section>
            </section>
            <section className="separator"></section>
            <section>
              <label>Imagen para fondo general</label>
              <ImageUploader
                inputName={"background_general_image"}
                image={setting?.background_general_image}
                handleSetValue={setValue}
              />
            </section>
            <section className="separator"></section>
            <section>
              <label>Imagen para fondo visor 3D</label>
              <ImageUploader
                inputName={"background_3d_image"}
                image={setting?.background_3d_image}
                handleSetValue={setValue}
              />
            </section>
            <section className="separator"></section>
            <section>
              <label>Imagen para fondo en versión escritorio</label>
              <ImageUploader
                inputName={"background_desktop_image"}
                image={setting?.background_desktop_image}
                handleSetValue={setValue}
              />
            </section>
          </section>
        )}
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSection: state.currentSection,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(RestaurantForm);
