//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import VariantForm from "../components/VariantForm";
import Spinner from "../components/Spinner";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const VariantEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const { id } = useParams();
  const [items, setItems] = useState();
  const BRANCH_ID =
    loggedUser.role == "branch_user" ? loggedUser.branch_id : null;

  useEffect(() => {
    axios
      .get(get.variants(id, loggedUser.restaurant_id, BRANCH_ID))
      .then((res) => {
        setSelectedEntity(res.data);
        setIsLoading(false);
        setItems(res.data.items);
      });
  }, []);

  if (!items) return null;

  return isLoading ? (
    <Spinner />
  ) : (
    <section className="container">
      <div className="form-title">
        <h3>Editar variante</h3>
      </div>
      <VariantForm action={"edit"} entityItems={items} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantEdit);
