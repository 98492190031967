//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "suggestions";

const Suggestions = (props) => {
  const {
    setHasMore,
    setNextPage,
    setIsLoading,
    setSuggestions,
    clearSuggestions,
  } = props;

  const SUGGESTIONS_URL = getAll.suggestions;

  useEffect(() => {
    clearSuggestions();
    setIsLoading(true);
    axios
      .get(SUGGESTIONS_URL)
      .then((res) => {
        const { suggestions } = res.data;
        setSuggestions(suggestions);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

export default connect(null, actions)(Suggestions);
