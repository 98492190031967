//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "../components/ImageUploader";
import CountryPriceList from "../components/CountryPriceList";

//Assets
import "../stylesheets/PlanForm.css";

//Utils
import { create, update } from "../utils/services";
import { is_recommended_options } from "../utils/selectValues";
import { modalPlanSuccessful, modalPlanError } from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import * as actions from "../actions";

const ENTITY = "plans";

const PlanForm = (props) => {
  const {
    id,
    name,
    maximum_menus,
    recommended_dishes,
    notifications,
    cartear_waiters,
    plan_countries,
    image,
    action,
    updatePlan,
    planCountries,
    addPlanCountry,
    clearPlanCountries,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const [cartearWaitersSelect, setCartearWaitersSelect] = useState([]);
  const [notificationsSelect, setNotificationsSelect] = useState([]);
  const [isRecommendedSelect, setIsRecommendedSelect] = useState([]);

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let plan = {
      ...data,
      recommended_dishes: isRecommendedSelect.value,
      cartear_waiters: cartearWaitersSelect.value,
      notifications: notificationsSelect.value,
      plan_countries_attributes: [...planCountries],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY], { plan })
        .then(() =>
          modalPlanSuccessful("creado", data.name, t, () => history.goBack())
        )
        .then(() => e.target.reset())
        .catch(() => modalPlanError("crear", data.name, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](id), { plan })
        .then(() => {
          data.id = id;
          updatePlan(data);
        })
        .then(() =>
          modalPlanSuccessful("actualizado", data.name, t, () => history.goBack())
        )
        .catch(() => modalPlanError("actualizar", data.name, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondLanguages(
        secondLanguages.filter((lang) => lang.value !== value.label)
      );
    }

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value)
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("image");
    register("recommended_dishes");
    register("cartear_waiters");
    register("notifications");
    clearPlanCountries();

    if (action === "edit") {
      const recommendedTransformed = is_recommended_options.filter(
        (op) => op.value == recommended_dishes
      );

      const cartearWaitersTransformed = is_recommended_options.filter(
        (op) => op.value == cartear_waiters
      );

      const notificationsTransformed = is_recommended_options.filter(
        (op) => op.value == notifications
      );

      setIsRecommendedSelect(recommendedTransformed);
      setCartearWaitersSelect(cartearWaitersTransformed);
      setNotificationsSelect(notificationsTransformed);
    }

    if (plan_countries?.length > 0) {
      addPlanCountry(plan_countries);
    }
  }, []);

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="plan-form-container">
          <section className="row">
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
            />
            <section className="plan-main-info">
              <section className="plan-name">
                <label>Nombre</label>
                <input
                  name="name"
                  className="form-control"
                  defaultValue={name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="form-group">
                <label htmlFor="recommended_dishes">¿Producto Destacado?</label>
                <Select
                  name="recommended_dishes"
                  value={isRecommendedSelect}
                  placeholder="Seleccione"
                  options={is_recommended_options}
                  onChange={(value, action) => {
                    handleChange(value, action, setIsRecommendedSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
              <section className="plan-name">
                <label>Cantidad de Menus</label>
                <input
                  name="maximum_menus"
                  className="form-control"
                  defaultValue={maximum_menus}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="row">
                <section className="form-group">
                  <label htmlFor="cartear_waiters">Cartear Mozos</label>
                  <Select
                    name="cartear_waiters"
                    value={cartearWaitersSelect}
                    placeholder="Seleccione"
                    options={is_recommended_options}
                    onChange={(value, action) => {
                      handleChange(value, action, setCartearWaitersSelect);
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
                <section className="separator"></section>
                <section className="form-group">
                  <label htmlFor="notifications">Notificaciones</label>
                  <Select
                    name="notifications"
                    value={notificationsSelect}
                    placeholder="Seleccione"
                    options={is_recommended_options}
                    onChange={(value, action) => {
                      handleChange(value, action, setNotificationsSelect);
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className="row">
          <CountryPriceList />
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    planCountries: state.planCountries,
  };
};

export default connect(mapStateToProps, actions)(PlanForm);
