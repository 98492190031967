//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setTags,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearTags,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "tags";

const Tags = (props) => {
  const { setHasMore, setNextPage, setTags, setIsLoading, clearTags } = props;

  const { id } = useParams();
  const TAGS_URL = getAll.tags(id);

  useEffect(() => {
    clearTags();
    setIsLoading(true);
    axios
      .get(TAGS_URL)
      .then((res) => {
        const { tags } = res.data;

        setTags(tags);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setTags,
  clearTags,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(Tags);
