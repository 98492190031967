import accompanimentIcon from "../../assets/images/accompanimentIcon.svg"
import allergenIcon from "../../assets/images/allergenIcon.svg"
import badgeIcon from "../../assets/images/badgeIcon.svg"
import branchIcon from "../../assets/images/branchIcon.svg"
import deviseIcon from "../../assets/images/deviseIcon.svg"
import menuIcon from "../../assets/images/menuIcon.svg"
import notificationIcon from "../../assets/images/notificationIcon.svg"
import productIcon from "../../assets/images/productIcon.svg"
import qrIcon from "../../assets/images/qrIcon.svg"
import reportIcon from "../../assets/images/reportIcon.svg"
import settingIcon from "../../assets/images/settingIcon.svg"
import suggestionIcon from "../../assets/images/suggestionIcon.svg"
import toppingIcon from "../../assets/images/toppingIcon.svg"
import usersIcon from "../../assets/images/usersIcon.svg"
import variantIcon from "../../assets/images/variantIcon.svg"
import versionsIcon from "../../assets/images/versionsIcon.svg"
import waiterIcon from "../../assets/images/waiterIcon.svg"

const icons = {
  accompanimentIcon,
  allergenIcon,
  badgeIcon,
  branchIcon,
  deviseIcon,
  menuIcon,
  notificationIcon,
  productIcon,
  qrIcon,
  reportIcon,
  settingIcon,
  suggestionIcon,
  toppingIcon,
  usersIcon,
  variantIcon,
  versionsIcon,
  waiterIcon,
}

export default icons;
