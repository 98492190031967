//Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Assets
import "../stylesheets/UserProfileForm.css";
import * as actions from "../actions";

//Utils
import { update } from "../utils/services";
import {
  modalChangePasswordSuccessful,
  modalChangePasswordError,
} from "../utils/modals";

const ChangePasswordForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedUser, showLoadingOverlay } = props;
  const [passMatch, setPassMatch] = useState(null);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    const passwords_match = checkPassword(
      data.new_password,
      data.confirm_new_password
    );

    if (!passwords_match) return;

    showLoadingOverlay(true);
    const user = {
      email: loggedUser.email,
      password: data.new_password,
      old_password: data.current_password,
    };

    axios
      .put(update.password_from_profile, { user })
      .then(() => {
        modalChangePasswordSuccessful(t);
        history.push("/");
      })
      .catch((err) => {
        modalChangePasswordError(err.response.data, t);
      })
      .finally(() => showLoadingOverlay(false));
  };

  const checkPassword = (new_pass, confirm_new_pass) => {
    if (new_pass === confirm_new_pass) {
      setPassMatch(true);
      return true;
    } else {
      setPassMatch(false);
      return false;
    }
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="row">
          <section className="form-group">
            <label>Contraseña antigua</label>
            <input
              name="current_password"
              className="form-control"
              type="password"
              ref={register({
                required: true,
                message: "Este campo es requerido",
              })}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label>Contraseña nueva</label>
            <input
              name="new_password"
              className={`${
                passMatch === false ? "form-control-border-red" : "form-control"
              }`}
              type="password"
              ref={register({
                required: true,
                message: "Este campo es requerido",
              })}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-group">
            <label>Confirmar contraseña nueva</label>
            <input
              name="confirm_new_password"
              className={`${
                passMatch === false ? "form-control-border-red" : "form-control"
              }`}
              type="password"
              ref={register({
                required: true,
                message: "Este campo es requerido",
              })}
            />
          </section>
        </section>
        {passMatch === false && (
          <section className="row">
            <span className="text-red text-bold">
              Las contraseñas no coinciden
            </span>
          </section>
        )}

        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button w-auto px-16"
              value="CAMBIAR CONTRASEÑA"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(ChangePasswordForm);
