//Dependencies
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Components
import Dashboard from "../components/Dashboard";
import Layout from "../components/Layout";
import Restaurants from "../pages/Restaurants";
import RestaurantNew from "../pages/RestaurantNew";
import RestaurantEdit from "../pages/RestaurantEdit";
import Branches from "../pages/Branches";
import BranchesNew from "../pages/BranchesNew";
import BranchesEdit from "../pages/BranchesEdit";
import Models from "../pages/Models";
import ModelEdit from "../pages/ModelEdit";
import Products from "../pages/Products";
import ProductNew from "../pages/ProductNew";
import ProductEdit from "../pages/ProductEdit";
import Allergens from "../pages/Allergens";
import AllergenNew from "../pages/AllergenNew";
import AllergenEdit from "../pages/AllergenEdit";
import AdvertisingEdit from "../pages/AdvertisingEdit";
import AdvertisingNew from "../pages/AdvertisingNew";
import Tags from "../pages/Tags";
import TagNew from "../pages/TagNew";
import TagEdit from "../pages/TagEdit";
import Currencies from "../pages/Currencies";
import CurrencyNew from "../pages/CurrencyNew";
import CurrencyEdit from "../pages/CurrencyEdit";
import Countries from "../pages/Countries";
import CountryNew from "../pages/CountryNew";
import CountryEdit from "../pages/CountryEdit";
import Billing from "../pages/Billing";
import PaymentMethods from "../pages/PaymentMethods";
import ShippingMethods from "../pages/ShippingMethods";
import Users from "../pages/Users";
import UserNew from "../pages/UserNew";
import UserEdit from "../pages/UserEdit";
import UserProfile from "../pages/UserProfile";
import UserChangePassword from "../pages/UserChangePassword";
import Menus from "../pages/Menus";
import MenuNew from "../pages/MenuNew";
import MenuEdit from "../pages/MenuEdit";
import Categories from "../pages/Categories";
import CategoryNew from "../pages/CategoryNew";
import CategoryEdit from "../pages/CategoryEdit";
import Toppings from "../pages/Toppings";
import ToppingNew from "../pages/ToppingNew";
import ToppingEdit from "../pages/ToppingEdit";
import Accompaniments from "../pages/Accompaniments";
import PlanAndPayment from "../pages/PlanAndPayment";
import Plans from "../pages/Plans";
import PlanNew from "../pages/PlanNew";
import PlanEdit from "../pages/PlanEdit";
import PlanPromotions from "../pages/PlanPromotions";
import PlanPromotionNew from "../pages/PlanPromotionNew";
import PlanPromotionEdit from "../pages/PlanPromotionEdit";
import MobileVersions from "../pages/MobileVersions";
import MobileVersionNew from "../pages/MobileVersionNew";
import MobileVersionEdit from "../pages/MobileVersionEdit";
import Suggestions from "../pages/Suggestions";
import SuggestionShow from "../pages/SuggestionShow";
import Notifications from "../pages/Notifications";
import NotificationShow from "../pages/NotificationShow";
import AccompanimentNew from "../pages/AccompanimentNew";
import AccompanimentEdit from "../pages/AccompanimentEdit";
import Variants from "../pages/Variants";
import VariantNew from "../pages/VariantNew";
import VariantEdit from "../pages/VariantEdit";
import QRCode from "../pages/QRCode";
import Translations from "../pages/Translations";
import RestaurantEmail from "../pages/RestaurantEmail";
import BranchEmail from "../pages/BranchEmail";
import PasswordRecoveryEmail from "../pages/PasswordRecoveryEmail";
import SubscriptionExpireSoon from "../pages/SubscriptionExpireSoon";
import SubscriptionExpired from "../pages/SubscriptionExpired";
import SubscriptionSuccess from "../pages/SubscriptionSuccess";
import SubscriptionSuccessfullyRenewed from "../pages/SubscriptionSuccessfullyRenewed";
import Lounge from "../pages/Lounge";
import TableSummary from "../pages/TableSummary";
import TableOrders from "../pages/TableOrders";
import Advertisings from "../pages/Advertisings";

export default (
  <Router>
    <Layout>
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/restaurants/:id/login-as" exact component={Dashboard} />
        <Route
          path="/restaurants/:id/branches/:id/login-as"
          exact
          component={Dashboard}
        />
        <Route path="/restaurants" exact component={Restaurants} />
        <Route path="/restaurants/create" exact component={RestaurantNew} />
        <Route path="/restaurants/:id/edit" exact component={RestaurantEdit} />
        <Route path="/restaurants/:id/branches" exact component={Branches} />
        <Route
          path="/restaurants/:id/branches/create"
          exact
          component={BranchesNew}
        />
        <Route path="/restaurants/:id/products" exact component={Products} />
        <Route path="/restaurants/:id/Models" exact component={Models} />
        <Route path="/models/:id/edit" exact component={ModelEdit} />
        <Route path="/branches" exact component={Branches} />
        <Route path="/branches/create" exact component={BranchesNew} />
        <Route path="/branches/:id/edit" exact component={BranchesEdit} />
        <Route
          path="/restaurants/:restaurant_id/branches/:id/edit"
          exact
          component={BranchesEdit}
        />
        <Route path="/branches/:id/products" exact component={Products} />
        <Route path="/products" exact component={Products} />
        <Route path="/products/create" exact component={ProductNew} />
        <Route path="/products/:id/edit" exact component={ProductEdit} />
        <Route
          path="/products/:id/translations"
          exact
          component={Translations}
        />
        <Route
          path="/advertisings/:id/translations"
          exact
          component={Translations}
        />
        <Route
          path="/branches/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/allergens" exact component={Allergens} />
        <Route path="/allergens/create" exact component={AllergenNew} />
        <Route path="/allergens/:id/edit" exact component={AllergenEdit} />
        <Route
          path="/allergens/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/tags" exact component={Tags} />
        <Route path="/tags/create" exact component={TagNew} />
        <Route path="/tags/:id/edit" exact component={TagEdit} />
        <Route path="/tags/:id/translations" exact component={Translations} />
        <Route path="/currencies" exact component={Currencies} />
        <Route path="/currencies/create" exact component={CurrencyNew} />
        <Route path="/currencies/:id/edit" exact component={CurrencyEdit} />
        <Route path="/countries" exact component={Countries} />
        <Route path="/countries/create" exact component={CountryNew} />
        <Route path="/countries/:id/edit" exact component={CountryEdit} />
        <Route path="/billing" exact component={Billing} />
        <Route path="/payment_methods" exact component={PaymentMethods} />
        <Route
          path="/payment_methods/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/delivery_methods" exact component={ShippingMethods} />
        <Route
          path="/delivery_methods/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/users" exact component={Users} />
        <Route path="/users/create" exact component={UserNew} />
        <Route path="/users/:id/edit" exact component={UserEdit} />
        <Route path="/users/:id/profile" exact component={UserProfile} />
        <Route
          path="/users/:id/change_password"
          exact
          component={UserChangePassword}
        />
        <Route path="/menus/:id/translations" exact component={Translations} />
        <Route path="/menus" exact component={Menus} />
        <Route path="/menus/create" exact component={MenuNew} />
        <Route path="/menus/:id/edit" exact component={MenuEdit} />
        <Route path="/categories" exact component={Categories} />
        <Route path="/categories/create" exact component={CategoryNew} />
        <Route path="/categories/:id/edit" exact component={CategoryEdit} />
        <Route path="/toppings" exact component={Toppings} />
        <Route path="/accopaniments" exact component={Accompaniments} />
        <Route path="/plans_and_payments" exact component={PlanAndPayment} />
        <Route path="/plans" exact component={Plans} />
        <Route path="/plans/create" exact component={PlanNew} />
        <Route path="/plans/:id/edit" exact component={PlanEdit} />
        <Route
          path="/plans/:id/plan_promotions"
          exact
          component={PlanPromotions}
        />
        <Route
          path="/plans/:id/plan_promotions/create"
          exact
          component={PlanPromotionNew}
        />
        <Route
          path="/plans/:id/plan_promotions/:prom_id/edit"
          exact
          component={PlanPromotionEdit}
        />
        <Route path="/mobile_versions" exact component={MobileVersions} />
        <Route
          path="/mobile_versions/create"
          exact
          component={MobileVersionNew}
        />
        <Route
          path="/mobile_versions/:id/edit"
          exact
          component={MobileVersionEdit}
        />
        <Route path="/suggestions" exact component={Suggestions} />
        <Route path="/suggestions/:id" exact component={SuggestionShow} />
        <Route path="/notifications" exact component={Notifications} />
        <Route path="/notifications/:id" exact component={NotificationShow} />
        <Route path="/toppings/create" exact component={ToppingNew} />
        <Route path="/toppings/:id/edit" exact component={ToppingEdit} />
        <Route
          path="/toppings/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/accompaniments" exact component={Accompaniments} />
        <Route path="/advertisings" exact component={Advertisings} />
        <Route
          path="/accompaniments/create"
          exact
          component={AccompanimentNew}
        />
        <Route path="/advertisings/create" exact component={AdvertisingNew} />
        <Route path="/restaurants/:id/advertisings/create" exact component={AdvertisingNew} />
        <Route
          path="/accompaniments/:id/edit"
          exact
          component={AccompanimentEdit}
        />
        <Route
          path="/advertisings/:id/edit"
          exact
          component={AdvertisingEdit}
        />
        <Route
          path="/restaurants/:id/advertisings/:id/edit"
          exact
          component={AdvertisingEdit}
        />
        <Route
          path="/accompaniments/:id/translations"
          exact
          component={Translations}
        />
        <Route
          path="/categories/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/variants" exact component={Variants} />
        <Route path="/variants/create" exact component={VariantNew} />
        <Route path="/variants/:id/edit" exact component={VariantEdit} />
        <Route
          path="/variants/:id/translations"
          exact
          component={Translations}
        />
        <Route path="/qrcode" exact component={QRCode} />
        <Route path="/restaurant/settings" exact component={RestaurantEdit} />
        <Route path="/branch/settings" exact component={BranchesEdit} />
        <Route path="/restaurant_email" exact component={RestaurantEmail} />
        <Route path="/branch_email" exact component={BranchEmail} />
        <Route
          path="/password_recovery_email"
          D
          exact
          component={PasswordRecoveryEmail}
        />
        <Route
          path="/subscription_expire_soon"
          exact
          component={SubscriptionExpireSoon}
        />
        <Route
          path="/subscription_expired"
          exact
          component={SubscriptionExpired}
        />
        <Route
          path="/subscription_successful"
          exact
          component={SubscriptionSuccess}
        />
        <Route
          path="/subscription_successfully_renewed"
          exact
          component={SubscriptionSuccessfullyRenewed}
        />
        <Route path="/lounge" exact component={Lounge} />
        <Route
          path="/lounge/tables/:id/summary"
          exact
          component={TableSummary}
        />
        <Route path="/lounge/tables/:id/orders" exact component={TableOrders} />
      </Switch>
    </Layout>
  </Router>
);
