//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import AllergenForm from "../components/AllergenForm";

//Utils
import { setSelectedEntity } from "../actions";
import { get } from "../utils/services";

const AllergenEdit = (props) => {
  const { setSelectedEntity, selectedEntity } = props;
  const { id } = useParams();
  useEffect(() => {
    axios.get(get.allergens(id)).then((res) => {
      setSelectedEntity(res.data);
    });
  }, []);

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar Alérgeno</h3>
      </div>
      <AllergenForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllergenEdit);
