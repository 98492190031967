//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

//Components
import PageLoader from "../components/PageLoader";

//Assets
import * as actions from "../actions";
import "../stylesheets/MailTemplate.css";

//Utils
import { get, getAll, create, update } from "../utils/services";
import {
  modalMailTemplateSuccessful,
  modalMailTemplateError,
} from "../utils/modals";

const BranchEmail = (props) => {
  const { showLoadingOverlay } = props;
  const { register, handleSubmit } = useForm();

  const GET_MAIL_TEMPLATE_URL = get.mail_template("branch");
  const CREATE_MAIL_TEMPLATE_URL = create.mail_template();
  const UPDATE_MAIL_TEMPLATE_URL = update.mail_template("branch");

  const [isLoading, setIsLoading] = useState(true);
  const [mailTemplate, setMailTemplate] = useState({});
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("es");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(GET_MAIL_TEMPLATE_URL)
      .then(({ data }) => setMailTemplate(data))
      .finally(() => {
        axios.get(getAll.languages).then(({ data }) => {
          const ORGANIZED_LANGUAGES = data.languages.sort((a) =>
            a.code == "es" ? -1 : 1
          );

          setLanguages(ORGANIZED_LANGUAGES);
        });
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let mail_template;

    if (!mailTemplate.mail_type) {
      mail_template = {
        mail_type: "branch",
        translations_attributes: [
          {
            att: "title",
            description: data.title,
            locale: "es",
          },
          {
            att: "paragraph_one",
            description: data.paragraph_one,
            locale: "es",
          },
          {
            att: "paragraph_two",
            description: data.paragraph_two,
            locale: "es",
          },
          {
            att: "user",
            description: data.user,
            locale: "es",
          },
          {
            att: "password",
            description: data.password,
            locale: "es",
          },
          {
            att: "button",
            description: data.button,
            locale: "es",
          },
        ],
      };
      axios
        .post(CREATE_MAIL_TEMPLATE_URL, { mail_template })
        .then(({ data }) => setMailTemplate(data))
        .then(() => modalMailTemplateSuccessful())
        .catch(() => modalMailTemplateError())
        .finally(() => showLoadingOverlay(false));
    } else {
      mail_template = {
        mail_type: "branch",
        translations_attributes: [
          {
            id: findIdByAtt("title"),
            att: "title",
            description: data.title,
            locale: selectedLanguage,
          },
          {
            id: findIdByAtt("paragraph_one"),
            att: "paragraph_one",
            description: data.paragraph_one,
            locale: selectedLanguage,
          },
          {
            id: findIdByAtt("paragraph_two"),
            att: "paragraph_two",
            description: data.paragraph_two,
            locale: selectedLanguage,
          },
          {
            id: findIdByAtt("user"),
            att: "user",
            description: data.user,
            locale: selectedLanguage,
          },
          {
            id: findIdByAtt("password"),
            att: "password",
            description: data.password,
            locale: selectedLanguage,
          },
          {
            id: findIdByAtt("button"),
            att: "button",
            description: data.button,
            locale: selectedLanguage,
          },
        ],
      };
      axios
        .put(UPDATE_MAIL_TEMPLATE_URL, { mail_template })
        .then(({ data }) => setMailTemplate(data))
        .then(() => modalMailTemplateSuccessful())
        .catch(() => modalMailTemplateError())
        .finally(() => showLoadingOverlay(false));
    }
  };

  const findDescriptionByAtt = (att, code) => {
    return mailTemplate?.translations?.find(
      (t) => t.att === att && t.locale === code
    )?.description;
  };

  const findIdByAtt = (att) => {
    return mailTemplate?.translations?.find(
      (t) => t.att === att && t.locale === selectedLanguage
    )?.id;
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <section>
        <form className="mail_template" onSubmit={handleSubmit(onSubmit)}>
          <h3>Email</h3>
          <Tabs>
            <TabList>
              {languages.map(({ id, value, code }) => {
                return (
                  <Tab key={id} onClick={() => setSelectedLanguage(code)}>
                    {value}
                  </Tab>
                );
              })}
            </TabList>
            {languages.map(({ code }, index) => {
              return (
                <TabPanel key={index}>
                  <section className="row mb-16">
                    <section className="mail_template-title">
                      <label>Asunto</label>
                      <input
                        name="title"
                        defaultValue={findDescriptionByAtt("title", code)}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                  <section className="row">
                    <section className="mail_template-description">
                      <label>Párrafo 1</label>
                      <textarea
                        name="paragraph_one"
                        defaultValue={findDescriptionByAtt(
                          "paragraph_one",
                          code
                        )}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                  <section className="row">
                    <section className="mail_template-description">
                      <label>Párrafo 2</label>
                      <textarea
                        name="paragraph_two"
                        defaultValue={findDescriptionByAtt(
                          "paragraph_two",
                          code
                        )}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                  <section className="row mb-16">
                    <section className="mail_template-title">
                      <label>Usuario</label>
                      <input
                        name="user"
                        defaultValue={findDescriptionByAtt("user", code)}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                  <section className="row mb-16">
                    <section className="mail_template-title">
                      <label>Contraseña</label>
                      <input
                        name="password"
                        defaultValue={findDescriptionByAtt("password", code)}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                  <section className="row mb-16">
                    <section className="mail_template-title">
                      <label>Texto del botón</label>
                      <input
                        name="button"
                        defaultValue={findDescriptionByAtt("button", code)}
                        className="form-control"
                        ref={register({
                          required: true,
                          message: "Este campo es requerido",
                        })}
                      />
                    </section>
                  </section>
                </TabPanel>
              );
            })}
          </Tabs>

          <section className="row">
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value="GUARDAR"
              />
            </section>
          </section>
        </form>
      </section>
    </>
  );
};

export default connect(null, actions)(BranchEmail);
