import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

//Components
import PageLoader from "../components/PageLoader";
import OrderInfo from "../components/OrderInfo";

//Assets
import "../stylesheets/TableSummary.css";

//Utils
import { get } from "../utils/services";

const TableSummary = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [table, setTable] = useState({});
  const { restaurant_id, branch_id } = useSelector((state) => state.loggedUser);
  const { id: table_id } = useParams();

  useEffect(() => {
    axios
      .get(get.tables(restaurant_id, branch_id, table_id))
      .then((res) => setTable(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleGetTotal = () => {
    let table_total = 0;
    table.current_orders.map((order) => {
      order.consumables.map((consumable) => {
        if (consumable.dish) {
          table_total =
            parseFloat(table_total) +
            parseFloat(
              (consumable.dish.price * consumable.quantity).toFixed(2)
            );
        } else if (consumable.dish_topping) {
          table_total =
            parseFloat(table_total) +
            parseFloat(
              (
                consumable.dish_topping.topping.price * consumable.quantity
              ).toFixed(2)
            );
        } else if (consumable.dish_variant) {
          table_total =
            parseFloat(table_total) +
            parseFloat(
              (
                consumable.dish_variant.variant.price * consumable.quantity
              ).toFixed(2)
            );
        } else if (consumable.dish_accompaniment) {
          table_total =
            parseFloat(table_total) +
            parseFloat(
              (
                consumable.dish_accompaniment.accompaniment.price *
                consumable.quantity
              ).toFixed(2)
            );
        }
      });
    });
    return `$ ${table_total.toFixed(2)}`;
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <section className="restaurant-summary__container">
        <section className="restaurant-summary__title">
          <h2>Resumen {table.name}</h2>
        </section>
        <section className="restaurant-summary__body">
          <section className="summary-info">
            <section className="summary-info__title">
              <h2>Información</h2>
              <section className="separator"></section>
            </section>
            <section className="summary-info__body">
              <sections className="orders__container">
                {table.current_orders.map((order) => (
                  <OrderInfo {...order} key={order.id} />
                ))}
              </sections>
              <section className="orders-total">
                <h2>Total</h2>
                <section className="separator"></section>
                <h2 className="total-price">{handleGetTotal()}</h2>
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default TableSummary;
