import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { create } from "../utils/services";
import CartearModal from "./CartearModal";

const useStyles = makeStyles(() => ({
  progress: {
    color: "#e20713 !important",
  },
  button: {
    background:
      "linear-gradient(-14.55deg, #e20613 -61.49%, #ee7b5a 100.63%) !important",
    color: "white !important",
  },
  errors: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "200px",
  },
}));

const InfoImportProduct = ({ classes, exportToExcel, inputRef, sendData }) => {
  return (
    <>
      <h2 id="simple-modal-title">
        Deberás importar una planilla excel que esté conformada exactamente de
        la siguiente manera:
      </h2>
      <ul>
        <li>
          En la Columna 1 irán los Nombres de tus productos. Es Obligatoria.
        </li>
        <li>
          En la Columna 2 irán los Precios de tus productos. Es Obligatoria y
          solo pueden ir números. Utiliza . (punto) para decimales, no coma.
        </li>
        <li>
          En la Columna 3 irán las descripciones de tus productos. Es Opcional.
        </li>
        <li>
          Primero debes Descargar Planilla. Luego rellenarla con tus productos y
          por último IMPORTAR PRODUCTOS
        </li>
      </ul>
      <h4 id="simple-modal-description"></h4>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => inputRef.current.click()}
      >
        IMPORTAR PRODUCTOS
        <input
          type="file"
          id="productFile"
          name="productFile"
          accept=".xlsx"
          ref={inputRef}
          onChange={sendData}
        />
      </Button>
      <Button onClick={() => exportToExcel()}>Descargar Planilla</Button>
    </>
  );
};

const LoadingImportProduct = ({ classes }) => {
  return (
    <div className={classes.root}>
      <h1>Espere...</h1>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

const SuccessfullImportProduct = ({ classes, handleClose }) => {
  return (
    <div>
      <h1>Importación exitosa</h1>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => handleClose()}
      >
        OK
      </Button>
    </div>
  );
};

const ErrorsImportProduct = ({ classes, errors, handleClose }) => {
  return (
    <div>
      <h1>Importación con errores</h1>
      <div className={classes.errors}>
        {errors.map((error) => {
          const row = Object.keys(error);
          return (
            <div>
              <strong>Error en la fila: {row}</strong>: {error[row].join(", ")}
            </div>
          );
        })}
      </div>
      <br></br>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => handleClose()}
      >
        OK
      </Button>
    </div>
  );
};

const ImportProducts = ({
  restaurantId,
  branchId,
  open,
  setOpen,
  filteredItems,
  setFilteredItems,
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [productsSize, setProductsSize] = useState();
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setShowInfo(true);
    setOpen(false);
    setResponseSuccess(false);
    setErrors([]);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Nombre de producto", "Precio", "Descripción"],
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "template.xlsx");
  };

  const sendData = (e) => {
    setShowInfo(false);
    setLoading(true);
    const file = e.target.files[0];
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onload = () => {
      const baseURL = reader.result.split(";")[1].split(",")[1];
      axios
        .post(
          `${create.import_products(restaurantId, branchId)}?lang=${i18n.language}`,
          {
            xlsxbase64: baseURL,
            restaurant_id: restaurantId,
            branch_id: branchId,
          },
        )
        .then((res) => {
          setLoading(false);
          setErrors(res.data.errors);
          setResponseSuccess(true);
          const products = [...filteredItems, ...res.data.products];
          setFilteredItems(
            products.sort((a, b) => a.title?.localeCompare(b.title)),
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
  };

  return (
    <CartearModal open={open} onClose={() => handleClose()}>
      {showInfo && (
        <InfoImportProduct
          classes={classes}
          inputRef={inputRef}
          sendData={sendData}
          exportToExcel={exportToExcel}
        />
      )}
      {loading && <LoadingImportProduct classes={classes} />}
      {responseSuccess && errors.length == 0 && (
        <SuccessfullImportProduct classes={classes} handleClose={handleClose} />
      )}
      {!loading && errors.length > 0 && (
        <ErrorsImportProduct
          classes={classes}
          errors={errors}
          handleClose={handleClose}
        />
      )}
    </CartearModal>
  );
};

// setProductsSize(res.data.products.length);
// <h2>Importados {productsSize} productos de {productsSize + errors.length}</h2>
//
export default ImportProducts;
