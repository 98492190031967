//Dependencies
import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { PayPalButton } from "react-paypal-button-v2";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

//Utils
import { modalSuccessfulPayment, modalErrorPayment } from "../utils/modals";
import { create, update } from "../utils/services";
import * as actions from "../actions";

const Paypal = (props) => {
  const { t } = useTranslation(); 
  const { plan_country, loggedUser, paymentId, total_price, promotion } = props;
  const { restaurant_id, branch_id } = loggedUser;
  const plan_country_id = plan_country.id;
  const price = plan_country.price;
  const currency = plan_country.currency.code;
  const client_id = sessionStorage.getItem("paypal_client_id");

  const handleGoBack = () => {
    document.querySelector(".back-button").click();
  };

  return (
    <PayPalButton
      amount={total_price}
      options={{ clientId: client_id, currency: currency }}
      shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
      onSuccess={(details, data) => {
        const body = {
          id: paymentId ? paymentId : null,
          transaction_id: details.id,
          first_total: total_price,
          payer_name: details.payer.name.given_name,
          state: details.status === "COMPLETED" ? 1 : 0,
          plan_country_id: plan_country_id,
          branch_id: branch_id,
        };

        if (paymentId) {
          axios
            .put(
              update.payment_transactions(restaurant_id, branch_id, paymentId),
              body
            )
            .then(() => modalSuccessfulPayment(t, () => handleGoBack()));
        } else {
          axios
            .post(create.payment_transactions(restaurant_id, branch_id), body)
            .then(() => modalSuccessfulPayment(t, () => handleGoBack()));
        }
      }}
      onError={() => modalErrorPayment(t)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    payment_transactions: state.payment_transactions,
    paymentId: state.paymentId,
  };
};

export default connect(mapStateToProps, actions)(Paypal);
