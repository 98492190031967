//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import "../stylesheets/SuggestionShow.css";
import * as actions from "../actions";

const NotificationShow = (props) => {
  const { selectedEntity } = props;

  return (
    <section className="suggestion__container">
      <section className="suggestion__title">Notificación</section>
      <section className="suggestion__body">
        <section className="suggestion__body-top flex-column">
          <section className="topic-name mb-8">
            Topic: {selectedEntity.topic.name}
          </section>
          <section className="row justify-content-space-between">
            <section className="suggestion-user-data">
              <section className="user-id">{selectedEntity.time}</section>
              <section className="user-mail">
                {selectedEntity.title || "Título"}
              </section>
            </section>
            <section className="suggestion-date">
              {selectedEntity.date || "10/07/2021"}
            </section>
          </section>
        </section>
        <section className="suggestion__body-message">
          "{selectedEntity.body}".
        </section>
      </section>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(NotificationShow);
