//Dependencies
import React from "react";

//Components
import CurrencyForm from "../components/CurrencyForm";

const CurrencyNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva Divisa</h3>
      </div>
      <CurrencyForm action={"create"} />
    </section>
  );
};

export default CurrencyNew;
