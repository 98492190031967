//Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../components/Spinner";

//Components
import QRCodeItem from "../components/QRCodeItem";

//Assets
import "../stylesheets/qrcode.css";

//Utils
import { get } from "../utils/services";
import { models } from "../utils/qrcodeModels.json";

const QRCode = ({ loggedUser }) => {
  const [hasActiveMenu, setHasActiveMenu] = useState(false);
  const [dynamicLink, setDynamicLink] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const branch_id = loggedUser.branch_id;
  const loggedRestaurantId = loggedUser.restaurant_id;
  const handleCopyToClipboard = () => {
    const DYNAMIC_LINK = document.getElementById("dynamic-link");
    DYNAMIC_LINK.select();
    DYNAMIC_LINK.setSelectionRange(0, 999999);
    document.execCommand("copy");
    DYNAMIC_LINK.setSelectionRange(0, 0);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    });

    Toast.fire({
      icon: "success",
      title: "El texto se ha copiado al portapapeles.",
    });
  };

  useEffect(() => {
    axios
      .get(`has_active_menu?branch_id=${branch_id}`)
      .then((res) => {
        setHasActiveMenu(res.data.has_active_menu);
        setDynamicLink(res.data.dynamic_link);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <section className="qrcode__container">
      <section className="qrcode-preview__container">
        <QRCodeItem
          user={loggedUser}
          dynamic_link_branch={dynamicLink}
          hasActiveMenu={hasActiveMenu}
        />
      </section>

      {hasActiveMenu && (
        <section className="share-link__container">
          <section className="share-link__title">
            <h3>Compartí tu link en las Redes!</h3>
          </section>
          <section className="share-link">
            <section className="form-group d-flex">
              <input
                id="dynamic-link"
                name="dynamic-link"
                className="form-control cartear-form-input"
                defaultValue={dynamicLink}
                readOnly
              />
              <div
                className="btn cartear-outline-button ml-24 w-10"
                onClick={() => handleCopyToClipboard()}
              >
                <p>COPIAR LINK</p>
              </div>
            </section>
          </section>
        </section>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, null)(QRCode);
