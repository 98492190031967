const modals = {
  modals: {
    common: {
      errorTitle: "An error has occurred!",
      successfulTitle: "Excellent!",
      close: "Close",
    },
    removeProduct: {
      title: "Are you sure you want to remove the product {{productName}}?",
    },
    removeMenuCategory: {
      title: "Are you sure you want to remove the category {{categoryName}}?",
    },
    updatePrices: {
      title: "Update prices",
      updatePercentage: "Update percentage",
    },
    addProduct: {
      title: "Add Product to Category",
      inputPlaceholder: "Select a Product",
      confirmButton: "Select",
      emptyOption: "You must select a product to continue",
      alreadyExistsTitle: "This product has already been added!",
      alreadyExistsContent:
        "There is already a product named <strong>{{productName}}</strong> for this category",
      successfullyAddedTitle: "Excellent!",
      successfullyAddedContent:
        "The product <strong>{{productName}}</strong> has been added.",
    },
    copyToClipboard: {
      title: "The text has been copied to the clipboard",
    },
    addCategory: {
      title: "Add Category",
      inputPlaceholder: "Select a Category",
      confirmButton: "Select",
      emptyResult: "You must select a category to continue",
      contentHtml:
        "There is already a category named <strong>{{categoryName}}</strong> for this menu.",
      alreadyExistsTitle: "This category has already been added!",
      successfullyAddedTitle: "Excellent!",
      successfullyAddedContent:
        "The category <strong>{{categoryName}}</strong> has been added.",
    },
    categorySuccessful: {
      content:
        "The category <strong>{{categoryName}}</strong> has been {{action}} successfully.",
    },
    categoryError: {
      content:
        "The category <strong>{{categoryName}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    restaurantSuccessful: {
      content:
        "The restaurant <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    restaurantError: {
      content:
        "The restaurant <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    allergenSuccessful: {
      content:
        "The allergen <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    allergenError: {
      content:
        "The allergen <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    tagSuccessful: {
      content:
        "The tag <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    tagError: {
      content:
        "The tag <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    userSuccessful: {
      content:
        "The user <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    changePasswordSuccessful: {
      content: "The password has been changed successfully",
    },
    currencySuccessful: {
      content:
        "The currency <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    currencyError: {
      content:
        "The currency <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    countrySuccessful: {
      content:
        "The country <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    countryError: {
      content:
        "The country <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    productSuccessful: {
      content:
        "The product <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    productError: {
      content:
        "The product <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    toppingSuccessful: {
      content:
        "The topping <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    toppingError: {
      content:
        "The topping <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    accompanimentSuccessful: {
      content:
        "The accompaniment <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    accompanimentError: {
      content:
        "The accompaniment <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    advertisingSuccessful: {
      content:
        "The advertising <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    advertisingError: {
      content:
        "The advertising <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    variantSuccessful: {
      content:
        "The variant <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    variantError: {
      content:
        "The variant <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    planSuccessful: {
      content:
        "The plan <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    planError: {
      content:
        "The plan <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    mobileVersionSuccessful: {
      content: "The version has been {{action}} successfully.",
    },
    mobileVersionError: {
      content:
        "The version could not be {{action}}, please try again in a few minutes.",
    },
    menuSuccessful: {
      content:
        "The menu <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    menuError: {
      content:
        "The menu <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    modelSuccessful: {
      content: "The model has been {{action}} successfully",
    },
    modelError: {
      content:
        "The model could not be {{action}}, please try again in a few minutes",
    },
    branchSuccessful: {
      content:
        "The branch <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    branchError: {
      content:
        "The branch <strong>{{name}}</strong> could not be {{action}}, by the following errors {{listErrors}} please try again in a few minutes.",
    },
    translationSuccessful: {
      content:
        "The translation <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    translationError: {
      content:
        "The translation <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    successfulPayment: {
      content: "Your payment has been processed successfully.",
    },
    errorPayment: {
      content:
        "Your payment could not be processed, please try again in a few minutes.",
    },
    notificationSuccessful: {
      content: "The notification has been sent successfully.",
    },
    notificationError: {
      content:
        "The notification could not be sent, please try again in a few minutes.",
    },
    menuClonedSuccessful: {
      content: "The menu has been cloned successfully",
    },
    menuClonedError: {
      content:
        "The menu could not be cloned, please try again in a few minutes.",
    },
    menuAsignedSuccessful: {
      content: "The menu has been assigned successfully",
    },
    menuAssignedError: {
      content:
        "The menu could not be assigned, please try again in a few minutes.",
    },
    suscriptionSuccessful: {
      content: "The subscription has been created successfully.",
    },
    suscriptionError: {
      content:
        "The subscription could not be created, please try again in a few minutes.",
    },
    cancelSuscriptionSucccessful: {
      content: "The subscription has been canceled successfully.",
    },
    cancelSuscriptionError: {
      content:
        "The subscription could not be canceled, please try again in a few minutes.",
    },
    emailSentSuccessful: {
      content: "The email has been sent successfully.",
    },
    emailSentError: {
      content:
        "The email could not be sent, please try again in a few minutes.",
    },
    mailTemplateSuccesful: {
      content: "The mail template has been saved successfully.",
    },
    mailTemplateError: {
      content:
        "The mail template could not be saved, please try again in a few minutes.",
    },
    tableSuccessful: {
      content:
        "The table <strong>{{name}}</strong> has been {{action}} successfully.",
    },
    tableError: {
      content:
        "The table <strong>{{name}}</strong> could not be {{action}}, please try again in a few minutes.",
    },
    multiTableSuccessful: {
      content: "The bulk table creation was successful.",
    },
    multiTableError: {
      content:
        "The bulk table creation could not be completed, please try again in a few minutes.",
    },
    entityAlreadyExist: {
      content:
        "There is already a {{entity}} with the name <strong>{{name}}</strong>.",
    },
    productClone: {
      title: "Clone Product",
      content: "Are you sure you want to clone the product {{title}}?",
      confirm: "Clone",
    },
    branchAssign: {
      title: "Select Branch",
      confirm: "Confirm",
      validate: "You must select at least one branch to continue",
    },
  },
};

export default modals;
