//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

//Components
import ScheduleItem from "../components/ScheduleItem";

//Assets
import "rc-time-picker/assets/index.css";
import AddIcon from "@material-ui/icons/Add";

//Utils
import * as actions from "../actions";

const SchedulesContainer = ({
  scheduleItems,
  setScheduleItems,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [itemId, setItemId] = useState(
    scheduleItems?.length > 0
      ? scheduleItems[scheduleItems.length - 1].id + 1
      : 0
  );

  const { register, unregister, setValue } = useForm();

  const handdleAddSchedule = () => {
    setScheduleItems([
      ...scheduleItems,
      { from: "08:00:00", to: "23:00:00", auxId: itemId + 1 },
    ]);
    setItemId(itemId + 1);
  };

  useEffect(() => {
    if (scheduleItems) {
      setItems(scheduleItems.filter((i) => !i._destroy));
    }
  }, [scheduleItems]);

  return (
    <>
      <section className="row">
        <section className="schedule-container">
          <div className="form-title">
            <h3>{t("entities.schedules.title")}</h3>
          </div>
          {!readOnly && (
            <div className="create-button" onClick={() => handdleAddSchedule()}>
              <AddIcon />
              {t("commons.buttons.add")}
            </div>
          )}
        </section>
      </section>
      <section className="row">
        <section className="schedule-items">
          {items.map((item, index) => (
            <ScheduleItem
              key={index}
              item={item}
              itemIndex={index}
              register={register}
              unregister={unregister}
              setValue={setValue}
              setScheduleItems={setScheduleItems}
              scheduleItems={scheduleItems}
              readOnly={readOnly}
            />
          ))}
        </section>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    schedulesItems: state.schedules,
  };
};

export default connect(mapStateToProps, actions)(SchedulesContainer);
