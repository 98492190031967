//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

//Components
import CountryPriceItem from "../components/CountryPriceItem";
import Spinner from "../components/Spinner";

//Assets
import "../stylesheets/itemsList.css";

//Utils
import { getAll } from "../utils/services";
import * as actions from "../actions";

const CountryPriceList = ({ planCountries }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    axios
      .get(getAll.countries)
      .then((res) => {
        setCountries(res.data.countries);
      })
      .then(() =>
        axios
          .get(getAll.currencies())
          .then((res) => setCurrencies(res.data.currencies))
      )
      .then(() => setIsLoading(false));
  }, []);

  return (
    <section className="items__container pt-0">
      {planCountries.length > 0 ? (
        isLoading ? (
          <Spinner loading={true} />
        ) : (
          <section className="items-list">
            <section className="row">
              <div className="items-list__header">
                <p className="entity-title">País</p>
                <p className="is_visible-title">Divisa</p>
                <p className="price-title">Precio</p>
                <p className="remove-title"></p>
              </div>
            </section>
            {planCountries.map((item, index) => {
              return item._destroy != 1 ? (
                <CountryPriceItem
                  key={index}
                  item={item}
                  countries={countries}
                  currencies={currencies}
                  countriesSelected={countriesSelected}
                  setCountriesSelected={setCountriesSelected}
                />
              ) : (
                <></>
              );
            })}
          </section>
        )
      ) : (
        <></>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    planCountries: state.planCountries,
  };
};

export default connect(mapStateToProps, actions)(CountryPriceList);
