//Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "users";

const Users = (props) => {
  const {
    setHasMore,
    setNextPage,
    setIsLoading,
    setUsers,
    clearUsers,
    loggedUser,
  } = props;

  let RESTAURANT_ID = window.localStorage.getItem('restaurantId');
  let BRANCH_ID = window.localStorage.getItem('branchId');

  RESTAURANT_ID = RESTAURANT_ID ? RESTAURANT_ID : loggedUser.restaurant_id;
  BRANCH_ID = BRANCH_ID ? BRANCH_ID : loggedUser.branch_id;

  const USERS_URL =
    loggedUser?.role === "super_admin"
      ? getAll.users()
      : loggedUser.role === "restaurant_admin"
      ? getAll.users(RESTAURANT_ID)
      : getAll.users(RESTAURANT_ID, BRANCH_ID);

  useEffect(() => {
    clearUsers();
    setIsLoading(true);
    axios
      .get(USERS_URL)
      .then((res) => {
        const { users } = res.data;
        setUsers(users);
        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false))
      .catch((error) => console.log(error));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(Users);
