//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setToppings,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearToppings,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "toppings";

const Toppings = (props) => {
  const {
    setHasMore,
    setNextPage,
    setToppings,
    setIsLoading,
    clearToppings,
    loggedUser,
  } = props;

  const { id } = useParams();
  const TOPPINGS_URL = getAll.toppings(
    id ? id : loggedUser.restaurant_id,
    loggedUser.role == "branch_user" ? loggedUser.branch_id : null
  );

  useEffect(() => {
    clearToppings();
    setIsLoading(true);
    axios
      .get(TOPPINGS_URL)
      .then((res) => {
        const { toppings } = res.data;

        setToppings(toppings);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);


  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setToppings,
  clearToppings,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toppings);
