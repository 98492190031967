//Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

//Components
import EntityItem from "../components/EntityItem";

//Assets
import "../stylesheets/itemsList.css";
import AddIcon from "@material-ui/icons/Add";

//Utils
import * as actions from "../actions";

const ItemsList = ({
  entity,
  entityItems,
  addEntityItem,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (entityItems.length > 0) {
      addEntityItem(entityItems);
    }
  }, []);

  const handdleAddItems = () => {
    addEntityItem([{ name: "", price: "", is_visible: null }]);
  };

  return (
    <section className="items__container">
      <section className="items__header">
        <section className="items-title">
          <p>
            {t(`forms.items.${entity}Title`, {
              entity: t(`entities.${entity}s.singular`),
            })}
          </p>
        </section>
        {!readOnly && (
          <section
            className="items-add-button"
            onClick={() => handdleAddItems()}
          >
            <div className="cartear-outline-button">
              <AddIcon />
              {t("forms.items.addItem")}
            </div>
          </section>
        )}
      </section>
      {entityItems.filter((item) => item._destroy != 1).length > 0 ? (
        <section className="items-list">
          <section className="row">
            <div className="items-list__header">
              <p className="entity-title">
                {t(`entities.${entity}s.singular`)}
              </p>
              <p className="price-title">{t("forms.items.price")}</p>
              <p className="is_visible-title">{t("forms.items.isVisible")}</p>
              <p className="remove-title"></p>
            </div>
          </section>
          {entityItems.sort((a, b) => a.id - b.id).map((item, index) => (
            <EntityItem key={item.key} item={item} readOnly={readOnly} />
          ))}
        </section>
      ) : (
        <></>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    entityItems: state.entityItems,
  };
};

export default connect(mapStateToProps, actions)(ItemsList);
