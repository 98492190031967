//Dependencies
import React from "react";

//Components
import TagForm from "../components/TagForm";

const TagNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva Etiqueta</h3>
      </div>
      <TagForm action={"create"} />
    </section>
  );
};

export default TagNew;
