import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > h1": {
      marginRight: "100px",
    },
  },
  progress: {
    color: "#e20713 !important",
  },
  button: {
    background:
      "linear-gradient(-14.55deg, #e20613 -61.49%, #ee7b5a 100.63%) !important",
    color: "white !important",
  },
  errors: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "200px",
  },
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  header: {
    display: "flex",
    justifyContent: "end",
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const CartearModal = ({ children, open, onClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <header className={classes.header}>
          <CloseIcon onClick={() => onClose()} className={classes.closeIcon} />
        </header>
        {children}
      </div>
    </Modal>
  );
};

export default CartearModal;
