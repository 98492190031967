import React from "react";
import { connect } from "react-redux";

//Utils
import * as actions from "../actions";

const Table = ({ table, setSelectedEntity }) => {
  const { name, is_active, pending_orders } = table;

  const handleShowActions = (e) => {
    setSelectedEntity(table);
    let actions_table = document.querySelector(".actions-table");

    actions_table.style.left = `${e.pageX}px`;
    actions_table.style.top = `${e.pageY}px`;
    actions_table.classList.toggle("show");
  };

  window.onclick = function (e) {
    const { target } = e;
    const { parentElement } = target;

    if (!target.matches(".actions") && !parentElement.matches(".actions")) {
      const dropdowns = document.getElementsByClassName("actions-table");
      for (let i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <div
      className={`table actions ${is_active ? "table__status--active" : ""}`}
      onClick={handleShowActions}
    >
      <div className="table__body actions">
        {is_active && (
          <>
            {pending_orders !== 0 && (
              <div className="table__unconfirmed-orders">{pending_orders}</div>
            )}
            <div className="table__status">ACTIVA</div>
          </>
        )}
        <div className="table__info actions">
          <span className="table__name">{name}</span>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(Table);
