//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

//Assets
import ClearIcon from "@material-ui/icons/Clear";
import cartearSelectStyles from "../utils/cartearSelectStyles";

//utils
import * as actions from "../actions";

const CountryPriceItem = ({
  item,
  planCountries,
  updatePlanCountry,
  removePlanCountry,
  countries,
  currencies,
  countriesSelected,
  setCountriesSelected,
}) => {
  const [countriesSelect, setCountriesSelect] = useState([]);
  const [currenciesSelect, setCurrenciesSelect] = useState([]);

  useEffect(() => {
    const countriesTransformed = countries
      .filter((country) => country.id === item?.country?.id)
      .map(({ id, name }) => ({
        value: id,
        label: name,
        isDisabled: true,
      }));
    setCountriesSelect(countriesTransformed);

    const currenciesTransformed = currencies
      .filter((currency) => currency.id == item?.currency?.id)
      .map(({ id, translations }) => ({
        value: id,
        label: translations[0].description,
      }));
    setCurrenciesSelect(currenciesTransformed);

    setCountriesSelected(planCountries.map(({ country }) => country));
  }, []);

  const handleChange = (value, action, setStateFunction = null) => {
    const inputRef = action?.name || value.target.name;

    if (setStateFunction) setStateFunction(value);

    const PLAN_COUNTRY_INDEX = planCountries.findIndex((pc) => pc === item);
    let pcUpdated = [...planCountries];
    pcUpdated[PLAN_COUNTRY_INDEX][inputRef] =
      value?.target?.value || value.value;
    updatePlanCountry(pcUpdated[PLAN_COUNTRY_INDEX]);

    setCountriesSelected([
      ...countriesSelected,
      value?.target?.value || value.value,
    ]);
  };

  const handleRemoveItem = (item) => {
    removePlanCountry(item);
  };

  return (
    <section className="row" key={item.id}>
      <section className="form-group w-60 item-is_visible__container">
        <Select
          name={`country_id`}
          value={countriesSelect}
          placeholder="Seleccione"
          options={countries.map((country) => ({
            label: country.name,
            value: country.id,
            isDisabled: countriesSelected.includes(country.id) ? true : false,
          }))}
          onChange={(value, action) => {
            handleChange(value, action, setCountriesSelect);
          }}
          styles={cartearSelectStyles}
        />
      </section>
      <section className="form-group w-20 item-is_visible__container">
        <Select
          name={`currency_id`}
          value={currenciesSelect}
          placeholder="Seleccione"
          options={currencies.map((currency) => ({
            label: currency.translations[0].description,
            value: currency.id,
          }))}
          onChange={(value, action) => {
            handleChange(value, action, setCurrenciesSelect);
          }}
          styles={cartearSelectStyles}
        />
      </section>
      <section className="form-group w-20 item-price__container">
        <input
          name={`price`}
          className="form-control item-price"
          defaultValue={item.price || null}
          onChange={(value, action) => {
            handleChange(value, action);
          }}
        />
      </section>
      <section
        className="row clear-icon__container"
        onClick={() => handleRemoveItem(item)}
      >
        <ClearIcon />
      </section>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    planCountries: state.planCountries,
  };
};

export default connect(mapStateToProps, actions)(CountryPriceItem);
