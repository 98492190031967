//Dependencies
import React from "react";

//Components
import AllergenForm from "../components/AllergenForm";

const AllergenNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Alérgeno</h3>
      </div>
      <AllergenForm action={"create"} />
    </section>
  );
};

export default AllergenNew;
