import React from "react";
import { Link } from "react-router-dom";
import "../stylesheets/card.css";
import "../stylesheets/cardPlanType.css";

const CardPlanType = ({
  klass,
  id,
  name,
  plan_countries,
  index,
  setIndexPlan,
  planIdToRenew,
  expireSoon,
}) => {
  const price = plan_countries[0].price;
  const symbol = plan_countries[0].currency.symbol;
  const plan_class = {
    2: "basic",
    1: "intermediate",
    0: "full",
  };

  const handleGetButtonText = () => {
    const buttonText =
      planIdToRenew === id && expireSoon ? "Renovar" : "Contratar";

    return buttonText;
  };

  return (
    <div className={`card plan ${plan_class[index]}`}>
      <div className="card-header">
        <span className="plan-type-title text">{name}</span>
        <div className="top-circle" />
      </div>
      <div className="card-footer">
        <div className="details">
          <button className="hire-button" onClick={() => setIndexPlan(index)}>
            <span>{handleGetButtonText()}</span>
          </button>
          <span className="text">
            {symbol} {price}
          </span>
          <div className="bottom-circle" />
        </div>
      </div>
    </div>
  );
};

export default CardPlanType;
