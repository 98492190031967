//Dependencies
import React from "react";
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

//Assets
import "../stylesheets/LoginLoader.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PageLoader = ({ loading }) => {
  return (
    <div className="login-loader-section">
      <PropagateLoader
        color={"#e20713"}
        loading={loading}
        css={override}
        size={30}
      />
      <h2>Iniciando sesión...</h2>
    </div>
  );
};

export default PageLoader;
