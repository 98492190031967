//Dependencies
import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

//Components
import ProfileImageUploader from "../components/ProfileImageUploader";

//Assets
import "../stylesheets/UserProfileForm.css";
import * as actions from "../actions";

//Utils
import { update } from "../utils/services";
import { modalUserSuccessful, modalUserError } from "../utils/modals";

const UserProfileForm = (props) => {
  const {
    id,
    first_name,
    last_name,
    avatar,
    updateUser,
    setLoggedUser,
    loggedUser,
    showLoadingOverlay,
  } = props;

  const history = useHistory();
  const { register, handleSubmit, setValue } = useForm();
  register("avatar");

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let user = {
      ...data,
    };

    axios
      .put(update.users(id, loggedUser.restaurant_id, loggedUser.branch_id), { user })
      .then((res) => {
        updateUser(res.data);
        setLoggedUser(res.data);
        modalUserSuccessful(
          "actualizado",
          `${data.first_name} ${data.last_name}`
        )}
      )
      .catch(() =>
        modalUserError("actualizar", `${data.first_name} ${data.last_name}`)
      )
      .finally(() => {
        showLoadingOverlay(false);
        history.push('/');
      });
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="user-profile-form-container">
          <section className="row justify-content-center mb-32">
            <ProfileImageUploader
              inputName={"avatar"}
              image={avatar}
              handleSetValue={setValue}
            />
          </section>
          <section className="row">
            <section className="user-profile-main-info">
              <section className="user-profile-name">
                <label>Nombre</label>
                <input
                  name="first_name"
                  className="form-control"
                  defaultValue={first_name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="user-profile-name">
                <label>Apellido</label>
                <input
                  name="last_name"
                  className="form-control"
                  defaultValue={last_name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
            </section>
          </section>
          <section className="row">
            <Link
              to={`/users/${loggedUser.id}/change_password`}
              className="text-red text-bold cursor-point decoration-none"
            >
              Cambiar contraseña
            </Link>
          </section>
        </section>

        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(UserProfileForm);
