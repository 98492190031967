//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import AccompanimentForm from "../components/AccompanimentForm";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const AccompanimentEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const { id } = useParams();
  const [items, setItems] = useState();
  const BRANCH_ID =
    loggedUser.role == "branch_user" ? loggedUser.branch_id : null;
  useEffect(() => {
    axios
      .get(get.accompaniments(id, loggedUser.restaurant_id, BRANCH_ID))
      .then((res) => {
        setSelectedEntity(res.data);
        setItems(res.data.items);
      });
  }, []);

  if (!items) return null;

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar Acompañamiento</h3>
      </div>
      <AccompanimentForm
        action={"edit"}
        entityItems={items}
        {...selectedEntity}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccompanimentEdit);
