//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";
import "../stylesheets/Notifications.css";

//Utils
import { getAll, create } from "../utils/services";
import {
  modalNotificationSuccessful,
  modalNotificationError,
} from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";

const ENTITY = "notifications";

const Notifications = (props) => {
  const { setIsLoading, setNotifications, clearNotifications } = props;
  const { register, handleSubmit, setValue } = useForm();

  const TOPICS_URL = getAll.topics;
  const NOTIFICATIONS_URL = getAll.notifications;
  const [topics, setTopics] = useState([]);
  const [topicSelect, setTopicSelect] = useState([]);

  useEffect(() => {
    clearNotifications();
    setIsLoading(true);
    axios
      .get(TOPICS_URL)
      .then((res) => {
        const { topics } = res.data;
        setTopics(
          topics.map((topic) => ({ label: topic.name, value: topic.id }))
        );
      })
      .then((res) => {
        axios.get(NOTIFICATIONS_URL).then((response) => {
          const { notifications } = response.data;
          setNotifications(notifications);
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        setNotifications([]);
      });
  }, []);

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value)
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  const onSubmit = (data, e) => {
    let notification = {
      ...data,
      topic_id: topicSelect.value,
      translations_attributes: [
        {
          description: data.body,
          locale: "es",
          att: "title",
        },
        {
          description: data.body,
          locale: "es",
          att: "body",
        },
      ],
    };

    axios
      .post(create[ENTITY], { notification })
      .then(() => modalNotificationSuccessful())
      .then(() => e.target.reset())
      .catch(() => modalNotificationError());
  };

  return (
    <>
      <section>
        <form className="send_notification" onSubmit={handleSubmit(onSubmit)}>
          <h3>Notificación</h3>
          <section className="row mb-16">
            <section className="notification-title">
              <label>
                Título de la notificación
                <span className="helper-text">(Máximo 65 caracteres)</span>
              </label>
              <input
                name="title"
                className="form-control"
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
            </section>
          </section>
          <section className="row flex-column mb-16">
            <label htmlFor="topic">Topic</label>
            <Select
              name="topic"
              value={topicSelect}
              placeholder="Seleccione"
              options={topics}
              onChange={(value, action) => {
                handleChange(value, action, setTopicSelect);
              }}
              styles={cartearSelectStyles}
              noOptionsMessage={() => "Sin resultados"}
            />
          </section>
          <section className="row">
            <section className="notification-description">
              <label>
                Mensaje
                <span className="helper-text">(Máximo 240 caracteres)</span>
              </label>
              <textarea
                name="body"
                className="form-control"
                placeholder="Escribí acá tu comentario..."
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
              <p>
                <span className="important-note">! Consejo:</span> No abusar de
                enviar gran cantidad de notificaciones en períodos cortos de
                tiempo para no molestar a los usuarios.
              </p>
            </section>
          </section>
          <section className="row">
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value="ENVIAR"
              />
            </section>
          </section>
        </form>
      </section>
      <Datatable entity={ENTITY} />
    </>
  );
};

export default connect(null, actions)(Notifications);
