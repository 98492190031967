import axios from "axios";

import { CheckHasMore } from "./CheckHasMore";

export const DatatableSortRows = (
  entity,
  actions,
  SORT_DATATABLE_URL,
  setIsLoading
) => {
  axios
    .get(SORT_DATATABLE_URL)
    .then((res) => {
      const {
        meta: {
          links: { next, last },
        },
      } = res.data;

      CheckHasMore(next, last, actions.setHasMore, actions.setNextPage);

      switch (entity) {
        case "restaurants":
          actions.clearRestaurants();
          actions.setRestaurants(res.data[entity]);

          break;
        case "branches":
          actions.clearBranches();
          actions.setBranches(res.data[entity]);

          break;
        case "menus":
          actions.clearMenus();
          actions.setMenus(res.data[entity]);

          break;

        case "countries":
          actions.clearCountries();
          actions.setCountries(res.data[entity]);

          break;
        case "products":
          actions.clearProducts();
          actions.setProducts(res.data[entity]);

          break;
        case "allergens":
          actions.clearAllergens();
          actions.setAllergens(res.data[entity]);

          break;
        case "tags":
          actions.clearTags();
          actions.setTags(res.data[entity]);

          break;
        case "payment_methods":
          actions.clearPaymentMethods();
          actions.setPaymentMethods(res.data[entity]);

          break;
        case "delivery_methods":
          actions.clearShippingMethods();
          actions.setShippingMethods(res.data[entity]);

          break;
        case "users":
          actions.clearUsers();
          actions.setUsers(res.data[entity]);

          break;
        case "currencies":
          actions.clearCurrencies();
          actions.setCurrencies(res.data[entity]);

          break;
        case "categories":
          actions.clearCategories();
          actions.setCategories(res.data[entity]);

          break;
        case "toppings":
          actions.clearToppings();
          actions.setToppings(res.data[entity]);

          break;
        case "accompaniments":
          actions.clearAccompaniments();
          actions.setAccompaniments(res.data[entity]);

          break;
        case "variants":
          actions.clearVariants();
          actions.setVariants(res.data[entity]);

          break;
        case "plans":
          actions.clearPlans();
          actions.setPlans(res.data[entity]);

          break;
        default:
          break;
      }
    })
    .then(() => setIsLoading(false));
};
