//Dependencies
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { connect } from "react-redux";

//Assets
import "../stylesheets/RestaurantForm.css";
import "react-credit-cards/es/styles-compiled.css";

//Utils
import { create, update } from "../utils/services";
import * as actions from "../actions";

const Pagos360 = ({
  plan_country,
  plan_name,
  loggedUser: { restaurant_name, restaurant_id, branch_name, branch_id },
  paymentId,
  promotion,
  total_price,
  showLoadingOverlay,
}) => {
  const { register, handleSubmit, setValue } = useForm();

  const plan_country_id = plan_country.id;

  const handleGoBack = () => {
    document.querySelector(".back-button").click();
  };

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let date = new Date().toLocaleString("de-DE").split(",")[0];
    date = date.replaceAll(".", "-");

    const body = {
      payment_request: {
        first_total: total_price,
        first_due_date: date,
        back_url_success: "",
        back_url_pending: "",
        back_url_rejected: "",
      },
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer NWQwMmNiNWYxNWQxMDdmN2VkY2U5MGQ5MjBjNDNjYWMzYzY2NTRiMDVkMjY4M2FmZGU1OWViNmVhMjc1ZmRmZg",
    };
    body.payment_request = {
      ...body.payment_request,
      ...data,
      description: `${
        plan_name.startsWith("Plan") ? plan_name : `Plan ${plan_name}`
      } x ${promotion?.period} ${promotion?.period > 1 ? "Meses" : "Mes"}`,
      payer_name: `${data.payer_name} - ${restaurant_name} - ${branch_name} - ${
        plan_name.startsWith("Plan") ? plan_name : `Plan ${plan_name}`
      }  (${promotion?.period} ${promotion?.period > 1 ? "Meses" : "Mes"})`,
    };
    axios
      .post("https://api.pagos360.com/payment-request", body, {
        headers: headers,
      })
      .then((res) => {
        if (paymentId) {
          axios
            .put(
              update.payment_transactions(restaurant_id, branch_id, paymentId),
              {
                ...res.data,
                transaction_id: res.data.id,
                branch_id: branch_id,
                plan_country_id: plan_country_id,
                id: paymentId ? paymentId : null,
                cycles: promotion.period,
              }
            )
            .then((res2) => {
              window.open(res2.data.checkout_url);
              showLoadingOverlay(false);
              handleGoBack();
            });
        } else {
          axios
            .post(create.payment_transactions(restaurant_id, branch_id), {
              ...res.data,
              transaction_id: res.data.id,
              branch_id: branch_id,
              plan_country_id: plan_country_id,
              id: null,
              cycles: promotion.period,
            })
            .then((res2) => {
              window.open(res2.data.checkout_url);
              showLoadingOverlay(false);
              handleGoBack();
            });
        }
      });
  };

  useEffect(() => {
    register("payer_name");
    register("description");
  });

  return (
    <section>
      <div className="form-title">
        <h3>Datos de Facturación</h3>
      </div>
      <section>
        <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
          <section className="row">
            <section className="form-group">
              <label htmlFor="payer_name">Nombre y Apellido</label>
              <input
                name="payer_name"
                className="form-control"
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
            </section>
          </section>
          <section className="row d-none">
            <section className="form-group">
              <label>Concepto de pago</label>
              <input
                name="description"
                className="form-control"
                value="Cartear - Suscripción"
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>Monto</label>
              <input
                name="first_total"
                className="form-control"
                value={total_price}
                readOnly={true}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value="IR A PAGAR"
              />
            </section>
          </section>
        </form>
      </section>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    payment_transactions: state.payment_transactions,
    paymentId: state.paymentId,
  };
};

export default connect(mapStateToProps, actions)(Pagos360);
