//Dependencies
import axios from "axios";

//Utils
import { update } from "./services";

export const SetToggleAttribute = (
  data,
  entity,
  att,
  filteredItems,
  actions,
  userRole,
) => {
  const { id, restaurant_id, branch_id } = data;
  const ITEM_INDEX = filteredItems.findIndex((item) => item.id === id);

  let updated_items = filteredItems;
  updated_items[ITEM_INDEX] = {
    ...updated_items[ITEM_INDEX],
    att: data[att],
  };
  if (entity === "menus" || entity === "advertisings") {
    let { activeMenu } = actions;
    if (userRole === "restaurant_admin") {
      if (!activeMenu || activeMenu.branch_id !== data.branch_id) {
        activeMenu = updated_items.find(
          (m) => (m.active || m.is_visible) && m.id !== data.id && m.branch_id === data.branch_id,
        );
      }
    } else {
      if (!activeMenu) {
        activeMenu = updated_items.find((m) => (m.active || m.is_visible) && m.id !== data.id);
      }
    }
    const ACTIVE_MENU_INDEX = filteredItems.findIndex(
      (i) => i.id === activeMenu?.id,
    );

    updated_items[ACTIVE_MENU_INDEX] = {
      ...updated_items[ACTIVE_MENU_INDEX],
      is_visible: false,
      active: false,
      att: false,
    };
  }

  switch (entity) {
    case "advertisings":
      const ADVERTISING_URL = update.advertisings(restaurant_id, id, branch_id);

      axios.put(ADVERTISING_URL, updated_items[ITEM_INDEX]).then((res) => {
        if(res.data.is_visible || res.data.active) {
          actions.setActiveMenu(res.data);
        } else {
          actions.setActiveMenu({});
        }
        actions.clearAdvertisings();
        actions.setAdvertisings(updated_items);
      });

      break;
    case "menus":
      const MENU_URL = update.menus(restaurant_id, id, branch_id);

      axios.put(MENU_URL, updated_items[ITEM_INDEX]).then((res) => {
        if(res.data.is_visible || res.data.active) {
          actions.setActiveMenu(res.data);
        } else {
          actions.setActiveMenu({});
        }
        actions.clearMenus();
        actions.setMenus(updated_items);
      });

      break;
    case "branches":
      const BRANCH_URL = update.branches(restaurant_id, id);

      axios.put(BRANCH_URL, updated_items[ITEM_INDEX]).then(() => {
        actions.clearBranches();
        actions.setBranches(updated_items);
      });

      break;
    case "products":
      const PRODUCT_URL = update.products(restaurant_id, id, branch_id);

      axios.put(PRODUCT_URL, updated_items[ITEM_INDEX]).then(() => {
        actions.clearProducts();
        actions.setProducts(updated_items);
      });

      break;
    case "toppings":
      const TOPPING_URL = update.toppings(restaurant_id, id);

      axios.put(TOPPING_URL, updated_items[ITEM_INDEX]).then(() => {
        actions.clearToppings();
        actions.setToppings(updated_items);
      });

      break;
    case "accompaniments":
      const ACCOMPANIMENT_URL = update.accompaniments(restaurant_id, id);

      axios.put(ACCOMPANIMENT_URL, updated_items[ITEM_INDEX]).then(() => {
        actions.clearAccompaniments();
        actions.setAccompaniments(updated_items);
      });

      break;
    case "variants":
      const VARIANT_URL = update.variants(restaurant_id, id);

      axios.put(VARIANT_URL, updated_items[ITEM_INDEX]).then(() => {
        actions.clearVariants();
        actions.setVariants(updated_items);
      });

      break;
    default:
      break;
  }
};
