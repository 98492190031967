//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setMenus,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearMenus,
  setActiveMenu,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";
import PageLoader from "../components/PageLoader";

const ENTITY = "menus";

const Menus = (props) => {
  const {
    isLoading,
    setHasMore,
    setNextPage,
    setMenus,
    setIsLoading,
    setActiveMenu,
    clearMenus,
    loggedUser,
  } = props;

  // const { id } = useParams();
  const MENUS_URL = getAll.menus(
    loggedUser.restaurant_id,
    loggedUser.branch_id,
  );

  useEffect(() => {
    clearMenus();
    setIsLoading(true);
    axios
      .get(MENUS_URL)
      .then((res) => {
        const { menus } = res.data;

        setMenus(menus);
        setActiveMenu(
          menus.find((m) => m.active && m.branch_id === loggedUser.branch_id),
        );

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return isLoading ? <PageLoader /> : <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setMenus,
  clearMenus,
  setHasMore,
  setNextPage,
  setIsLoading,
  setActiveMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
