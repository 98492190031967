//Dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";

//Components
import Card from "./Card";
import SubCard from "./SubCard";
import PageLoader from "./PageLoader";

//Assets
import "../stylesheets/dashboard.css";

//Utils
import { get } from "../utils/services";

const Dashboard = (props) => {
  const { loggedUser } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantStats, setRestaurantStats] = useState({});
  const [userStats, setUserStats] = useState({});
  const [menuStats, setMenuStats] = useState({});
  const [dishesStats, setDishesStats] = useState({});
  const [waitersStats, setWaitersStats] = useState({});
  const [ordersStats, setOrdersStats] = useState({});
  const [branchesStats, setBranchesStats] = useState({});
  const [planStats, setPlanStats] = useState({});
  const [cable, setCable] = useState("");
  const RESTAURANT_ID =
    loggedUser?.role !== "super_admin" ? loggedUser.restaurant_id : null;
  const BRANCH_ID = ["branch_admin", "branch_user"].includes(loggedUser?.role)
    ? loggedUser.branch_id
    : null;

  useEffect(() => {
    axios
      .get(get.restaurants_stadistics(RESTAURANT_ID, BRANCH_ID))
      .then((res) => {
        if (loggedUser?.role === "restaurant_admin") {
          const { menus, users, dishes, branches } = res.data.restaurant;
          setUserStats(users);
          setMenuStats(menus);
          setDishesStats(dishes);
          setBranchesStats(branches);
        } else if (loggedUser?.role === "branch_admin") {
          const { menus, users, dishes, orders = 0, waiters = 0 } = res.data;
          setUserStats(users);
          setMenuStats(menus);
          setDishesStats(dishes);
          setOrdersStats(orders);
          setWaitersStats(waiters);
        } else {
          const { restaurant, user, plan } = res.data;
          setRestaurantStats(restaurant);
          setUserStats(user);
          setPlanStats(plan);
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  const handleCopyToClipboard = () => {
    const DYNAMIC_LINK = document.getElementById("dynamic-link");
    DYNAMIC_LINK.select();
    DYNAMIC_LINK.setSelectionRange(0, 999999);
    document.execCommand("copy");
    DYNAMIC_LINK.setSelectionRange(0, 0);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    });

    Toast.fire({
      icon: "success",
      title: "El texto se ha copiado al portapapeles.",
    });
  };

  return (
    <div className="dashboard">
      {isLoading || !loggedUser ? (
        <section className="w-100 d-flex justify-content-center">
          <PageLoader loading={true} />
        </section>
      ) : (
        <>
          <div className="dashboard-card-list">
            {loggedUser.role === "restaurant_admin" ? (
              <>
                <Card title={"Total Número de Menús"} total={menuStats} />
                <Card title={"Total Número de Platos"} total={dishesStats} />
                <Card title={"Total Número de Usuarios"} total={userStats} />
              </>
            ) : loggedUser.role === "branch_admin" ? (
              <>
                <Card title={"Total Número de Menús"} total={menuStats} />
                <Card title={"Total Número de Platos"} total={dishesStats} />
                <Card title={"Total Número de Usuarios"} total={userStats} />
                {/* <Card title={"Total Número de Pedidos"} total={ordersStats} />
            <Card title={"Total Número de Mozos"} total={waitersStats} /> */}
                <section className="share-link__container">
                  <section className="share-link__title">
                    <h3>Compartí tu link en las Redes!</h3>
                  </section>
                  <section className="share-link">
                    <section className="form-group d-flex">
                      <input
                        id="dynamic-link"
                        name="dynamic-link"
                        className="form-control cartear-form-input"
                        defaultValue={loggedUser.dynamic_link_delivery}
                        readOnly
                      />
                      <div
                        className="btn cartear-outline-button ml-24 w-10"
                        onClick={() => handleCopyToClipboard()}
                      >
                        <p>COPIAR LINK</p>
                      </div>
                    </section>
                  </section>
                </section>
              </>
            ) : (
              <>
                <Card
                  title={"Número de Restaurantes"}
                  total={restaurantStats?.total}
                  visible={restaurantStats?.visible}
                  not_visible={restaurantStats?.not_visible}
                />
                <Card title={"Número de Usuarios"} total={userStats?.total} />
                <Card title={"Planes vigentes "} total={planStats?.total} />
              </>
            )}
          </div>
          <section className="dashboard-card-list">
            {branchesStats?.length > 0 ? (
              branchesStats.map((branchStat, index) => {
                return <SubCard {...branchStat} key={index} />;
              })
            ) : (
              <></>
            )}
          </section>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
