//Dependencies
import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";

//Assets
import "../stylesheets/modelsFilter.css";
import FilterListIcon from "@material-ui/icons/FilterList";

//Utils
import * as actions from "../actions";
import { get } from "../utils/services";

const ModelsFilterButton = (props) => {
  const { buttonName, setIsLoading, setModels, clearModels } = props;
  const { id } = useParams();

  const handleDropdown = (e) => {
    let models_filter = document.querySelector(".models-filter");

    models_filter.style.left = "71.5%";
    models_filter.style.top = "100%";
    models_filter.classList.toggle("show");
  };

  window.onclick = function (e) {
    const { target } = e;
    const { parentElement } = target;

    if (
      !target.matches(".models-filter") &&
      !parentElement.matches(".models-filter")
    ) {
      const dropdowns = document.getElementsByClassName("models-filter");
      for (let i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  const handleApplyModelFilter = () => {
    setIsLoading(true);
    document.querySelector(".models-filter").classList.remove("show");
    let custom_filter_url = "?";
    const uploaded = document.querySelectorAll("input[name='upload']");
    const no_uploaded = document.querySelectorAll("input[name='no-upload']");
    uploaded.forEach((current_cb) => {
      if (current_cb.checked)
        custom_filter_url +=
          current_cb.id === "ios"
            ? "custom_filters[charged][]=url_3d_ios&"
            : current_cb.id === "android"
            ? "custom_filters[charged][]=url_3d_android&"
            : "custom_filters[charged][]=url_3d_web&";
    });
    no_uploaded.forEach((current_cb) => {
      if (current_cb.checked)
        custom_filter_url +=
          current_cb.id === "no-ios"
            ? "custom_filters[not_charged][]=url_3d_ios&"
            : current_cb.id === "no-android"
            ? "custom_filters[not_charged][]=url_3d_android&"
            : "custom_filters[not_charged][]=url_3d_web&";
    });

    axios
      .get(`${get.models(id)}${custom_filter_url}`)
      .then((res) => {
        clearModels();
        setModels(res.data.products);
      })
      .then(() => setIsLoading(false));
  };

  return (
    <>
      <section className="filter-button" onClick={handleDropdown}>
        <FilterListIcon className="filter-button-icon filter-button" />
        {buttonName}
      </section>
      <div className="models-filter">
        <ul className="models-filter-list">
          <li className="models-filter-item">
            <div className="filter-title">Filtrar por:</div>
            <div className="models-section">
              <div className="top-section">
                <span>Cargados</span>
                <div className="title-line"></div>
              </div>
              <ul className="filter-options-container">
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="ios"
                    name="upload"
                    value="upload_ios"
                    className="cb-option"
                  />
                  <label htmlFor="ios">iOS</label>
                </li>
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="android"
                    name="upload"
                    value="upload_android"
                    className="cb-option"
                  />
                  <label htmlFor="android">Android</label>
                </li>
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="web"
                    name="upload"
                    value="upload_web"
                    className="cb-option"
                  />
                  <label htmlFor="web">Web</label>
                </li>
              </ul>
            </div>
            <div className="models-section mt-32">
              <div className="top-section">
                <span>No Cargados</span>
                <div className="title-line"></div>
              </div>
              <ul className="filter-options-container">
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="no-ios"
                    name="no-upload"
                    value="no-upload_ios"
                    className="cb-option"
                  />
                  <label htmlFor="no-ios">iOS</label>
                </li>
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="no-android"
                    name="no-upload"
                    value="no-upload_android"
                    className="cb-option"
                  />
                  <label htmlFor="no-android">Android</label>
                </li>
                <li className="uploaded-option">
                  <input
                    type="checkbox"
                    id="no-web"
                    name="no-upload"
                    value="no-upload_web"
                    className="cb-option"
                  />
                  <label htmlFor="no-web">Web</label>
                </li>
              </ul>
            </div>
            <div className="filter-button-section mt-32">
              <span onClick={() => handleApplyModelFilter()}>Aplicar</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default connect(null, actions)(ModelsFilterButton);
