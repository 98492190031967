//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

//Components
import ImageUploader from "../components/ImageUploader";
import VideoUploader from "../components/VideoUploader";

//Assets
import "../stylesheets/ProductForm.css";

//Utils
import { getAll, create, update } from "../utils/services";
import {
  is_recommended_options,
  is_visible_options,
  set_entity_limit_options,
} from "../utils/selectValues";
import { modalProductSuccessful, modalProductError } from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import * as actions from "../actions";

const ENTITY = "products";

const ProductForm = (props) => {
  const {
    id,
    translations,
    branch_id,
    image,
    video,
    price,
    dynamic_link,
    discount_ratio,
    discount_value,
    allergen_products,
    topping_products,
    accompaniment_products,
    variant_products,
    is_visible,
    is_recommended,
    action,
    updateProduct,
    loggedUser,
    showLoadingOverlay,
    title,
    description,
    url_3d_web,
    url_3d_ios,
    url_3d_android,
  } = props;

  const { t } = useTranslation();
  const DISCOUNT_RATIO = set_entity_limit_options([], 0, 100, 1, "%");

  const [toppings, setToppings] = useState([]);
  const [accompaniments, setAccompaniments] = useState([]);
  const [variants, setVariants] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [toppingsSelect, setToppingsSelect] = useState([]);
  const [accompanimentsSelect, setAccompanimentsSelect] = useState([]);
  const [variantsSelect, setVariantsSelect] = useState();
  const [allergensSelect, setAllergensSelect] = useState([]);
  const [isRecommendedSelect, setIsRecommendedSelect] = useState([]);
  const [isVisibleSelect, setIsVisibleSelect] = useState([
    { value: 1, label: "Si" },
  ]);
  const [discountRatioSelect, setDiscountRatioSelect] = useState([]);
  const [removedToppings, setRemovedToppings] = useState([]);
  const [removedAllergens, setRemovedAllergens] = useState([]);
  const [removedAccompaniments, setRemovedAccompaniments] = useState([]);
  const [removedVariants, setRemovedVariants] = useState();
  const canShareLink = url_3d_web || url_3d_ios || url_3d_android;

  const { register, handleSubmit, setValue, getValues } = useForm();
  const history = useHistory();

  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = branch_id || loggedUser.branch_id;
  const readOnly =
    ["branch_admin", "branch_user"].includes(loggedUser.role) &&
    !branch_id &&
    !!id;

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);

    let product = {
      ...data,
      is_visible: [0, 1].includes(data.is_visible)
        ? data.is_visible
        : isVisibleSelect[0].value,
      restaurant_id: RESTAURANT_ID,
      branch_id: BRANCH_ID,
      translations_attributes: data.translations_attributes,
    };

    const productName = data.translations_attributes.filter(
      (translation) => translation.att === "title",
    )[0].description;

    if (action === "create") {
      product = {
        ...product,
        allergen_products_attributes:
          data.allergen_products_attributes?.length > 0
            ? data.allergen_products_attributes?.map((value) => ({
                allergen_id: value?.value,
              }))
            : [],
        accompaniment_products_attributes:
          data.accompaniment_products_attributes?.length > 0
            ? data.accompaniment_products_attributes?.map((value) => ({
                accompaniment_id: value?.value,
              }))
            : [],
        topping_products_attributes:
          data.topping_products_attributes?.length > 0
            ? data.topping_products_attributes?.map((value) => ({
                topping_id: value?.value,
              }))
            : [],
        variant_products_attributes: data.variant_products_attributes
          ? [{ variant_id: data.variant_products_attributes.value }]
          : [],
      };

      axios
        .post(create[ENTITY](RESTAURANT_ID), { product })
        .then(() =>
          modalProductSuccessful("creado", productName, t, () =>
            history.goBack(),
          ),
        )
        .then(() => e.target.reset())
        .catch(() => modalProductError("crear", productName, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      product = {
        ...product,
        allergen_products_attributes:
          data.allergen_products_attributes?.length > 0
            ? [
                ...data.allergen_products_attributes.map((value) =>
                  value.id ? { id: value.id } : { allergen_id: value.value },
                ),
                ...removedAllergens,
              ]
            : [...removedAllergens],
        accompaniment_products_attributes:
          data.accompaniment_products_attributes?.length > 0
            ? [
                ...data.accompaniment_products_attributes.map((value) =>
                  value.id
                    ? { id: value.id }
                    : { accompaniment_id: value.value },
                ),
                ...removedAccompaniments,
              ]
            : [...removedAccompaniments],
        topping_products_attributes:
          data.topping_products_attributes?.length > 0
            ? [
                ...data.topping_products_attributes.map((value) =>
                  value.id ? { id: value.id } : { topping_id: value.value },
                ),
                ...removedToppings,
              ]
            : [...removedToppings],
        variant_products_attributes: data.variant_products_attributes
          ? [
              {
                id: variant_products[0]?.id,
                variant_id: variantsSelect?.value,
                _destroy: !!removedVariants,
              },
            ]
          : [removedVariants],
      };
      // VER LOGICA METER SACAR CON EL ID QUE VIENE DESDE EL PRODUCTO Y SIN EL ID QUE VIENE DESDE EL SELECT
      //
      axios
        .put(update[ENTITY](RESTAURANT_ID, id), { product })
        .then(() => {
          data.id = id;
          updateProduct(data);
        })
        .then(() =>
          modalProductSuccessful("actualizado", productName, t, () =>
            history.goBack(),
          ),
        )
        .catch(() => modalProductError("actualizar", productName, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null,
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondLanguages(
        secondLanguages.filter((lang) => lang.value !== value.label),
      );
    }

    if (action.action === "clear") {
      if (action.name === "topping_products_attributes[]") {
        setRemovedToppings(
          toppingsSelect.map((t) => ({ ...t, _destroy: true })),
        );
      } else if (action.name === "allergen_products_attributes[]") {
        setRemovedAllergens(
          allergensSelect.map((t) => ({ ...t, _destroy: true })),
        );
      } else if (action.name === "accompaniment_products_attributes[]") {
        setRemovedAccompaniments(
          accompanimentsSelect.map((t) => ({ ...t, _destroy: true })),
        );
      } else if (action.name === "variant_products_attributes[]") {
        setRemovedVariants({ ...variantsSelect, _destroy: true });
      }
    } else if (action.action === "remove-value") {
      if (
        action.name === "topping_products_attributes[]" &&
        action.removedValue.id
      ) {
        setRemovedToppings([
          ...removedToppings,
          { ...action.removedValue, _destroy: true },
        ]);
      } else if (
        action.name === "allergen_products_attributes[]" &&
        action.removedValue.id
      ) {
        setRemovedAllergens([
          ...removedAllergens,
          { ...action.removedValue, _destroy: true },
        ]);
      } else if (
        action.name === "accompaniment_products_attributes[]" &&
        removedValue.id
      ) {
        setRemovedAccompaniments([
          ...removedAccompaniments,
          { ...action.removedValue, _destroy: true },
        ]);
      } else if (
        action.name === "variant_products_attributes[]" &&
        removedValue.id
      ) {
        setRemovedVariants({ ...action.removedValue, _destroy: true });
      }
    } else {
      if (action.name === "topping_products_attributes[]") {
        const index = removedToppings.findIndex(
          (t) => t.value === action.option.value,
        );
        if (index !== -1) {
          removedToppings.splice(index, 1);
          setRemovedToppings([...removedToppings]);
        }
      } else if (action.name === "allergen_products_attributes[]") {
        const index = removedAllergens.findIndex(
          (t) => t.value === action.option.value,
        );
        if (index !== -1) {
          removedAllergens.splice(index, 1);
          setRemovedAllergens([...removedAllergens]);
        }
      } else if (action.name === "accompaniment_products_attributes[]") {
        const index = removedAccompaniments.findIndex(
          (t) => t.value === action.option.value,
        );
        if (index !== -1) {
          removedAccompaniments.splice(index, 1);
          setRemovedAccompaniments([...removedAccompaniments]);
        }
      } else if (action.name === "variant_products_attributes[]") {
        setRemovedVariants(null);
      }
    }

    setValue(inputRef, value);
    setStateFunction(value);
  };

  const handleCopyToClipboard = () => {
    const DYNAMIC_LINK = document.getElementById("dynamic-link");
    DYNAMIC_LINK.select();
    DYNAMIC_LINK.setSelectionRange(0, 999999);
    document.execCommand("copy");
    DYNAMIC_LINK.setSelectionRange(0, 0);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    });

    Toast.fire({
      icon: "success",
      title: t("modals.copyToClipboard.title"),
    });
  };

  useEffect(() => {
    register("image");
    register("video");
    register("translations_attributes[0][locale]");
    register("translations_attributes[1][locale]");
    register("discount_ratio");
    register("allergen_products_attributes[]");
    register("topping_products_attributes[]");
    register("accompaniment_products_attributes[]");
    register("variant_products_attributes[]");
    register("is_visible");
    register("is_recommended");

    axios
      .get(getAll.allergens())
      .then((res) => {
        const { allergens } = res.data;
        setAllergens(allergens);
      })
      .then(() => {
        axios.get(getAll.toppings(RESTAURANT_ID, BRANCH_ID)).then((res) => {
          const { toppings } = res.data;
          const AVAILABLE_TOPPINGS = toppings.filter((top) => !!top.is_visible);
          setToppings(AVAILABLE_TOPPINGS);
        });
      })
      .then(() => {
        axios
          .get(getAll.accompaniments(RESTAURANT_ID, BRANCH_ID))
          .then((res) => {
            const { accompaniments } = res.data;
            const AVAILABLE_ACCOMPANIMENTS = accompaniments.filter(
              (acc) => !!acc.is_visible,
            );
            setAccompaniments(AVAILABLE_ACCOMPANIMENTS);
          });
      })
      .then(() => {
        axios.get(getAll.variants(RESTAURANT_ID, BRANCH_ID)).then((res) => {
          const { variants } = res.data;
          const AVAILABLE_VARIANTS = variants.filter(
            (variant) => !!variant.is_visible,
          );
          setVariants(AVAILABLE_VARIANTS);
        });
      });

    if (action === "edit") {
      const allergensTransformed =
        allergen_products?.map(({ id, allergen }) => ({
          value: allergen?.id,
          label: allergen?.name,
          id: id,
        })) || null;

      const toppingsTransformed =
        topping_products?.map(({ id, topping }) => ({
          value: topping?.id,
          label: topping?.name,
          id: id,
        })) || null;

      const accompanimentsTransformed =
        accompaniment_products?.map(({ id, accompaniment }) => ({
          value: accompaniment?.id,
          label: accompaniment?.name,
          id: id,
        })) || null;

      const variantsTransformed = variant_products[0]
        ? {
            value: variant_products[0].variant?.id,
            label: variant_products[0].variant?.name,
            id: variant_products[0].id,
          }
        : null;

      const isVisibleTransformed = is_visible_options.filter(
        (op) => op.value == is_visible,
      );

      const discountRatioTransformed = DISCOUNT_RATIO.filter(
        (op) => op.value == discount_ratio,
      );

      const isRecommendedTransformed = is_recommended_options.filter(
        (op) => op.value == is_recommended,
      );

      setAllergensSelect(allergensTransformed);
      setToppingsSelect(toppingsTransformed);
      setAccompanimentsSelect(accompanimentsTransformed);
      setVariantsSelect(variantsTransformed);
      setIsVisibleSelect(isVisibleTransformed);
      setDiscountRatioSelect(discountRatioTransformed);
      setIsRecommendedSelect(isRecommendedTransformed);
    }
  }, []);

  const handleCalculatePrice = (value) => {
    let basePrice = document.getElementsByName("price")[0].value || 0;
    let discount = value;

    if (value === "price") {
      discount =
        document.getElementsByName("discount_ratio")[0].value != ""
          ? document.getElementsByName("discount_ratio")[0].value
          : 0;
    }

    let finalPriceInput = document.getElementsByName("discount_value");
    let discountValue =
      parseFloat(basePrice) -
      parseFloat(basePrice) * (parseFloat(discount) / 100);
    discountValue = isNaN(discountValue) ? "" : discountValue;
    finalPriceInput[0].value = discountValue?.toFixed(2);
  };

  return (
    <section>
      <form className="cartear-product-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="product-form-left">
          <section className="product-form-container">
            <section className="row">
              <section
                style={{ width: "30vw", color: "red", fontWeight: "bold" }}
              >
                {t("commons.imageUploader.product.warning")}
                <ImageUploader
                  inputName={"image"}
                  image={image}
                  handleSetValue={setValue}
                  readOnly={readOnly}
                />
              </section>
              <section
                style={{ width: "30vw", color: "red", fontWeight: "bold", marginLeft: "20px", marginTop: "40px" }}
              >
                <VideoUploader
                  inputName={"video"}
                  image={image}
                  handleSetValue={setValue}
                  readOnly={readOnly}
                />
              </section>
              <section className="product-main-info">
                <section className="product-name">
                  <label>{t("forms.product.title")}</label>
                  <input
                    readOnly={readOnly}
                    name="translations_attributes[0][description]"
                    maxLength={60}
                    className="form-control"
                    defaultValue={title}
                    ref={register({
                      required: true,
                      message: t("forms.isRequired"),
                    })}
                  />
                  <input
                    type="hidden"
                    name="translations_attributes[0][id]"
                    value={
                      !!translations && translations.length > 0
                        ? translations.filter((prod) => prod.att === "title")
                            .length > 0
                          ? parseInt(
                              translations.filter(
                                (prod) => prod.att === "title",
                              )[0].id,
                            )
                          : null
                        : null
                    }
                    ref={register}
                  />
                  <input
                    type="hidden"
                    name="translations_attributes[0][att]"
                    value="title"
                    ref={register}
                  />
                  <input
                    type="hidden"
                    name="translations_attributes[0][locale]"
                    value={loggedUser.primary_lang}
                    ref={register}
                  />
                </section>
                <section className="row">
                  <section className="form-group">
                    <label htmlFor="is_recommended">
                      {t("forms.product.isRecommended")}
                    </label>
                    <Select
                      name="is_recommended"
                      value={isRecommendedSelect}
                      placeholder={t("commons.placeholders.select")}
                      isDisabled={readOnly}
                      options={is_recommended_options}
                      onChange={(value) => {
                        setValue("is_recommended", value.value);
                        setIsRecommendedSelect(value);
                      }}
                      styles={cartearSelectStyles}
                    />
                  </section>
                </section>
                {!id && (
                  <section className="row">
                    <section className="form-group">
                      <label htmlFor="is_visible">
                        {t("forms.product.isVisible")}
                      </label>
                      <Select
                        name="is_visible"
                        value={isVisibleSelect}
                        placeholder={t("commons.placeholders.select")}
                        isDisabled={readOnly}
                        options={is_visible_options}
                        onChange={(value) => {
                          setValue("is_visible", value.value);
                          setIsVisibleSelect(value);
                        }}
                        styles={cartearSelectStyles}
                      />
                    </section>
                  </section>
                )}
              </section>
            </section>
          </section>
          {!id && (
            <section className="row">
              <section className="form-group">
                <label>{t("forms.product.price")}</label>
                <input
                  readOnly={readOnly}
                  name="price"
                  className="form-control"
                  value={price}
                  ref={register({
                    required: true,
                    message: t("forms.isRequired"),
                  })}
                  onChange={(e) => {
                    let price = e.target.value;
                    if (price.match(/^\d+(\.\d{0,2})?$|^$/)) {
                      setValue("price", price);
                      handleCalculatePrice(e.target.name);
                    }
                  }}
                />
              </section>
              <section className="separator"></section>
              <section className="form-group">
                <label htmlFor="discount_ratio">
                  {t("forms.product.discount")}
                </label>
                <Select
                  isDisabled={readOnly}
                  name="discount_ratio"
                  value={discountRatioSelect}
                  placeholder={t("commons.placeholders.select")}
                  options={DISCOUNT_RATIO.map((discount) => ({
                    label: discount.label,
                    value: discount.value,
                  }))}
                  onChange={(value) => {
                    handleCalculatePrice(value.value);
                    setValue("discount_ratio", value.value);
                    setDiscountRatioSelect(value);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
              <section className="separator"></section>
              <section className="form-group">
                <label htmlFor="discount_value">
                  {t("forms.product.finalPrice")}
                </label>
                <input
                  name="discount_value"
                  readOnly
                  className="form-control"
                  defaultValue={discount_value?.toFixed(2)}
                  ref={register({
                    required: true,
                    message: t("forms.isRequired"),
                  })}
                />
              </section>
            </section>
          )}
          <section className="row">
            <section className="product-description">
              <label>{t("forms.product.description")}</label>
              <textarea
                name="translations_attributes[1][description]"
                className="form-control"
                defaultValue={description}
                ref={register}
                maxLength={350}
                readOnly={readOnly}
              />
              <input
                readOnly={readOnly}
                type="hidden"
                name="translations_attributes[1][id]"
                value={
                  !!translations && translations.length > 0
                    ? translations.filter((prod) => prod.att === "description")
                        .length > 0
                      ? parseInt(
                          translations.filter(
                            (prod) => prod.att === "description",
                          )[0].id,
                        )
                      : null
                    : null
                }
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[1][att]"
                value="description"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[1][locale]"
                value="es"
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            {!readOnly && (
              <section className="form-submit-btn">
                <input
                  type="submit"
                  className="btn gradient-button"
                  value={t("commons.buttons.save")}
                />
              </section>
            )}
          </section>
        </section>
        {!id && (
          <section className="product-form-right">
            <section className="row">
              <section className="form-group">
                <label htmlFor="allergen_products_attributes[]">
                  {t("forms.product.allergens")}
                </label>
                <Select
                  name="allergen_products_attributes[]"
                  value={allergensSelect}
                  isMulti
                  isDisabled={readOnly}
                  placeholder="Seleccione"
                  options={allergens.map((al) => ({
                    label: al.name,
                    value: al.id,
                  }))}
                  isOptionSelected={(o, a) =>
                    a.map((a) => a.value).includes(o.value)
                  }
                  onChange={(value, action) => {
                    handleChange(value, action, setAllergensSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
            </section>
            <section className="row">
              <section className="form-group">
                <label htmlFor="topping_products_attributes[]">
                  {t("forms.product.toppings")}
                </label>
                <Select
                  isDisabled={readOnly}
                  name="topping_products_attributes[]"
                  value={toppingsSelect}
                  isMulti
                  isOptionSelected={() => null}
                  placeholder="Seleccione"
                  options={toppings.map((al) => ({
                    label: al.name,
                    value: al.id,
                  }))}
                  onChange={(value, action) => {
                    handleChange(value, action, setToppingsSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
            </section>
            <section className="row">
              <section className="form-group">
                <label htmlFor="accompaniment_products_attributes[]">
                  {t("forms.product.accompaniments")}
                </label>
                <Select
                  isDisabled={readOnly}
                  name="accompaniment_products_attributes[]"
                  value={accompanimentsSelect}
                  isMulti
                  isOptionSelected={() => null}
                  placeholder="Seleccione"
                  options={accompaniments.map((al) => ({
                    label: al.name,
                    value: al.id,
                  }))}
                  onChange={(value, action) => {
                    handleChange(value, action, setAccompanimentsSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
            </section>
            <section className="row">
              <section className="form-group">
                <label htmlFor="variant_products_attributes[]">
                  {t("forms.product.variants")}
                </label>
                <Select
                  isDisabled={readOnly}
                  name="variant_products_attributes[]"
                  isClearable
                  value={variantsSelect}
                  placeholder="Seleccione"
                  options={variants.map((al) => ({
                    label: al.name,
                    value: al.id,
                  }))}
                  onChange={(value, action) => {
                    handleChange(value, action, setVariantsSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
            </section>
          </section>
        )}
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(ProductForm);
