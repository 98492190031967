//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import PlanPromotionForm from "../components/PlanPromotionForm";
import Spinner from "../components/Spinner";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const PlanPromotionEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedEntity, selectedEntity, loggedUser } = props;

  const { id, prom_id } = useParams();
  useEffect(() => {
    axios.get(get.plans(id)).then((res) => {
      const PROMOTION = res.data.plan_promotions.find((pp) => pp.id == prom_id);
      setSelectedEntity(PROMOTION);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <section className="container">
      <div className="form-title">
        <h3>Editar Promoción</h3>
      </div>
      <PlanPromotionForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanPromotionEdit);
