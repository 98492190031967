// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faHome,
  faUtensils,
  faExclamationTriangle,
  faTag,
  faCreditCard,
  faTruck,
  faMoneyBillAlt,
  faUsers,
  faStar,
  faMobileAlt,
  faCodeBranch,
  faBullhorn,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faUtensils,
  faExclamationTriangle,
  faTag,
  faCreditCard,
  faTruck,
  faMoneyBillAlt,
  faUsers,
  faStar,
  faMobileAlt,
  faCodeBranch,
  faBullhorn,
  faEnvelope
);
