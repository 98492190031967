//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

//Assets
import cartearSelectStyles from "../utils/cartearSelectStyles";
import unavailableImage from "../images/unavailable-image.jpg";

//Utils
import { set_entity_limit_options } from "../utils/selectValues";
import * as actions from "../actions";

const CategoryProduct = ({
  loggedUser,
  allergens,
  variants,
  toppings,
  accompaniments,
  dish,
  removeMenuCategoryProduct,
  updateMenuCategoryProduct,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const { register, setValue } = useForm();
  const [toppingsSelect, setToppingsSelect] = useState([]);
  const [accompanimentsSelect, setAccompanimentsSelect] = useState([]);
  const [variantsSelect, setVariantsSelect] = useState([]);
  const [allergensSelect, setAllergensSelect] = useState([]);
  const [discountValue, setDiscountValue] = useState(
    parseFloat(dish.discount_value || dish.price)?.toFixed(2),
  );
  const [dishVisibility, setDishVisilibity] = useState(
    dish ? dish.is_visible : true,
  );
  const [discountRatioSelect, setDiscountRatioSelect] = useState({
    label: dish.discount_ratio ? `${dish.discount_ratio}%` : "Sin descuento",
    value: dish.discount_ratio || 0,
  });
  const [dishPrice, setDishPrice] = useState();
  const DISCOUNT_RATIO = set_entity_limit_options([], 0, 100, 1, "%");
  const readOnlyPrice = !loggedUser.permissions.can_view_price;
  const canHandleVisibility = loggedUser.permissions.can_handle_visibility;

  const PRODUCT_NAME = dish.title;
  const PRODUCT_DESC = dish.description;
  const handleCopyToClipboard = (dish) => {
    const DYNAMIC_LINK = document.getElementById(`${dish.id}-dynamic-link`);
    DYNAMIC_LINK.select();
    DYNAMIC_LINK.setSelectionRange(0, 999999);
    document.execCommand("copy");
    DYNAMIC_LINK.setSelectionRange(0, 0);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    });

    Toast.fire({
      icon: "success",
      title: t("modals.copyToClipboard.title"),
    });
  };

  useEffect(() => {
    setDishPrice(dish.price);
    setDiscountValue(parseFloat(dish.discount_value || dish.price)?.toFixed(2));
    setDiscountRatioSelect({
      label: dish.discount_ratio ? `${dish.discount_ratio}%` : "Sin descuento",
      value: dish.discount_ratio || 0,
    });
  }, [dish]);

  useEffect(() => {
    const allergensTransformed =
      dish.dish_allergens_attributes?.map(
        ({ id, allergen_id, value, label, _destroy }) => ({
          id: id,
          value: allergen_id || value,
          _destroy: _destroy,
          label:
            allergens.filter(({ id }) => id == allergen_id)[0]?.name ||
            label ||
            `Allergen ${allergen_id}`,
        }),
      ) || [];

    const toppingsTransformed =
      dish.dish_toppings_attributes?.map(
        ({ id, topping_id, value, label, _destroy }) => ({
          id: id,
          value: topping_id || value,
          _destroy: _destroy,
          label:
            toppings.filter(({ id }) => id == topping_id)[0]?.name ||
            label ||
            `Topping ${topping_id}`,
        }),
      ) || [];

    const accompanimentsTransformed =
      dish.dish_accompaniments_attributes?.map(
        ({ id, accompaniment_id, value, label, _destroy }) => ({
          id: id,
          value: accompaniment_id || value,
          _destroy: _destroy,
          label:
            accompaniments?.filter(({ id }) => id == accompaniment_id)[0]
              ?.name ||
            label ||
            `Acompañamiento ${accompaniment_id}`,
        }),
      ) || [];

    const variantsTransformed =
      dish.dish_variants_attributes?.map(
        ({ id, variant_id, value, label, _destroy }) => ({
          id: id,
          value: variant_id || value,
          _destroy: _destroy,
          label:
            variants?.filter(({ id }) => id == variant_id)[0]?.name ||
            label ||
            `Variante ${variant_id}`,
        }),
      ) || [];

    setAccompanimentsSelect(accompanimentsTransformed);
    setToppingsSelect(toppingsTransformed);
    setVariantsSelect(variantsTransformed);
    setAllergensSelect(allergensTransformed);
  }, []);

  const filterObjects = (currentObjects, childKey, action) => {
    const objects = [...currentObjects];
    if (action.action === "remove-value") {
      if (action.removedValue.id) {
        return objects.map((o) => {
          if ([o.id, o[childKey]].includes(action.removedValue.id)) {
            return { ...o, _destroy: true };
          }
          return o;
        });
      } else {
        const index = objects.findIndex(
          (o) => o.value === action.removedValue.value,
        );
        objects.splice(index, 1);
        return objects;
      }
    } else if (action.action === "clear") {
      return objects.map((o) => ({ ...o, _destroy: true }));
    } else {
      const newObject = { ...action.option };
      newObject[childKey] = action.option.value;
      objects.push(newObject);
      return objects;
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null,
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondLanguages(
        secondLanguages.filter((lang) => lang.value !== value.label),
      );
    }

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value),
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  const handleCalculatePrice = (value) => {
    let basePrice =
      document.getElementById(`price-${dish.category_id}-${dish.product_id}`)
        .value || 0;
    let discount = value;

    if (value === "price") {
      discount =
        document.getElementById(
          `discount_ratio-${dish.category_id}-${dish.product_id}`,
        ).children[2].value != ""
          ? document.getElementById(
              `discount_ratio-${dish.category_id}-${dish.product_id}`,
            ).children[2].value
          : 0;
    }

    let finalPriceInput = document.getElementById(
      `discount_value-${dish.category_id}-${dish.product_id}`,
    );

    finalPriceInput.value = (
      parseFloat(basePrice) -
      (parseFloat(basePrice) * parseInt(discount)) / 100
    ).toFixed(2);

    updateMenuCategoryProduct({
      ...dish,
      price: basePrice,
      discount_ratio: discount,
      discount_value: finalPriceInput.value,
    });
  };

  const handleVisibility = () => {
    const newVisibility = !dishVisibility;
    setDishVisilibity(newVisibility);
    updateMenuCategoryProduct({
      ...dish,
      is_visible: newVisibility,
    });
  };

  const handleRemoveProduct = (dish) => {
    swal({
      title: t("modals.removeProduct.title", { productName: PRODUCT_NAME }),
      text: null,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeMenuCategoryProduct(dish);
      }
    });
  };

  return (
    <section className="category-product" style={{ height: "900px" }}>
      <section className="w-30 category-product-image">
        <img
          src={dish.image || unavailableImage}
          alt="Entity Image"
          id={`entity-${dish.category_id}-${dish.product_id}`}
        />
      </section>
      <section className="w-70 category-product-details">
        <section className="category-product-details__header">
          <section className="category-product-details__header-top">
            <section className="category-product-details__header-title">
              <h2>{PRODUCT_NAME}</h2>
            </section>
            <section className="category-product-details__header-buttons">
              <span
                onClick={() => {
                  if (canHandleVisibility) {
                    handleVisibility();
                  }
                }}
                className={`${
                  dishVisibility ? "toggle-button_green" : "toggle-button_red"
                }`}
              >
                {dishVisibility
                  ? t("forms.product.visible")
                  : t("forms.product.hide")}
              </span>
              {!readOnly && (
                <span
                  className="text-red"
                  onClick={() => handleRemoveProduct(dish)}
                >
                  {t("forms.categoryProduct.removeProduct")}
                </span>
              )}
            </section>
          </section>
          <section className="category-product-details__header-description">
            {PRODUCT_DESC}
          </section>
        </section>
        <section className="category-product-details__body">
          <section className="row">
            <section className="form-group">
              <label htmlFor="dish_allergens_attributes">
                {t("entities.allergens.title")}
              </label>
              <Select
                isDisabled={readOnly}
                name="dish_allergens_attributes"
                value={allergensSelect?.filter((a) => !a._destroy)}
                placeholder={t("commons.placeholders.select")}
                isClearable
                isMulti
                options={allergens?.map((al) => ({
                  label: al.name || al.label,
                  value: al.id,
                }))}
                onChange={(_, action) => {
                  const objects = filterObjects(
                    allergensSelect,
                    "allergen_id",
                    action,
                  );
                  updateMenuCategoryProduct({
                    ...dish,
                    dish_allergens_attributes: objects,
                  });
                  setAllergensSelect([...objects]);
                }}
                styles={cartearSelectStyles}
                noOptionsMessage={() => t("forms.withoutResult")}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label htmlFor="dish_variants_attributes">Variantes</label>
              <Select
                isDisabled={readOnly}
                name="dish_variants_attributes"
                value={variantsSelect?.filter((v) => !v._destroy)}
                placeholder={t("commons.placeholders.select")}
                isClearable
                options={variants?.map((al) => ({
                  label: al.name || al.label,
                  value: al.id,
                }))}
                onChange={(value, action) => {
                  action.option = value;
                  const objects = filterObjects(
                    variantsSelect,
                    "variant_id",
                    action,
                  );
                  updateMenuCategoryProduct({
                    ...dish,
                    dish_variants_attributes: objects,
                  });
                  setVariantsSelect([...objects]);
                }}
                styles={cartearSelectStyles}
                noOptionsMessage={() => t("forms.withoutResult")}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label htmlFor="dish_toppings_attributes">
                {t("entities.toppings.title")}
              </label>
              <Select
                isDisabled={readOnly}
                name="dish_toppings_attributes"
                value={toppingsSelect?.filter((t) => !t._destroy)}
                isMulti
                placeholder={t("commons.placeholders.select")}
                options={toppings?.map((al) => ({
                  label: al.name || al.label,
                  value: al.id,
                }))}
                onChange={(_, action) => {
                  const objects = filterObjects(
                    toppingsSelect,
                    "topping_id",
                    action,
                  );
                  updateMenuCategoryProduct({
                    ...dish,
                    dish_toppings_attributes: objects,
                  });
                  setToppingsSelect([...objects]);
                }}
                styles={cartearSelectStyles}
                noOptionsMessage={() => t("forms.withoutResult")}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label htmlFor="dish_accompaniments_attributes">
                {t("entities.accompaniments.title")}
              </label>
              <Select
                isDisabled={readOnly}
                name="dish_accompaniments_attributes"
                value={accompanimentsSelect.filter((a) => !a._destroy)}
                isMulti
                placeholder={t("commons.placeholders.select")}
                options={accompaniments?.map((acc) => ({
                  label: acc.name || acc.label,
                  value: acc.id,
                }))}
                onChange={(_, action) => {
                  const objects = filterObjects(
                    accompanimentsSelect,
                    "accompaniment_id",
                    action,
                  );
                  updateMenuCategoryProduct({
                    ...dish,
                    dish_accompaniments_attributes: objects,
                  });
                  setAccompanimentsSelect([...objects]);
                }}
                styles={cartearSelectStyles}
                noOptionsMessage={() => t("forms.withoutResult")}
              />
            </section>
          </section>
        </section>
        <section
          className="category-product-details__footer"
          style={{ marginTop: "-80px" }}
        >
          <section className="row" style={{ marginTop: "15px" }}>
            <section className="form-group">
              <label>{t("forms.product.price")}</label>
              <input
                readOnly={readOnly || readOnlyPrice}
                id={`price-${dish.category_id}-${dish.product_id}`}
                name="price"
                className="form-control"
                value={dishPrice}
                ref={register({
                  required: true,
                  message: t("forms.inputRequired"),
                })}
                onChange={(e) => {
                  if (!readOnly && !readOnlyPrice) {
                    let price = e.target.value;
                    if (price.match(/^\d+(\.\d{0,2})?$|^$/)) {
                      setDishPrice(price);
                      const discountValue = (
                        (parseFloat(price) *
                          (100 - discountRatioSelect.value)) /
                        100
                      ).toFixed(2);
                      const finalValue = isNaN(discountValue)
                        ? ""
                        : discountValue;
                      setDiscountValue(finalValue);
                      updateMenuCategoryProduct({
                        ...dish,
                        price: price,
                        discount_value: finalValue,
                      });
                    }
                  }
                }}
              />
            </section>
            <section className="separator"></section>
            <section className="form-group">
              <label htmlFor="discount_ratio">
                {t("forms.product.discount")}
              </label>
              <Select
                isDisabled={readOnly || readOnlyPrice}
                id={`discount_ratio-${dish.category_id}-${dish.product_id}`}
                name="discount_ratio"
                value={discountRatioSelect}
                placeholder={t("commons.placeholders.select")}
                options={DISCOUNT_RATIO.map((discount) => ({
                  label: discount.label,
                  value: discount.value,
                }))}
                onChange={(value, action) => {
                  if (!readOnly && !readOnlyPrice) {
                    handleCalculatePrice(value.value);
                    handleChange(value, action, setDiscountRatioSelect);
                  }
                }}
                styles={cartearSelectStyles}
              />
            </section>
            <section className="separator"></section>
            <section className="form-group">
              <label htmlFor="discount_value">
                {t("forms.product.finalPrice")}
              </label>
              <input
                readOnly={readOnly || readOnlyPrice}
                id={`discount_value-${dish.category_id}-${dish.product_id}`}
                name="discount_value"
                className="form-control"
                value={discountValue}
                ref={register({
                  required: true,
                  message: t("forms.inputRequired"),
                })}
              />
            </section>
          </section>
          <section className="separator"></section>
          <section className="row">
            <section className="form-group">
              <h3>Compartí tu link en las Redes!</h3>
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <input
                id={`${dish.id}-dynamic-link`}
                name="dynamic-link"
                className="form-control cartear-form-input"
                defaultValue={dish.dynamic_link}
                readOnly
              />
            </section>
            <section className="separator"></section>
            <section className="form-group">
              <div
                className="btn cartear-outline-button w-30"
                onClick={() => handleCopyToClipboard(dish)}
              >
                <p>COPIAR LINK</p>
              </div>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default connect(null, actions)(CategoryProduct);
