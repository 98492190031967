import React, { useState } from "react";
import { useTranslation } from "react-i18next"
import axios from "axios";

//Utils
import { create } from "../utils/services";
import {
  modalTableSuccessful,
  modalTableError,
  modalMultiTableSuccessful,
  modalMultiTableError,
  modalEntityAlreadyExist,
} from "../utils/modals";

const CreateTableModal = ({ loggedUser, tables, setTables }) => {
  const { t } = useTranslation();
  const [tableName, setTableName] = useState("");
  const [massiveFrom, setMassiveFrom] = useState(1);
  const [massiveTo, setMassiveTo] = useState(5);
  const [isMassive, setIsMassive] = useState(false);

  const { restaurant_id, branch_id } = loggedUser;

  const handleCreateTable = () => {
    const table = {
      name: !isMassive ? tableName : undefined,
      prefix: isMassive ? "" : undefined,
      from: isMassive ? massiveFrom : undefined,
      to: isMassive ? massiveTo : undefined,
    };

    axios
      .post(create.tables(restaurant_id, branch_id), table)
      .then((res) => {
        if (isMassive) {
          modalMultiTableSuccessful(t);
          handleCloseModal();
        } else {
          modalTableSuccessful("creada", table.name, t);
          handleCloseModal();
        }

        let tablesArr;
        if (isMassive) {
          tablesArr = [...tables, ...res.data];
        } else {
          tablesArr = tables;
          tablesArr.push(res.data);
        }

        setTables(tablesArr);
      })
      .catch((e) => {
        if (isMassive) {
          modalMultiTableError(t);
        } else {
          if (e?.response?.data?.errors?.name[0] === "has already been taken") {
            modalEntityAlreadyExist("mesa", table.name, t);
          } else {
            modalTableError("crear", table.name, t);
          }
        }
      });
  };

  const handleCloseModal = () => {
    const closeButton = document.querySelector(".swal2-close");
    closeButton.click();
  };

  const handleChangeFrom = (value) => {
    const FROM_VALUE = parseInt(value) < 0 ? 1 : value;
    if (parseInt(massiveTo) < FROM_VALUE) setMassiveTo(parseInt(value) + 5);

    setMassiveFrom(FROM_VALUE);
  };

  const handleChangeTo = (value) => {
    const TO_VALUE =
      parseInt(value) < parseInt(massiveFrom)
        ? parseInt(massiveFrom) + 1
        : parseInt(value) > parseInt(massiveFrom) + 200
        ? parseInt(massiveFrom) + 200
        : value;
    setMassiveTo(TO_VALUE);
  };

  return (
    <div>
      {!isMassive && (
        <section className="form-group d-flex justify-content-center align-items-center">
          <label className="nowrap mr-16" htmlFor="new-table-name">
            Nombre de la mesa:
          </label>
          <input
            type="text"
            className="form-control"
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
            disabled={isMassive}
          />
        </section>
      )}
      <section className="massive_creation mb-16 mt-32">
        <label htmlFor="massive_creation" className="mr-8">
          Creación masiva
        </label>
        <input
          type="checkbox"
          className="branch-cb"
          checked={isMassive}
          onChange={() => setIsMassive((prevState) => !prevState)}
        />
      </section>
      <section className="from-to__section d-flex justify-content-space-between flex-column">
        {isMassive && (
          <>
            <span className="helper-text p-0">(Máximo 200 mesas)</span>
            <div className="d-flex justify-content-space-between">
              <section className="form-group d-flex justify-content-center align-items-center mr-16">
                <label className="nowrap mr-16" htmlFor="table-from">
                  Desde:
                </label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  max={parseInt(massiveTo) - 1}
                  value={massiveFrom}
                  onChange={(e) => handleChangeFrom(e.target.value)}
                  disabled={!isMassive}
                />
              </section>
              <section className="form-group d-flex justify-content-center align-items-center">
                <label className="nowrap mr-16" htmlFor="table-to">
                  Hasta:
                </label>
                <input
                  type="number"
                  className="form-control"
                  min={parseInt(massiveFrom) + 1}
                  max={parseInt(massiveTo) + 200}
                  value={massiveTo}
                  onChange={(e) => handleChangeTo(e.target.value)}
                  disabled={!isMassive}
                />
              </section>
            </div>
          </>
        )}
      </section>
      <section className="d-flex justify-content-center align-items-center">
        <div className="secondary-button mr-16" onClick={handleCreateTable}>
          Confirmar
        </div>
        <div className="primary-button ml-16" onClick={handleCloseModal}>
          Cancelar
        </div>
      </section>
    </div>
  );
};

export default CreateTableModal;
