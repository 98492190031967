//Dependencies
import React from "react";
import QRCode from "qrcode.react";

const QRCodeItem = ({ dynamic_link_branch, hasActiveMenu }) => {
  const handleDownloadFile = () => {
    var a = document.createElement("a");
    const canvas = document.getElementById('QRCode');
    a.href = canvas.toDataURL("image/png");
    a.download = "QRCode.png";
    a.click();
  };

  return (
    <section
      className="qrcode-preview-item middle-item"
    >
      <section className="qrcode-preview-body">
        { hasActiveMenu &&
        <>
          <section className="qrcode-image__container">
            <QRCode
              id="QRCode"
              value={dynamic_link_branch}
              renderAs='canvas'
            />
          </section>
          <button
            className="btn gradient-button"
            onClick={() => handleDownloadFile()}
          >
            <p>DESCARGAR QR</p>
          </button>
        </>
        }
        { !hasActiveMenu &&
          <p>Necesitas tener al menos UN menú activo</p>
        }
      </section>
    </section>
  );
};

export default QRCodeItem;
