//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

//Assets
import ClearIcon from "@material-ui/icons/Clear";
import cartearSelectStyles from "../utils/cartearSelectStyles";

//Utils
import { is_visible_options } from "../utils/selectValues";
import * as actions from "../actions";

const EntityItem = ({
  item,
  updateEntityItem,
  removeEntityItem,
  entityItems,
  readOnly = false,
}) => {
  const [isVisibleSelect, setIsVisibleSelect] = useState([]);

  useEffect(() => {
    const isVisibleTransformed = is_visible_options.filter(
      ({ value }) => value == item.is_visible
    );

    setIsVisibleSelect(isVisibleTransformed);
  }, [item]);

  const handleChange = (value, name, setStateFunction = null) => {
    let val = value;
    if (setStateFunction) {
      setStateFunction(value);
      val = value.value;
    }
    const ITEM_INDEX = entityItems.findIndex((i) => i === item);
    let itemsUpdated = [...entityItems];
    itemsUpdated[ITEM_INDEX][name] = val;
    updateEntityItem(itemsUpdated[ITEM_INDEX]);
  };

  const handleRemoveItem = (item) => {
    removeEntityItem(item);
  };

  return (
    <section className={item._destroy == 1 ? "d-none" : "row"}>
      <section className="form-group w-60 item-name__container">
        <input
          readOnly={readOnly}
          maxLength={50}
          name="name"
          className="form-control item-name"
          defaultValue={item.name}
          onChange={(value) => {
            handleChange(value.target.value, "name");
          }}
        />
      </section>
      <section className="form-group w-20 item-price__container">
        <input
          readOnly={readOnly}
          name="price"
          className="form-control item-price"
          value={item.price}
          onChange={(value) => {
            let price = value.target.value.match(/([0-9]+\.?[0-9]*|\.[0-9]+)/);
            price = price ? price[0] : "";
            handleChange(price, "price");
          }}
        />
      </section>
      <section className="form-group w-20 item-is_visible__container">
        <Select
          isDisabled={readOnly}
          name="is_visible"
          value={isVisibleSelect}
          placeholder="Seleccione"
          options={is_visible_options}
          onChange={(value) => {
            handleChange(value, "is_visible", setIsVisibleSelect);
          }}
          styles={cartearSelectStyles}
        />
      </section>
      {!readOnly && (
        <section
          className="row clear-icon__container"
          onClick={() => handleRemoveItem(item)}
        >
          <ClearIcon />
        </section>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    entityItems: state.entityItems,
  };
};

export default connect(mapStateToProps, actions)(EntityItem);
