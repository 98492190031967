const lateralMenu = {
  lateralMenu: {
    dashboard: "Dashboard",
    restaurants: "Restaurantes",
    paymentMethods: "Métodos de Pago",
    deliveryMethods: "Métodos de envío",
    currencies: "Divisas",
    countries: "Países",
    billing: "Facturación",
    plans: "Planes",
    mobileVersions: "Versiones",
    devices: "Dispositivos",
    suggestions: "Sugerencias",
    emails: "Emails",
    restaurantEmail: "Bienvenida Usuario Restaurant",
    branchEmail: "Bienvenida Usuario Sucursal",
    passwordRecoveryEmail: "Recuperar Contraseña",
    subscriptionExpireSoon: "Suscripción próxima a vencer",
    subscriptionExpired: "Suscripción vencida",
    subscriptionSuccessful: "Suscripción exitosa",
    subscriptionSuccessfullyRenewed: "Suscripción renovada exitosa",
    allergens: "Allergenos",
    tags: "Etiquetas",
    branches: "Sucursales",
    menus: "Menus",
    categories: "Categorías",
    products: "Productos",
    toppings: "Toppings",
    accompaniments: "Acompañamientos",
    variants: "Variantes",
    settings: "Configuración",
    users: "Usuarios",
    managment: "ADMINISTRACIÓN",
    advertisings: "Publicidades",
    qrCode: "Código QR",
    plansAndPayments: "Planes y Pagos",
  },
};

export default lateralMenu;
