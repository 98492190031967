//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

//Components
import ProductForm from "../components/ProductForm";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const ProductEdit = (props) => {
  const { i18n } = useTranslation();
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const { id } = useParams();
  const RESTAURANT_ID = loggedUser.restaurant_id;

  useEffect(() => {
    axios.get(`${get.products(id, RESTAURANT_ID)}?lang=${i18n.language}`).then((res) => {
      console.log(res.data)
      setSelectedEntity(res.data);
    });
  }, []);

  if (!Object.keys(selectedEntity).includes("allergen_products")) {
    return <></>;
  }

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar Producto</h3>
      </div>
      <ProductForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit);
