export const sortAttributes = {
  restaurants: "name",
  allergens: "name",
  tags: "name",
  users: "full_name",
  branches: "name",
  categories: "name",
  menus: "name",
  toppings: "name",
  variants: "name",
  accompaniments: "name",
  advertisings: "name",
  products: "title",
};

export const origin = [
  "products",
  "variants",
  "categories",
  "accompaniments",
  "toppings",
  "advertisings",
];
