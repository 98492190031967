//Dependencies
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import swal from "sweetalert";
import axios from "axios";

import { Collapse } from "react-collapse";

//Components
import CategoryProduct from "./CategoryProduct";
import Spinner from "./Spinner";

//Assets
import "../stylesheets/CategorySection.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

//Utils
import * as actions from "../actions";
import { getAll, get } from "../utils/services";
import MultiSelectModal from "./MultiSelectModal";

const CategorySection = ({
  category,
  index,
  removeMenuCategory,
  addMenuCategoryProduct,
  addMenuCategoryProducts,
  removeMenuCategoryProduct,
  reorderMenuCategoryProduct,
  loggedUser,
  menuCategoryDishes,
  allergens,
  products,
  toppings,
  accompaniments,
  variants,
  updateMenuCategory,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpened, setIsOpened] = useState(true);
  const [categoryDescription, setCategoryDescription] = useState(
    category?.description,
  );

  const CATEGORY_NAME = category?.name;
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.branch_id;

  useEffect(() => {
    // if (menuCategoryDishes?.length > 0) {
    setIsLoading(false);
    // }
  }, [menuCategoryDishes]);

  useEffect(() => {
    updateMenuCategory({
      ...category,
      translations_attributes: [
        {
          id: category?.description_id,
          locale: loggedUser.primary_lang,
          att: "description",
          description: categoryDescription,
        },
      ],
    });
  }, [categoryDescription]);

  const handleRemoveCategory = (category) => {
    swal({
      title: t("modals.removeCategory.title", { categoryName: CATEGORY_NAME }),
      text: null,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        menuCategoryDishes.forEach((dish) => {
          if (dish.category_id === category.id) {
            removeMenuCategoryProduct(dish);
          }
        });
        removeMenuCategory(category);
      }
    });
  };

  const onDragEnd = (result) => {
    const category_Id = category.category_id;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedDishes = reorder(
      menuCategoryDishes.filter(
        ({ category_id }) => category_id === category.category_id,
      ),
      result.source.index,
      result.destination.index,
    );

    reorderMenuCategoryProduct({ category_Id, reorderedDishes });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const resultOrdered = result.map((res, index) => ({ ...res, slot: index }));

    return resultOrdered;
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {CATEGORY_NAME && (
        <Draggable
          draggableId={`id-${category?.id}`}
          index={index}
          isDragDisabled={readOnly || isOpened}
        >
          {(provided) => (
            <section
              index={index}
              key={category?.id}
              className="category-section"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <section className="category-section__header">
                <section
                  className={`collapse-icon-${isOpened ? "close" : "open"}`}
                  onClick={() => setIsOpened(!isOpened)}
                >
                  {isOpened ? <RemoveIcon /> : <AddIcon />}
                </section>
                <section className="category-section__header-title">
                  <h2>{CATEGORY_NAME}</h2>
                </section>
                <section className="category-section__header-buttons">
                  <MultiSelectModal
                    open={openModal}
                    setOpen={setOpenModal}
                    title={t("modals.addProduct.title")}
                    description={t("entities.products.singular")}
                    addMenuCategoryProducts={addMenuCategoryProducts}
                    menuCategoryDishes={menuCategoryDishes}
                    confirm={t("modals.addProduct.confirmButton")}
                    restaurantId={RESTAURANT_ID}
                    branchId={BRANCH_ID}
                    categoryId={category?.category_id}
                    categoryName={CATEGORY_NAME}
                    restaurantLang={loggedUser.primary_lang}
                  />
                  {!readOnly && (
                    <span
                      className="text-green"
                      onClick={() => setOpenModal(true)}
                    >
                      <AddIcon />
                      {t("forms.categoryProduct.addProduct")}
                    </span>
                  )}
                  {!readOnly && (
                    <span
                      className="text-red"
                      onClick={() => handleRemoveCategory(category)}
                    >
                      {t("forms.categoryProduct.removeCategory")}
                    </span>
                  )}
                </section>
              </section>
              <section className="row">
                <section className="w-100">
                  <label>{t("forms.categoryProduct.description")}</label>
                  <textarea
                    readOnly
                    name="translations_attributes[0][description]"
                    className="form-control category-section__description"
                    value={categoryDescription}
                  />
                </section>
              </section>
              <Collapse isOpened={isOpened}>
                <section className="row flex-column">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="productsList">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            menuCategoryDishes.map((dish, indexDish) => {
                              {
                                /* products
                                ?.filter(
                                  ({ id }) =>
                                    id == dish.product_id &&
                                    category.category_id == dish.category_id
                                )
                                .map((prod) => ( */
                              }
                              return category.category_id ==
                                dish.category_id ? (
                                <Draggable
                                  isDragDisabled={readOnly}
                                  draggableId={`id-${dish?.id || indexDish}`}
                                  index={dish.slot}
                                  key={dish.id || indexDish}
                                >
                                  {(provided) => (
                                    <section
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <CategoryProduct
                                        loggedUser={loggedUser}
                                        categoryId={category?.id}
                                        variants={variants}
                                        toppings={toppings}
                                        allergens={allergens}
                                        accompaniments={accompaniments}
                                        dish={dish}
                                        readOnly={readOnly}
                                      />
                                    </section>
                                  )}
                                </Draggable>
                              ) : (
                                <></>
                              );
                              {
                                /* )) */
                              }
                            })
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </section>
              </Collapse>
            </section>
          )}
        </Draggable>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    menuCategories: state.menuCategories,
    menuCategoryDishes: state.menuCategoryDishes,
    categories: state.categories,
    products: state.products,
    toppings: state.toppings,
    allergens: state.allergens,
    accompaniments: state.accompaniments,
    variants: state.variants,
  };
};

export default connect(mapStateToProps, actions)(CategorySection);
