//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import * as actions from "../actions";

const PaymentStatus = (props) => {
  const {
    data: { state },
  } = props;

  const handleClassName = (state) => {
    let buttonTextClass;

    switch (state) {
      case "pending":
        buttonTextClass = "status-pending";
        break;
      case "paid":
        buttonTextClass = "status-paid";
        break;
      case "reverted":
        buttonTextClass = "status-reverted";
        break;
      case "expired":
        buttonTextClass = "status-expired";
        break;
      case "no_paid":
        buttonTextClass = "status-no_paid";
        break;
      default:
        break;
    }

    return buttonTextClass;
  };

  const handleButtonText = (state) => {
    let buttonText;
    switch (state) {
      case "pending":
        buttonText = "PROCESANDO PAGO";
        break;
      case "paid":
        buttonText = "PAGADO";
        break;
      case "reverted":
        buttonText = "RECHAZADO";
        break;
      case "no_paid":
        buttonText = "NO PAGO";
        break;
      case "expired":
        buttonText = "VENCIDO";
        break;
      default:
        break;
    }

    return buttonText;
  };

  return (
    <>
      <span className={handleClassName(state)}>{handleButtonText(state)}</span>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredItems: state.filteredItems,
  };
};

export default connect(mapStateToProps, actions)(PaymentStatus);
