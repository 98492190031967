//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import CurrencyForm from "../components/CurrencyForm";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const CurrencyEdit = (props) => {
  const { setSelectedEntity, selectedEntity } = props;
  const { id } = useParams();
  useEffect(() => {
    axios.get(get.currencies(id)).then((res) => {
      setSelectedEntity(res.data);
    });
  }, []);

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar Divisa</h3>
      </div>
      <CurrencyForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyEdit);
