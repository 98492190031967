const entities = {
  entities: {
    restaurants: {
      title: "Restaurants",
      searchInput: "Restaurant",
      singular: "Restaurant",
      actions: {
        edit: "Edit",
        models3D: "3D Models",
        loginAs: "Login As",
        branches: "Branches",
        delete: "Delete",
        show: "View",
      },
    },
    branches: {
      title: "Branches",
      searchInput: "Branch",
      singular: "Branch",
      edit: "Edit Branch",
      actions: {
        edit: "Edit",
        loginAs: "Login As",
        delete: "Delete",
        translations: "Translations",
      },
      attributes: {
        subtitle: "Subtitle",
        description: "Description",
      },
    },
    products: {
      title: "Products",
      searchInput: "Product",
      singular: "Product",
      attributes: {
        title: "Name",
        description: "Description",
        short_description: "Summary",
      },
      actions: {
        edit: "Edit",
        share: "Share Link",
        translations: "Translations",
        delete: "Delete",
        clone: "Clone",
        show: "Show",
      },
    },
    allergens: {
      title: "Allergens",
      searchInput: "Allergen",
      singular: "Allergen",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    tags: {
      title: "Tags",
      searchInput: "Tag",
      singular: "Tag",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    currencies: {
      title: "Currencies",
      searchInput: "Currency",
      singular: "Currency",
      actions: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    countries: {
      title: "Countries",
      searchInput: "Country",
      singular: "Country",
      actions: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    payment_methods: {
      title: "Payment Methods",
      searchInput: "Payment Method",
      singular: "",
      actions: {
        translations: "Translations",
      },
    },
    delivery_methods: {
      title: "Delivery Methods",
      searchInput: "Delivery Method",
      singular: "",
      actions: {
        translations: "Translations",
      },
    },
    users: {
      title: "User",
      searchInput: "User",
      singular: "User",
      actions: {
        edit: "Edit",
        sendEmail: "Send Email",
        delete: "Delete",
      },
    },
    menus: {
      title: "Menu",
      searchInput: "Menu",
      singular: "Menu",
      attributes: {
        name: "Name",
        description: "Description",
      },
      optionalButton: {
        name: "CLONE MENU",
        action: "clone",
      },
      actions: {
        edit: "Edit",
        assign: "Assign Branches",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    categories: {
      title: "Categories",
      searchInput: "Category",
      singular: "Category",
      attributes: {
        name: "Name",
        description: "Description",
      },
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    toppings: {
      title: "Toppings",
      searchInput: "Topping",
      singular: "Topping",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    advertisings: {
      title: "Advertisings",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      }
    },
    accompaniments: {
      title: "Accompaniments",
      searchInput: "Accompaniment",
      singular: "Accompaniment",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    variants: {
      title: "Variants",
      searchInput: "Variant",
      singular: "Variant",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
        show: "Show",
      },
    },
    models: {
      title: "Dishes",
      searchInput: "dish",
      filterButton: {
        name: "Filter",
        action: "filter",
      },
      actions: {
        edit: "Edit",
      },
    },
    payment_transactions: {
      title: "My Payments",
      searchInput: "",
      singular: "",
    },
    plans: {
      title: "Plans",
      searchInput: "Plan",
      singular: "Plan",
      actions: {
        edit: "Edit",
        promotions: "Promotions",
        delete: "Delete",
      },
    },
    plan_promotions: {
      title: "Promotions",
      searchInput: "Promotion",
      singular: "Promotion",
      actions: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    mobile_versions: {
      title: "Versions",
      searchInput: "Version",
      singular: "Version",
      actions: {
        edit: "Edit",
        translations: "Translations",
        delete: "Delete",
      },
    },
    suggestions: {
      title: "Suggestions",
      searchInput: "UID or Email",
      singular: "",
      actions: {
        show: "View Suggestion",
      },
    },
    notifications: {
      title: "Notifications",
      searchInput: "",
      singular: "",
      actions: {
        show: "View Details",
      },
    },
    billing: {
      title: "Branches",
      searchInput: "Branch",
      singular: "",
      actions: {
        payed: "Paid",
        unpayed: "Unpaid",
      },
    },
    schedules: {
      title: "Schedules",
    },
  },
};

export default entities;
