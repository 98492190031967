//Dependencies
import React from "react";
import { connect } from "react-redux";

//Components
import PlanForm from "../components/PlanForm";

//Assets
import AddIcon from "@material-ui/icons/Add";

//Utils
import * as actions from "../actions";

const PlanNew = ({ addPlanCountry }) => {
  const handdleAddItems = () => {
    addPlanCountry([{ country: null, currency_id: null, price: null }]);
  };

  return (
    <section className="container">
      <div className="form-title d-flex justify-content-space-between align-items-center">
        <h3>Nuevo Plan</h3>
        <div
          className="cartear-outline-button"
          onClick={() => handdleAddItems()}
        >
          <AddIcon />
          AGREGAR PAIS/PRECIO
        </div>
      </div>
      <PlanForm action={"create"} />
    </section>
  );
};

export default connect(null, actions)(PlanNew);
