//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import CategoryForm from "../components/CategoryForm";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const CategoryEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(
        get.categories(
          id,
          loggedUser.restaurant_id,
          loggedUser.branch_id ? loggedUser.branch_id : null
        )
      )
      .then((res) => {
        setSelectedEntity(res.data);
      });
  }, []);

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar categoría</h3>
      </div>
      <CategoryForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit);
