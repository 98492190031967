//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import PlanForm from "../components/PlanForm";

//Assets
import AddIcon from "@material-ui/icons/Add";

//Utils
import { get } from "../utils/services";
import * as actions from "../actions";

const PlanEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser, addPlanCountry } =
    props;
  const { id } = useParams();

  const handdleAddItems = () => {
    addPlanCountry([{ country: null, currency_id: null, price: null }]);
  };

  useEffect(() => {
    axios.get(get.plans(id)).then((res) => {
      setSelectedEntity(res.data);
    });
  }, []);

  return (
    <section className="container">
      <div className="form-title d-flex justify-content-space-between align-items-center">
        <h3>Editar Plan</h3>
        <div
          className="cartear-outline-button"
          onClick={() => handdleAddItems()}
        >
          <AddIcon />
          AGREGAR PAIS/PRECIO
        </div>
      </div>
      <PlanForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(PlanEdit);
