//Dependencies
import React from "react";

//Assets
import "../stylesheets/footer.css";

const Footer = () => {
  const currentYear = (new Date()).getFullYear();
  return (
    <footer className="page-footer">
      <div className="copyright">
        <span>© {currentYear} Cartear</span>
      </div>
    </footer>
  );
};

export default Footer;
