import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import MultipleSelect from "./MultiSelect";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { set_entity_limit_options } from "../utils/selectValues";
import cartearSelectStyles from "../utils/cartearSelectStyles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal({
  open,
  setOpen,
  title,
  confirm,
  categories,
  updateMenuCategoryProducts,
  menuCategoryDishes,
}) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [discountPercent, setDiscountPercent] = useState();
  const [modalStyle] = useState(getModalStyle);
  const { t } = useTranslation();
  const DISCOUNT_RATIO = set_entity_limit_options([], -100, 100, 1, "%");

  const updateSelectedProducts = () => {
    const products = [];
    selectedProducts.forEach((p) => {
      const newPrice = (p.price * ((100 + discountPercent) / 100)).toFixed(2);
      const newFinalPrice = (
        newPrice *
        ((100 - p.discount_ratio) / 100)
      ).toFixed(2);
      products.push({
        ...p,
        price: newPrice,
        discount_value: newFinalPrice,
      });
    });
    updateMenuCategoryProducts({ products });
    setOpen(false);
  };

  useEffect(() => {
    setOptions(
      menuCategoryDishes
        .filter((d) => !!d.category_id)
        .map((d) => ({
          ...d,
          category_name: categories.find((c) => c.category_id === d.category_id)
            ?.name,
        }))
        .sort((a, b) => {
          return a.category_id - b.category_id;
        }),
    );
  }, [menuCategoryDishes]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{title}</h2>
        <MultipleSelect
          options={options}
          setSelectedProducts={setSelectedProducts}
          groupBy={(option) => option.category_name}
        />
        <section className="form-group">
          <label htmlFor="discount_ratio">
            {t("modals.updatePrices.updatePercentage")}
          </label>
          <Select
            name="discount_ratio"
            placeholder={t("commons.placeholders.select")}
            options={DISCOUNT_RATIO.map((discount) => ({
              label: discount.label,
              value: discount.value,
            }))}
            onChange={(value) => {
              setDiscountPercent(value.value);
            }}
            styles={cartearSelectStyles}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (Number.isInteger(discountPercent)) {
                updateSelectedProducts();
              }
            }}
          >
            {confirm}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("modals.common.close")}
          </Button>
        </section>
      </div>
    </Modal>
  );
}
