//Dependencies
import React from "react";

//Assets
import "../stylesheets/main.css";

const Main = ({ children }) => {
  return <main className="page-main">{children}</main>;
};

export default Main;
