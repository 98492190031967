//Dependencies
import React from "react";
import { connect } from "react-redux";

//Components
import ModelForm from "../components/ModelForm";

const ModelEdit = ({ selectedEntity }) => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Modelo 3D</h3>
      </div>
      <ModelForm action={"edit"} selectedEntity={selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
  };
};

export default connect(mapStateToProps, null)(ModelEdit);
