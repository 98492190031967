//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { get } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "models";

const Models = (props) => {
  const {
    setHasMore,
    setNextPage,
    setModels,
    setIsLoading,
    clearModels,
    loggedUser,
  } = props;

  const { id } = useParams();
  const MODELS_URL = get.models(id ? id : loggedUser.restaurant_id);

  useEffect(() => {
    clearModels();
    setIsLoading(true);
    axios
      .get(MODELS_URL)
      .then((res) => {
        const { products } = res.data;
        setModels(products);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(Models);
