//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

//Components
import LateralMenu from "./LateralMenu";
import Main from "./Main";
import Header from "./Header";
import Footer from "./Footer";
import LoginLoader from "./LoginLoader";

//Assets
import "../stylesheets/layout.css";

//Utils
import * as actions from "../actions";
import { get } from "../utils/services";

const Layout = ({
  children,
  loggedUser,
  setLoggedUser,
  entity,
  isLoadingOverlayActivated,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const LOCATION = useLocation().pathname;
  let LOGIN_AS_ROLE = window.localStorage.getItem("userRole");
  let USER_ROLE = loggedUser?.role;
  let LOGIN_AS_ID;

  useEffect(() => {
    axios.get(get.loggedUser).then((res) => {
      setLoggedUser({
        ...res.data,
        role: LOGIN_AS_ROLE || res.data.role,
        branch_id: res.data.branch_id,
        restaurant_id: res.data.restaurant_id,
      });
      if (!LOCATION.includes("login-as")) {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (loggedUser) {
      if (window.localStorage.getItem("loginAs") && !loggedUser.renderized) {
        if (LOCATION.includes("/login-as")) {
          USER_ROLE = LOCATION.includes("branches")
            ? "branch_user"
            : LOCATION.includes("restaurants")
            ? "restaurant_admin"
            : loggedUser?.role;
          localStorage.setItem("userRole", USER_ROLE);
        } else {
          USER_ROLE = LOGIN_AS_ROLE;
        }

        LOGIN_AS_ID = LOCATION.replace("/login-as", "");

        if (USER_ROLE?.includes("branch")) {
          LOGIN_AS_ID = LOGIN_AS_ID.replace("branches/", "").replace(
            "/restaurants/",
            ""
          );
          const RESTAURANT_ID =
            localStorage.getItem("restaurantId") || LOGIN_AS_ID.split("/")[0];
          const BRANCH_ID =
            localStorage.getItem("branchId") || LOGIN_AS_ID.split("/")[1];

          localStorage.setItem("restaurantId", RESTAURANT_ID);
          localStorage.setItem("branchId", BRANCH_ID);

          const URL = get.restaurants(RESTAURANT_ID);

          axios
            .get(URL)
            .then(({ data }) => {
              setLoggedUser({
                ...loggedUser,
                restaurant_id: RESTAURANT_ID,
                restaurant_name: data.name,
                branch_id: BRANCH_ID,
                branch_name: data.branches.find(({ id }) => id == BRANCH_ID)
                  .name,
                role: USER_ROLE,
                loggedRole: USER_ROLE,
                dynamic_link_delivery: data.branches.find(
                  ({ id }) => id == BRANCH_ID
                ).dynamic_link_delivery,
                dynamic_link_branch: data.branches.find(
                  ({ id }) => id == BRANCH_ID
                ).dynamic_link_branch,
                primary_lang: data.restaurant_languages.filter(
                  ({ primary }) => primary
                )[0].code,
                renderized: true,
              });
            })
            .then(() => setIsLoading(false));
        } else if (
          USER_ROLE?.includes("super") ||
          USER_ROLE?.includes("restaurant")
        ) {
          LOGIN_AS_ID =
            localStorage.getItem("restaurantId") ||
            LOGIN_AS_ID.replace("/restaurants/", "");
          localStorage.setItem("restaurantId", LOGIN_AS_ID);

          axios
            .get(get.restaurants(LOGIN_AS_ID))
            .then(({ data }) => {
              setLoggedUser({
                ...loggedUser,
                restaurant_id: LOGIN_AS_ID,
                restaurant_name: data.name,
                role: USER_ROLE,
                loggedRole: loggedUser?.loggedRole
                  ? loggedUser?.loggedRole
                  : loggedUser?.role,
                primary_lang: data.restaurant_languages.filter(
                  ({ primary }) => primary
                )[0].code,
                renderized: true,
              });
            })
            .then(() => setIsLoading(false));
        }
      }
    }
  }, [loggedUser]);

  return isLoading ? (
    <LoginLoader />
  ) : (
    <>
      <div className="page-wraper">
        <LateralMenu />
        <div className="page-content">
          <Header />
          <Main>{children}</Main>
          <Footer />
        </div>
      </div>
      <div
        className={`dark-background  ${
          isLoadingOverlayActivated ? "d-block" : "d-none"
        }`}
      >
        <LoadingOverlay active={true} spinner={true} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    isLoadingOverlayActivated: state.showLoadingOverlay,
    currentSection: state.currentSection
  };
};

export default connect(mapStateToProps, actions)(Layout);
