//Dependencies
import React from "react";

//Assets
import "../stylesheets/card.css";

const Card = ({ title, total, visible, not_visible }) => {
  return (
    <div className="card">
      <div className="card-header">
        <span>{title}</span>
      </div>
      <div className="card-body">
        {total > 0 ? <span>{total}</span> : <span>0</span>}
      </div>
      <div className="card-footer">
        <div className="details">
          {visible >= 0 && <span>Activos: {visible}</span>}
          {not_visible >= 0 && <span>Inactivos: {not_visible}</span>}
        </div>
      </div>
    </div>
  );
};

export default Card;
