//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "mobile_versions";

const MobileVersions = (props) => {
  const {
    setHasMore,
    setNextPage,
    setMobileVersions,
    setIsLoading,
    clearMobileVersions,
  } = props;

  const MOBILE_VERSIONS_URL = getAll.mobile_versions;

  useEffect(() => {
    clearMobileVersions();
    setIsLoading(true);
    axios
      .get(MOBILE_VERSIONS_URL)
      .then((res) => {
        const { mobile_versions } = res.data;

        setMobileVersions(mobile_versions);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

export default connect(null, actions)(MobileVersions);
