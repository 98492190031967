//Dependencies
import React from "react";

//Components
import VariantForm from "../components/VariantForm";

const VariantNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva variante</h3>
      </div>
      <VariantForm action={"create"} />
    </section>
  );
};

export default VariantNew;
