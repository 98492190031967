import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import MultipleSelect from "./MultiSelect";
import Button from "@material-ui/core/Button";
import { getAll, get } from "../utils/services";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal({
  open,
  setOpen,
  title,
  description,
  confirm,
  restaurantId,
  branchId,
  categoryId,
  categoryName,
  menuCategoryDishes,
  addMenuCategoryProducts,
  restaurantLang,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const PRODUCTS_URL = getAll.products(restaurantId, branchId);

  useEffect(() => {
    console.log(restaurantLang)
    if (open) {
      let aux = [];
      axios.get(`${PRODUCTS_URL}?lang=${restaurantLang}`).then((res) => {
        const products = res.data.products.filter((p) => p.title !== null);
        const productsAlreadyInCategory = menuCategoryDishes
          .filter((d) => d.category_id === categoryId)
          .flatMap((d) => d.product_id);
        products.forEach((p) => {
          if (!productsAlreadyInCategory.includes(p.id)) {
            aux.push(p);
          }
        });
        const sortedProducts = aux.sort((a, b) => a.id - b.id);
        setOptions(sortedProducts);
      });
    }
  }, [open]);

  const [modalStyle] = React.useState(getModalStyle);

  const addSelectedProducts = async () => {
    const products = [];
    const response = await axios.get(`${PRODUCTS_URL}/selected_products`, {
      params: { products_ids: selectedProducts.map((sp) => sp.id) },
    });
    if (response.status === 200) {
      let lastIndex = menuCategoryDishes.filter(
        (d) => d.category_id == categoryId,
      );
      lastIndex =
        lastIndex?.length > 0
          ? lastIndex.sort((a, b) => b.slot - a.slot)[0].slot
          : 0;
      response.data.forEach((product) => {
        lastIndex += 1;
        const newProduct = {
          ...product,
          id: null,
          slot: lastIndex,
          product_id: product.id,
          allergens: product.allergen_products?.map(({ allergen }) => ({
            id: null,
            allergen: allergen,
          })),
          toppings: product.topping_products?.map(({ topping }) => ({
            id: null,
            topping: topping,
          })),
          accompaniments: product.accompaniment_products?.map(
            ({ accompaniment }) => ({
              id: null,
              accompaniment: accompaniment,
            }),
          ),
          variants: product.variant_products?.map(({ variant }) => ({
            id: null,
            variant: variant,
          })),
        };
        products.push(newProduct);
      });

      const ids = products.map((p) => p.product_id);
      if (
        menuCategoryDishes.some(
          ({ product_id, category_id }) =>
            ids.includes(product_id) && category_id == categoryId,
        )
      ) {
        let content = document.createElement("span");
        content.innerHTML = t("modals.addProduct.alreadyExistsContent", {
          productName: PRODUCT_NAME,
        });
        swal({
          title: t("modals.addProduct.alreadyExistsTitle"),
          content: content,
          icon: "error",
          dangerMode: true,
        });
        return;
      } else {
        addMenuCategoryProducts({
          branchId2: branchId,
          restaurantId2: restaurantId,
          categoryId2: categoryId,
          selectedProducts: products,
        });
      }
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{`${title} ${categoryName}`}</h2>
        <h4 id="simple-modal-description">{description}</h4>
        <MultipleSelect
          options={options}
          setSelectedProducts={setSelectedProducts}
        />
        <Button variant="contained" onClick={() => addSelectedProducts()}>
          {confirm}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("modals.common.close")}
        </Button>
      </div>
    </Modal>
  );
}
