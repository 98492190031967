import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ESTranslation from "./es/es.js";
import ENTranslation from "./en/en.js";

let language = navigator.language.split("-")[0];
const supportedLanguages = ["es", "en"];
language = supportedLanguages.includes(language) ? language : "es";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...ESTranslation,
      ...ENTranslation,
    },
    fallbackLng: window.localStorage.getItem('currentLanguage') || language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
