//Dependencies
import React from "react";

//Components
import RestaurantForm from "../components/RestaurantForm";

const RestaurantNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Restaurante</h3>
      </div>
      <RestaurantForm action={"create"} />
    </section>
  );
};

export default RestaurantNew;
