import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Utils
import { update } from "../utils/services";
import {
  modalTableSuccessful,
  modalTableError,
  modalEntityAlreadyExist,
} from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles.js";

const ChangeOrderTableModal = ({
  tables,
  restaurant_id,
  branch_id,
  table_id,
  order_id,
}) => {
  const [tableSelect, setTableSelect] = useState(null);
  const history = useHistory();

  const handleEditTableName = () => {
    const order = {
      table_id: tableSelect.value,
    };

    axios
      .put(update.orders(restaurant_id, branch_id, table_id, order_id), order)
      .then(() => handleCloseModal())
      .then(() => history.goBack());
  };

  const handleCloseModal = () => {
    const closeButton = document.querySelector(".swal2-close");
    closeButton.click();
  };

  return (
    <div>
      <section className="form-group d-flex flex-column pb-128 px-16 border-box">
        <label className="nowrap " htmlFor="new-table-name">
          Nueva mesa:
        </label>
        <Select
          name="order-status"
          placeholder="Seleccione"
          options={tables
            .filter((table) => table.id != table_id)
            .map((table) => ({
              value: table.id,
              label: table.name,
            }))}
          value={tableSelect}
          onChange={(value) => setTableSelect(value)}
          styles={cartearSelectStyles}
          maxMenuHeight={120}
        />
      </section>
      <section className="d-flex justify-content-center align-items-center">
        <div className="secondary-button mr-16" onClick={handleEditTableName}>
          Confirmar
        </div>
        <div className="primary-button ml-16" onClick={handleCloseModal}>
          Cancelar
        </div>
      </section>
    </div>
  );
};

export default ChangeOrderTableModal;
