//Dependencies
import axios from "axios";

//utils
import { CheckHasMore } from "./CheckHasMore";

export const DatatableFetchMoreData = (
  inView,
  entity,
  actions,
  setFetchingMoreData
) => {
  if (inView && hasMore) {
    setFetchingMoreData(true);
    switch (entity) {
      case "restaurants":
        axios
          .get(nextPage)
          .then((res) => {
            const filteredItems = res.data[entity];
            const {
              meta: {
                links: { next, last },
              },
            } = res.data;
            actions.setRestaurants(filteredItems);
            CheckHasMore(next, last, setHasMore, setNextPage);
          })
          .then(() => setFetchingMoreData(false));
        break;
      case "branches":
        axios
          .get(nextPage)
          .then((res) => {
            const filteredItems = res.data[entity];
            const {
              meta: {
                links: { next, last },
              },
            } = res.data;

            actions.setBranches(filteredItems);
            CheckHasMore(next, last, setHasMore, setNextPage);
          })
          .then(() => setFetchingMoreData(false));
        break;
      case "products":
        axios
          .get(nextPage)
          .then((res) => {
            const filteredItems = res.data[entity];
            const {
              meta: {
                links: { next, last },
              },
            } = res.data;

            actions.setProducts(filteredItems);
            CheckHasMore(next, last, setHasMore, setNextPage);
          })
          .then(() => setFetchingMoreData(false));
        break;

      default:
        break;
    }
  }
};
