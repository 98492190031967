//Dependencies
import React from "react";

//Components
import ToppingForm from "../components/ToppingForm";

const ToppingNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo topping</h3>
      </div>
      <ToppingForm action={"create"} />
    </section>
  );
};

export default ToppingNew;
