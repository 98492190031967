//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Assets
import "../stylesheets/CurrencyForm.css";
import cartearSelectStyles from "../utils/cartearSelectStyles";

//Utils
import { getAll, create, update } from "../utils/services";
import { COUNTRIES_ARRAY } from "../utils/Countries";
import * as actions from "../actions";
import { modalCountrySuccessful, modalCountryError } from "../utils/modals";

const ENTITY = "countries";

const CountryForm = (props) => {
  const {
    id,
    name,
    payment_platforms,
    action,
    updateCountry,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [countrySelect, setCountrySelect] = useState([]);
  const [paymentPlatforms, setPaymentPlatforms] = useState([]);
  const [paymentPlatformsSelect, setPaymentPlatformsSelect] = useState([]);

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  useEffect(() => {
    register("country");
    setCountries(COUNTRIES_ARRAY);

    axios
      .get(getAll.payment_platforms)
      .then((res) => setPaymentPlatforms(res.data.payment_platforms));

    if (action === "edit") {
      const CTRY_XFORMED = { value: id, label: name };
      setCountrySelect(CTRY_XFORMED);
    }
  }, []);

  useEffect(() => {
    if (payment_platforms?.length > 0) {
      const PYMT_PLTF_XFORMED = payment_platforms.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      setPaymentPlatformsSelect(PYMT_PLTF_XFORMED);
    }
  }, [payment_platforms]);

  /*country_payment_platforms_attributes: payment_platforms.map((pp) => ({
        id: action === "create" ? undefined : pp.id,
        payment_platform_id: pp.id,
        _destroy: paymentPlatformsSelect.some((x) => x.value != pp.id)
          ? "1"
          : undefined,
      })),
      */

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);

    if (action === "create") {
      const createPayload = paymentPlatformsSelect.map((pp) => ({
        payment_platform_id: pp.value,
      }));
      const country = {
        name: countrySelect.label,
        country_payment_platforms_attributes: createPayload,
      };
      axios
        .post(create[ENTITY], { country })
        .then(() =>
          modalCountrySuccessful("creado", countrySelect.label, t, () =>
            history.goBack()
          )
        )
        .then(() => e.target.reset())
        .catch(() => modalCountryError("crear", countrySelect.label), t)
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      const edit = paymentPlatformsSelect.map((pp) => {
        if (pp._destroy) {
          return {
            id: payment_platforms.find((rpp) => rpp.id == pp.value)
              ?.country_payment_platform_id,
            _destroy: true,
          };
        } else if (!payment_platforms.map((rpp) => rpp.id).includes(pp.value)) {
          return {
            payment_platform_id: pp.value,
          };
        }
      });
      const country = {
        name: countrySelect.label,
        country_payment_platforms_attributes: edit,
      };
      axios
        .put(update[ENTITY](null, id), { country })
        .then(() => {
          data.id = id;
          updateCountry(data);
        })
        .then(() =>
          modalCountrySuccessful("actualizado", countrySelect.label, t, () =>
            history.goBack()
          )
        )
        .catch(() => modalCountryError("actualizar", countrySelect.label, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (action.action === "add-platform") {
      const val = paymentPlatformsSelect.find((pp) => pp.value === value.value);
      let newValues;
      if (val) {
        newValues = paymentPlatformsSelect.map((pp) => {
          if (pp.value === val.value) {
            pp._destroy = false;
          }
          return pp;
        });
      } else {
        newValues = [...paymentPlatformsSelect, value];
      }
      setValue(inputRef, newValues);
      setStateFunction(newValues);
    } else if (action.action === "remove-value") {
      const newValues = paymentPlatformsSelect.map((pp) => {
        if (pp.value === value.value) {
          pp["_destroy"] = true;
        }
        return pp;
      });
      setStateFunction(newValues);
    } else {
      setValue(inputRef, value);
      setStateFunction(value);
    }
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="row">
          <section className="form-group pr-16">
            <label htmlFor="country">País</label>
            <Select
              name="country"
              value={countrySelect}
              placeholder="Seleccione"
              options={countries.map((country) => ({
                label: country.name,
                value: country.alpha2Code.toLowerCase(),
              }))}
              onChange={(value, action) => {
                handleChange(value, action, setCountrySelect);
              }}
              styles={cartearSelectStyles}
              noOptionsMessage={() => "Sin resultados"}
            />
          </section>
          <section className="form-group">
            <label htmlFor="payment_platform">Plataformas de Pago</label>
            <Select
              name="payment_platform"
              value={paymentPlatformsSelect.filter((pp) => !pp?._destroy)}
              placeholder="Seleccione"
              isMulti
              options={paymentPlatforms.map((pp) => ({
                label: pp.name,
                value: pp.id,
                another: pp.country_payment_platform_id,
              }))}
              onChange={(value, action) => {
                const object = action.removedValue || action.option;
                if (!action.removedValue) {
                  action.action = "add-platform";
                }
                handleChange(object, action, setPaymentPlatformsSelect);
              }}
              styles={cartearSelectStyles}
              noOptionsMessage={() => "Sin resultados"}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(CountryForm);
