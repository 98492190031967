// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "stylesheets/application.css";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "../reducers";
import Routes from "../routes/Index";
import axios from "axios";
import "../translations/i18n";

console.log("starting cartear");
require.context("../images", true);
global.toastr = require("toastr");

const restaurantLoginAs = window.localStorage.getItem("restaurant");
if (restaurantLoginAs) {
  axios.defaults.headers.common["user-language"] =
    JSON.parse(restaurantLoginAs).primary_code || "es";
}

Rails.start();
ActiveStorage.start();

const initialState = {
  user: {},
  isLoading: false,
  currentSection: "",
  restaurants: [],
  branches: [],
  products: [],
  allergens: [],
  tags: [],
  payment_methods: [],
  delivery_methods: [],
  users: [],
  currencies: [],
  mobileVersions: [],
  categories: [],
  toppings: [],
  accompaniments: [],
  variants: [],
  hasMore: false,
  nextPage: "",
  filteredItems: [],
  selectedEntity: {},
  schedules: [],
  menuCategories: [],
  menuCategoryDishes: [],
  entityItems: [],
  suggestions: [],
  notifications: [],
  planCountries: [],
  plan_promotions: [],
  models: [],
  showLoadingOverlay: false,
  payment_transactions: [],
  paymentId: null,
  tables: [],
  activeMenu: {},
};

const store = createStore(reducer, initialState);

document.addEventListener("DOMContentLoaded", () => {
  const div = document.getElementById("root");
  if (div) {
    render(
      <Provider store={store}>
        <>{Routes}</>
      </Provider>,
      document.body.appendChild(div)
    );
  }
});
