//Dependencies
import React from "react";

//Components
import UserForm from "../components/UserForm";

const UserNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo usuario</h3>
      </div>
      <UserForm action={"create"} />
    </section>
  );
};

export default UserNew;
