//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import UserForm from "../components/UserForm";

//Assets
import { setSelectedEntity } from "../actions";

//Utils
import { get } from "../utils/services";

const UserEdit = (props) => {
  const { setSelectedEntity, selectedEntity, loggedUser } = props;
  const { id } = useParams();
  let RESTAURANT_ID = window.localStorage.getItem('restaurantId');
  let BRANCH_ID = window.localStorage.getItem('branchId');

  RESTAURANT_ID = RESTAURANT_ID ? RESTAURANT_ID : loggedUser.restaurant_id;
  BRANCH_ID = BRANCH_ID ? BRANCH_ID : loggedUser.branch_id;

  useEffect(() => {
    axios.get(get.users(id, RESTAURANT_ID, BRANCH_ID)).then((res) => {
      setSelectedEntity(res.data);
    });
  }, []);

  return (
    <section className="container">
      <div className="form-title">
        <h3>Editar usuario</h3>
      </div>
      <UserForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
