//Dependencies
import React from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

//Components
import CategorySection from "../components/CategorySection";

//Utils
import * as actions from "../actions";

const CategoriesDnDContainer = ({
  menuCategories,
  addMenuCategory,
  clearMenuCategories,
  loggedUser,
  readOnly = false,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const categories = reorder(
      menuCategories,
      result.source.index,
      result.destination.index,
    );

    clearMenuCategories();
    addMenuCategory(categories);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const resultOrdered = result.map((res, index) => ({ ...res, slot: index }));

    return resultOrdered;
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categoriesList">
          {(provided) => (
            <section
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="w-100"
            >
              {menuCategories.map((category, index) => (
                <CategorySection
                  loggedUser={loggedUser}
                  key={category?.id}
                  category={category}
                  index={index}
                  readOnly={readOnly}
                />
              ))}
              {provided.placeholder}
            </section>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuCategories: state.menuCategories,
  };
};

export default connect(mapStateToProps, actions)(CategoriesDnDContainer);
