//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { CircleFlag } from "react-circle-flags";

//Assets
import "../stylesheets/header.css";
import userDefaultPhoto from "../images/user-default-photo.jpg";
import { setCurrentSection, setLoggedUser } from "../actions/";
import { useHistory } from "react-router-dom";
import { get } from "../utils/services";

//Utils
import { roles } from "../utils/roles.json";

const LOCATION = window.location;

const Header = ({ currentSection, loggedUser }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(loggedUser.avatar || userDefaultPhoto);
  const history = useHistory();
  const LOGGED_USER_NAME = loggedUser
    ? loggedUser.role === "super_admin"
      ? roles[loggedUser.role]
      : `${roles[loggedUser.loggedRole || loggedUser.role]} ${
          loggedUser.restaurant_name ? `> ${loggedUser.restaurant_name}` : ""
        } ${
          loggedUser.branch_name && loggedUser.role != "restaurant_admin"
            ? `> ${loggedUser.branch_name} `
            : ""
        }`
    : "";

  const languages = [
    { code: "es", flag: "es", language: t("commons.languages.es") },
    { code: "en", flag: "us", language: t("commons.languages.en") },
  ];

  const handleDropdown = () => {
    document.querySelector(".dropdown-content").classList.toggle("show");
  };

  const handleLogout = () => {
    removeLoginAs();
    const params = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    };

    fetch(LOCATION.origin + "/users/sign_out", params).then((res) => {
      LOCATION.href = LOCATION.origin + "/users/sign_in";
    });
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropdown")) {
      const dropdowns = document.getElementsByClassName("dropdown-content");
      for (let i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  const removeLoginAs = () => {
    window.localStorage.removeItem("loginAs");
    window.localStorage.removeItem("userRole");
    window.localStorage.removeItem("restaurantId");
    window.localStorage.removeItem("branchId");
    window.localStorage.removeItem("restaurant");
    axios.get(get.loggedUser).then((res) => {
      setLoggedUser(res.data);
    });
  };

  const closeLoginAs = () => {
    removeLoginAs();
    history.push("/");
    window.location.reload();
  };

  useEffect(() => {
    setAvatar(loggedUser.avatar || userDefaultPhoto);
  }, [loggedUser.avatar]);

  window.onpopstate = (event) => {
    dispatch(setCurrentSection(null));
    const oldElementActived = document.querySelector(".active");
    if (oldElementActived) {
      oldElementActived.classList.remove("active");
    }
    const url = window.location.pathname.split('/');
    const itemToActive = url[url.length - 1]
    let target; 
    if(itemToActive) {
      target = document.getElementById(`menu-item-${itemToActive}`)
    } else {
      target = document.getElementById("menu-item-dashboard")
    }
    target.classList.add("active");
  }
  return (
    <header className="page-header">
      <div className="page-title">
        <h1>{currentSection ? currentSection : "Dashboard"}</h1>
      </div>
      <div className="user-info dropdown" onClick={handleDropdown}>
        <span className="dropdown">{LOGGED_USER_NAME}</span>
        {languages.map(({ code, flag, language }) => (
          <div key={code} onClick={() => i18n.changeLanguage(code)}>
            <CircleFlag countryCode={flag} height={30} />
          </div>
        ))}
        <div className="user-photo dropdown">
          <img src={avatar} className="dropdown" alt="User Photo" />
        </div>
        <div className="dropdown-content">
          {loggedUser.role !== "super_admin" && (
            <Link to={`/users/${loggedUser.id}/profile`}>Mi perfil</Link>
          )}
          {window.localStorage.getItem("loginAs") && (
            <button type="button" onClick={closeLoginAs}>
              Cerrar Login As
            </button>
          )}
          <button type="button" onClick={handleLogout}>
            Cerrar sesión
          </button>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSection: state.currentSection,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setLoggedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
