//Dependencies
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import swal from "sweetalert";

//Components
import CategoriesDnDContainer from "../components/CategoriesDnDContainer";

//Assets
import AddIcon from "@material-ui/icons/Add";
import MultiSelectPriceModal from "./MultiSelectPriceModal";
import ImportPrices from "./ImportPrices";

//Utils
import * as actions from "../actions";

const MenuCategories = (props) => {
  const { t } = useTranslation();
  const {
    menu,
    categories,
    menuCategories,
    addMenuCategory,
    updateMenuCategoryProducts,
    loggedUser,
    menuCategoryDishes,
    filteredItems,
    setFilteredItems,
    readOnly = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [openImportPrices, setOpenImportPrices] = useState(false);
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.branch_id;

  const handleOpenAddCategoryModal = async () => {
    const filteredCategories = await categories.map((cat) => ({
      id: cat.id,
      name: cat.name,
    }));

    let options = {};
    await filteredCategories.map((item) => {
      options[item.id] = item.name;
    });

    const CATEGORY_SELECTED = await Swal.fire({
      title: t("modals.addCategory.title"),
      html: `<h4>${t("entities.categories.singular")}</h4>`,
      input: "select",
      inputOptions: options,
      inputPlaceholder: t("modals.addCategory.inputPlaceholder"),
      confirmButtonText: t("modals.addCategory.confirmButton"),
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (!!value) {
            resolve();
          } else {
            resolve(t("modals.addCategory.emptyResult"));
          }
        });
      },
    });

    if (!CATEGORY_SELECTED.isConfirmed) return;

    const { name: CATEGORY_NAME } = filteredCategories.filter(
      ({ id }) => id === parseInt(CATEGORY_SELECTED?.value),
    )[0];

    const newMenuCategory = categories.find(
      ({ id }) => id === parseInt(CATEGORY_SELECTED?.value),
    );

    if (
      menuCategories.filter(
        ({ category_id }) => category_id === newMenuCategory.id,
      ).length > 0
    ) {
      let content = document.createElement("span");
      content.innerHTML = t("modals.addCategory.contentHtml", {
        categoryName: CATEGORY_NAME,
      });
      swal({
        title: t("modals.addCategory.alreadyExistsTitle"),
        content: content,
        icon: "error",
        dangerMode: true,
      });
      return;
    }

    addMenuCategory([
      {
        category_id: newMenuCategory.id,
        slot:
          menuCategories[menuCategories.length - 1]?.slot ==
          menuCategories.length
            ? menuCategories.length + 1
            : menuCategories.length,
        name: CATEGORY_NAME,
        description: newMenuCategory.description,
      },
    ]);

    if (CATEGORY_SELECTED?.value) {
      let content = document.createElement("span");
      content.innerHTML = t("modals.addCategory.successfullyAddedContent", {
        categoryName: CATEGORY_NAME,
      });
      swal({
        title: t("modals.addCategory.successfullyAddedTitle"),
        content: content,
        icon: "success",
        dangerMode: true,
      });
    }
  };

  return (
    <>
      <section className="tab-content-container">
        <section className="row d-flex flex-column">
          <section className="row justify-content-end">
            <section className="create-section">
              <div className="create-button" onClick={() => setOpen(true)}>
                <AddIcon />
                {t("forms.menuCategories.updatePrices")}
              </div>
            </section>
            <section className="create-section">
              <div
                className="create-button"
                onClick={() => setOpenImportPrices(true)}
              >
                <AddIcon />
                {t("forms.menuCategories.importPrices")}
              </div>
            </section>
            {openImportPrices && (
              <ImportPrices
                menu={menu}
                open={openImportPrices}
                setOpen={setOpenImportPrices}
                menuCategoryDishes={menuCategoryDishes}
                updateMenuCategoryProducts={updateMenuCategoryProducts}
              />
            )}
            {open && (
              <MultiSelectPriceModal
                open={open}
                setOpen={setOpen}
                title={t("modals.updatePrices.title")}
                description={t("entities.products.singular")}
                updateMenuCategoryProducts={updateMenuCategoryProducts}
                menuCategoryDishes={menuCategoryDishes}
                confirm={t("modals.addProduct.confirmButton")}
                categories={menuCategories}
              />
            )}
            {!readOnly && (
              <section className="create-section">
                <div
                  className="create-button"
                  onClick={() => handleOpenAddCategoryModal()}
                >
                  <AddIcon />
                  {t("forms.menuCategories.addCategory")}
                </div>
              </section>
            )}
          </section>
          <section className="row mt-16">
            <CategoriesDnDContainer
              readOnly={readOnly}
              loggedUser={loggedUser}
            />
          </section>
        </section>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuCategories: state.menuCategories,
  };
};

export default connect(mapStateToProps, actions)(MenuCategories);
