const forms = {
  forms: {
    inputRequired: "This field is required",
    withoutResult: "No results",
    branch: {
      name: "Name",
      branch: "Branch",
      urlName: "URL Name",
      isVisible: "Visible?",
      description: "Description",
      descriptionPlaceholder: "Branch description",
      subtitle: "Subtitle",
      subtitlePlaceholder: "Branch subtitle",
      address: "Address",
      aditionalInfo: "Additional Information",
      price: "Cover Price",
      website: "Website",
      whatsapp: "Whatsapp",
      facebook: "Facebook",
      instagram: "Instagram",
      phone: "Phone",
      phonePlaceholder: "Enter phone number",
    },
    menu: {
      name: "Menu",
      dishesAndCategories: "Dishes and Categories",
      menuName: "Menu Name",
      description: "Description",
      active: "Active",
    },
    menuCategories: {
      addCategory: "ADD CATEGORY",
      updatePrices: "UPDATE PRICES %",
      importPrices: "IMPORT PRICES",
    },
    accompaniment: {
      title: "Accompaniment Name",
      limit: "Limit",
      isVisible: "Is visible?",
      isRequired: "Is required?",
    },
    allergen: {
      title: "Allergen Name",
      description: "Description",
    },
    topping: {
      title: "Topping Name",
      limit: "Limit",
      isVisible: "Is visible?",
      isRequired: "Is required?",
    },
    variant: {
      title: "Variant Name",
      isVisible: "Is visible?",
      isRequired: "Is required?",
    },
    category: {
      title: "Category Name",
    },
    advertising: {
      name: "Advertising name",
      isVisible: "¿Is visible?",
      buttonText: "Button action text",
      url: "Button action url",
    },
    categoryProduct: {
      description: "Description",
      addProduct: "Add product",
      removeProduct: "Remove product",
      removeCategory: "Remove category",
    },
    product: {
      title: "Product Name",
      isRecommended: "Featured Product?",
      isVisible: "Visible?",
      price: "Price",
      finalPrice: "Final Price",
      discount: "Discount",
      description: "Description",
      summary: "Summary",
      visible: "Visible",
      hide: "Hidden",
      updatePercentage: "Update percentage",
      allergens: "Allergens o Specifications",
      toppings: "Toppings",
      accompaniments: "Accompaniments",
      variants: "Variants",
    },
    items: {
      toppingTitle: "{{entity}} Items List",
      accompanimentTitle: "{{entity}} Items List",
      variantTitle: "{{entity}} Items List",
      addItem: "ADD ITEM",
      price: "Price",
      isVisible: "Is visible?",
    },
  },
};

export default forms;
