import forms from "./forms";
import entities from "./entities";
import commons from "./commons";
import modals from "./modals";
import lateralMenu from "./lateralMenu";
import dataTableColumns from "./dataTableColumns";

const ENTranslation = {
  en: {
    translation: {
      ...lateralMenu,
      ...dataTableColumns,
      ...entities,
      ...forms,
      ...commons,
      ...modals,
    },
  },
};

export default ENTranslation;
