//Dependencies
import React from "react";

//Components
import AccompanimentForm from "../components/AccompanimentForm";

const AccompanimentNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Acompañamiento</h3>
      </div>
      <AccompanimentForm action={"create"} />
    </section>
  );
};

export default AccompanimentNew;
