//Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getAll } from "../utils/services";

//Components
import Datatable from "../components/Datatable";

//Assets
import * as actions from "../actions";

//Utils
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "restaurants";

const Restaurants = (props) => {
  const {
    setHasMore,
    setNextPage,
    setRestaurants,
    setIsLoading,
    clearRestaurants,
  } = props;

  const RESTAURANTS_URL = getAll.restaurants;

  useEffect(() => {
    clearRestaurants();
    setIsLoading(true);

    axios
      .get(RESTAURANTS_URL)
      .then((res) => {
        const {
          restaurants,
          meta: {
            links: { next, last },
          },
        } = res.data;
        setRestaurants(restaurants);

        CheckHasMore(next, last, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

export default connect(null, actions)(Restaurants);
