import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CartearModal from "./CartearModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get, update } from "../utils/services";

const useStyles = makeStyles(() => ({
  progress: {
    color: "#e20713 !important",
  },
  button: {
    background:
      "linear-gradient(-14.55deg, #e20613 -61.49%, #ee7b5a 100.63%) !important",
    color: "white !important",
  },
  errors: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "200px",
  },
}));

const InfoImportPrices = ({
  importMenuPrices,
  language,
  menu,
  classes,
  inputRef,
}) => {
  const exportMenuPrices = async () => {
    try {
      const response = await axios.get(
        `${get.menus(menu.id, menu.restaurant_id, menu.branch_id)}/export_prices?lang=${language}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "actualizar_precios.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.error("Failed to download Excel file", err);
    }
  };

  return (
    <>
      <h2 id="simple-modal-title">
        Deberás primero descargar la planilla actual de los productos de tu menú
        apretando el botón de DESCARGAR PLANILLA
      </h2>
      <h4 id="simple-modal-description">
        Luego tienes que modificar solamente la columna precios. No modifiques
        ninguna otra columna, ya que podría haber errores a la hora de la
        actualización. Una vez que hayas modificado los precios en la planilla
        descargada, estás listo para importarla apretando el botón “IMPORTAR
        PLANILLA”. No olvides GUARDAR los cambios luego de importarla para que
        los nuevos precios se reflejen en el menú.
      </h4>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => inputRef.current.click()}
      >
        IMPORTAR PLANILLA
        <input
          type="file"
          id="productFile"
          name="productFile"
          accept=".xlsx"
          ref={inputRef}
          onChange={(e) => importMenuPrices(e)}
        />
      </Button>
      <Button onClick={() => exportMenuPrices()}>Descargar Planilla</Button>
    </>
  );
};

const ErrorsImportPrices = ({ classes, errors, handleClose }) => {
  return (
    <div>
      <h1>Importación con errores</h1>
      <div className={classes.errors}>
        {errors.map((error) => {
          const row = Object.keys(error);
          return (
            <div>
              <strong>Error en la fila: {row}</strong>: {error[row].join(", ")}
            </div>
          );
        })}
      </div>
      <br></br>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => handleClose()}
      >
        OK
      </Button>
    </div>
  );
};

const LoadingImportPrices = ({ classes }) => {
  return (
    <div className={classes.root}>
      <h1>Espere...</h1>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

const SuccessfullImportPrices = ({ classes, handleClose }) => {
  return (
    <div>
      <h1>Success</h1>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => handleClose()}
      >
        OK
      </Button>
    </div>
  );
};

const ImportPrices = ({
  menu,
  open,
  setOpen,
  menuCategoryDishes,
  updateMenuCategoryProducts,
}) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef(null);
  const [showInfo, setShowInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setShowInfo(true);
    setOpen(false);
    setResponseSuccess(false);
    setErrors([]);
  };

  const importMenuPrices = async (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onload = () => {
      const baseURL = reader.result.split(";")[1].split(",")[1];
      axios
        .patch(
          `${update.menus(menu.restaurant_id, menu.id, menu.branch_id)}/import_prices?lang=${i18n.language}`,
          {
            xlsxbase64: baseURL,
          },
        )
        .then((res) => {
          setLoading(false);
          setShowInfo(false);
          if (res.data.errors) {
            setErrors(res.data.errors);
          } else {
            const products = res.data.products;
            const newProducts = [...menuCategoryDishes];
            console.log(products)
            menuCategoryDishes.forEach((d) => {
              const index = newProducts.findIndex((p) => p.id === d.id);
              const product = products[d.id]
              if(product) {
                newProducts[index] = {
                  ...d,
                  price: product.price.toFixed(2),
                  discount_ratio: product.discount_ratio,
                  discount_value: product.discount_value.toFixed(2),
                };
              }
            });
            updateMenuCategoryProducts({ products: newProducts });
            setResponseSuccess(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
  };

  return (
    <CartearModal open={open} onClose={handleClose}>
      {showInfo && (
        <InfoImportPrices
          importMenuPrices={importMenuPrices}
          classes={classes}
          inputRef={inputRef}
          menu={menu}
          language={i18n.language}
        />
      )}
      {loading && <LoadingImportPrices classes={classes} />}
      {responseSuccess && errors.length == 0 && (
        <SuccessfullImportPrices classes={classes} handleClose={handleClose} />
      )}
      {!loading && errors.length > 0 && (
        <ErrorsImportPrices
          classes={classes}
          errors={errors}
          handleClose={handleClose}
        />
      )}
    </CartearModal>
  );
};

export default ImportPrices;
