//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setAllergens,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearAllergens,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "allergens";

const Allergens = (props) => {
  const {
    setHasMore,
    setNextPage,
    setAllergens,
    setIsLoading,
    clearAllergens,
  } = props;

  const { id } = useParams();
  const ALLERGENS_URL = getAll.allergens(id);

  useEffect(() => {
    clearAllergens();
    setIsLoading(true);
    axios
      .get(ALLERGENS_URL)
      .then((res) => {
        const { allergens } = res.data;

        setAllergens(allergens);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapDispatchToProps = {
  setAllergens,
  clearAllergens,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(null, mapDispatchToProps)(Allergens);
