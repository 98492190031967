//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setProducts,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearProducts,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "products";

const Products = (props) => {
  const {
    setHasMore,
    setNextPage,
    setProducts,
    setIsLoading,
    clearProducts,
    loggedUser,
  } = props;

  const { id } = useParams();
  const PRODUCTS_URL = getAll.products(
    id ? id : loggedUser.restaurant_id,
    loggedUser.branch_id ? loggedUser.branch_id : null
  );

  useEffect(() => {
    clearProducts();
    setIsLoading(true);
    axios
      .get(PRODUCTS_URL)
      .then((res) => {
        const { products } = res.data;

        setProducts(products);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setProducts,
  clearProducts,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
