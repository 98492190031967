//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "../components/ImageUploader";

//Assets
import "../stylesheets/MobileVersionForm.css";
import * as actions from "../actions";

//Utils
import { getAll, create, update } from "../utils/services";
import { so_options } from "../utils/selectValues";

import {
  modalMobileVersionSuccessful,
  modalMobileVersionError,
} from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";

const ENTITY = "mobile_versions";

const MobileVersionForm = (props) => {
  const {
    id,
    min_supported,
    current,
    so,
    translations,
    image,
    action,
    updateMobileVersion,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const [soSelect, setSoSelect] = useState([]);

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();
  const description =
    translations?.length > 0
      ? translations.filter(({ locale }) => locale == "es")[0]?.description
      : null;

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let mobile_version = {
      ...data,
      so: soSelect.value,
      translations_attributes: [
        {
          att: "description",
          id:
            translations?.length > 0
              ? translations.filter(({ locale }) => locale == "es")[0]?.id
              : null,
          description: data.description,
          locale: "es",
        },
      ],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY], { mobile_version })
        .then(() =>
          modalMobileVersionSuccessful("creado", t, () => history.goBack())
        )
        .then(() => e.target.reset())
        .catch(() => modalMobileVersionError("crear", t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](id), { mobile_version })
        .then(() => {
          data.id = id;
          updateMobileVersion(data);
        })
        .then(() =>
          modalMobileVersionSuccessful("actualizado", t, () => history.goBack())
        )
        .catch(() => modalMobileVersionError("actualizar", data.name, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondLanguages(
        secondLanguages.filter((lang) => lang.value !== value.label)
      );
    }

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value)
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("image");

    if (action === "edit" && so) {
      const soTransformed = so_options.filter(({ value }) => value == so);
      setSoSelect(soTransformed);
    }
  }, []);

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="mobile_version-form-container">
          <section className="row">
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
            />
            <section className="mobile_version-main-info">
              <section className="mobile_version-name">
                <label>Versión mínima soportada</label>
                <input
                  name="min_supported"
                  className="form-control"
                  defaultValue={min_supported}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="mobile_version-name">
                <label>Versión actual</label>
                <input
                  name="current"
                  className="form-control"
                  defaultValue={current}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="row">
                <section className="form-group">
                  <label htmlFor="restaurant_id">Sistema operativo</label>
                  <Select
                    name="so"
                    value={soSelect}
                    placeholder="Seleccione"
                    options={so_options}
                    onChange={(value, action) => {
                      handleChange(value, action, setSoSelect);
                    }}
                    styles={cartearSelectStyles}
                    noOptionsMessage={() => "Sin resultados"}
                  />
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className="row">
          <section className="mobile_version-description w-100">
            <label>Descripción</label>
            <textarea
              name="description"
              className="form-control"
              defaultValue={description}
              ref={register}
            />
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(MobileVersionForm);
