import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

//Utils
import { update } from "../utils/services";
import {
  modalTableSuccessful,
  modalTableError,
  modalEntityAlreadyExist,
} from "../utils/modals";

const EditTableModal = ({ loggedUser, table_name, table_id, updateTable }) => {
  const { t } = useTranslation();
  const [tableName, setTableName] = useState(table_name);
  const { restaurant_id, branch_id } = loggedUser;

  const handleEditTableName = () => {
    let table = {
      name: tableName,
      branch_id: branch_id,
    };

    axios
      .put(update.tables(restaurant_id, branch_id, table_id), { table })
      .then((res) => {
        modalTableSuccessful("actualizada", table.name, t);
        handleCloseModal();

        let updated_table = res.data.find((t) => t.id === table_id);
        updateTable(updated_table);
      })
      .catch((e) => {
        if (e?.response?.data?.errors?.name[0] === "has already been taken") {
          modalEntityAlreadyExist("mesa", table.name, t);
        } else {
          modalTableError("actualizar", table.name, t);
        }
      });
  };

  const handleCloseModal = () => {
    const closeButton = document.querySelector(".swal2-close");
    closeButton.click();
  };

  return (
    <div>
      <section className="form-group d-flex justify-content-center align-items-center">
        <label className="nowrap mr-16" htmlFor="new-table-name">
          Nombre de la mesa:
        </label>
        <input
          type="text"
          className="form-control"
          value={tableName}
          onChange={(e) => setTableName(e.target.value)}
        />
      </section>
      <section className="d-flex justify-content-center align-items-center">
        <div className="secondary-button mr-16" onClick={handleEditTableName}>
          Confirmar
        </div>
        <div className="primary-button ml-16" onClick={handleCloseModal}>
          Cancelar
        </div>
      </section>
    </div>
  );
};

export default EditTableModal;
