//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Autocomplete, usePlacesWidget } from "react-google-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import { useHistory } from "react-router-dom";

//Components
import Map from "../components/Map";
import ImageUploader from "../components/ImageUploader";
import SchedulesContainer from "../components/SchedulesContainer";

//Assets
import "../stylesheets/BranchForm.css";
import "rc-time-picker/assets/index.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

//Utils
import cartearSelectStyles from "../utils/cartearSelectStyles";
import { getAll, create, update } from "../utils/services";
import { is_visible_options } from "../utils/selectValues";
import * as actions from "../actions";

import { modalBranchSuccessful, modalBranchError } from "../utils/modals";

const ENTITY = "branches";

const BranchForm = (props) => {
  const {
    id,
    name,
    nickname,
    url_name,
    translations,
    image,
    plan_id,
    address,
    lat,
    lng,
    is_visible,
    configuration,
    is_recommended,
    restaurant_id,
    action,
    updateBranch,
    loggedUser,
    showLoadingOverlay,
    schedules,
    phone,
    description,
    subtitle,
    currency_object,
  } = props;

  // const [planSelect, setPlanSelect] = useState([]);
  const [scheduleItems, setScheduleItems] = useState(schedules || []);
  const [isVisibleSelect, setIsVisibleSelect] = useState([]);
  const [scheduleDaySelect, setScheduleDaySelect] = useState([]);
  const [urlName, setUrlName] = useState(url_name);
  const [currencies, setCurrencies] = useState([]);
  const [currencySelect, setCurrencySelect] = useState([]);
  const [place, setPlace] = useState({
    address: address,
    lat: Number(lat),
    lng: Number(lng),
  });
  const [phoneValue, setPhoneValue] = useState(phone);
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, setValue, getValues } = useForm();
  const history = useHistory();

  const RESTAURANT_ID = restaurant_id || loggedUser.restaurant_id;

  let loginAsEntity = window.localStorage.getItem("restaurant");
  loginAsEntity = loginAsEntity ? JSON.parse(loginAsEntity) : null;

  const { ref } = usePlacesWidget({
    options: {
      types: ["geocode"],
      componentRestrictions: {
        country: loginAsEntity
          ? loginAsEntity.country_code
          : loggedUser.country_code,
      },
      fields: [
        "name",
        "geometry",
        "formatted_address",
        "address_component",
        "adr_address",
      ],
    },
    onPlaceSelected: (place) => {
      setPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
  });

  useEffect(() => {
    console.log(currency_object)
    if (currency_object) {
      setCurrencySelect([
        {
          value: currency_object.currency_id,
          id: currency_object.id,
          label: currency_object.currency.name,
        },
      ]);
    }
  }, [currency_object]);

  useEffect(() => {
    axios.get(getAll.currencies()).then((res) => {
      setCurrencies(res.data["currencies"]);
    });
  }, []);

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    let branch = {
      ...data,
      address: place.address,
      phone: phoneValue,
      lat: place.lat,
      lng: place.lng,
      schedules_attributes: scheduleItems,
      restaurant_id: RESTAURANT_ID,
      url_name: urlName,
      currency_object_attributes: data.currency_id
        ? { id: currency_object?.id, currency_id: data.currency_id }
        : null,
    };

    if (action === "create") {
      axios
        .post(create[ENTITY](RESTAURANT_ID), { branch })
        .then(() =>
          modalBranchSuccessful(
            t("commons.actions.created"),
            data.name,
            t,
            () => console.log(""),
          ),
        )
        .then(() => {
          e.target.reset();
          history.goBack();
        })
        .catch((err) => {
          modalBranchError(
            t("commons.actions.create"),
            data.name,
            t,
            err.response.data.errors,
          );
        })
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](RESTAURANT_ID, id), { branch, lang: i18n.language })
        .then(() => {
          data.id = id;
          updateBranch(data);
        })
        .then(() => {
          modalBranchSuccessful(
            t("commons.actions.updated"),
            data.name,
            t,
            () => "",
          );
          if (window.location.href.includes("settings")) {
            props.setCurrentSection("Dashboard");
            history.push("/");
          } else {
            history.goBack();
          }
        })
        .catch((error) => {
          modalBranchError(
            t("commons.actions.edit"),
            data.name,
            t,
            error.response.data.errors,
          );
        })
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null,
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondLanguages(
        secondLanguages.filter((lang) => lang.value !== value.label),
      );
    }

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value),
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("image");
    register("translations_attributes[0][locale]");
    register("is_visible");
    register("currency_id");

    if (action === "edit") {
      const isVisibleTransformed = is_visible_options.filter(
        (op) => op.value == is_visible,
      );

      setIsVisibleSelect(isVisibleTransformed);
    }
  }, []);

  return (
    <section>
      <form
        className="cartear-branch-form"
        onSubmit={handleSubmit(onSubmit)}
        autocomplete="off"
      >
        <section className="branch-form-left">
          <section className="branch-form-container">
            <section className="row">
              <ImageUploader
                inputName={"image"}
                image={image}
                handleSetValue={setValue}
              />
              <section className="branch-main-info">
                <section className="branch-name">
                  <label>{t("forms.branch.name")}</label>
                  <input
                    name="name"
                    maxLength={100}
                    className="form-control"
                    defaultValue={name}
                    ref={register}
                  />
                </section>
                <section className="row">
                  <section className="form-group">
                    <label>{t("forms.branch.branch")}</label>
                    <input
                      name="nickname"
                      className="form-control"
                      defaultValue={nickname}
                      ref={register}
                    />
                  </section>
                </section>
                <section className="row">
                  <section className="form-group">
                    <label>{t("forms.branch.urlName")}</label>
                    <input
                      name="url_name"
                      className="form-control"
                      value={urlName}
                      onChange={(e) => {
                        setUrlName(
                          e.target.value
                            .replace(/[^a-zA-Z0-9_.]+/g, "")
                            .toLowerCase(),
                        );
                      }}
                    />
                  </section>
                </section>
                {loggedUser.role === "super_admin" && (
                  <section className="row">
                    <section className="form-group">
                      <label htmlFor="is_visible">
                        {t("forms.branch.isVisible")}
                      </label>
                      <Select
                        name="is_visible"
                        value={isVisibleSelect}
                        placeholder="Seleccione"
                        options={is_visible_options}
                        onChange={(value, action) => {
                          handleChange(value, action, setIsVisibleSelect);
                        }}
                        styles={cartearSelectStyles}
                      />
                    </section>
                  </section>
                )}
              </section>
            </section>
          </section>
          {/* <section className="row">
            <section className="form-group">
              <label htmlFor="plan_id">Plan</label>
              <Select
                name="plan_id"
                value={planSelect}
                placeholder="Seleccione"
                options={plans_options.map((plan) => ({
                  label: plan.label,
                  value: plan.value,
                }))}
                onChange={(value, action) => {
                  handleChange(value, action, setPlanSelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section> */}
          <section className="row">
            <section className="branch-description">
              <label>{t("forms.branch.subtitle")}</label>
              <textarea
                maxLength={100}
                name="translations_attributes[1][description]"
                className="form-control"
                placeholder={t("forms.branch.subtitlePlaceholder")}
                defaultValue={subtitle}
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[1][id]"
                value={
                  !!translations && translations.length > 0
                    ? translations.filter((prod) => prod.att === "subtitle")
                        .length > 0
                      ? parseInt(
                          translations.filter(
                            (prod) => prod.att === "subtitle",
                          )[0].id,
                        )
                      : null
                    : null
                }
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[1][att]"
                value="subtitle"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[1][locale]"
                value="es"
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="branch-description">
              <label>{t("forms.branch.description")}</label>
              <textarea
                name="translations_attributes[0][description]"
                className="form-control"
                maxLength={100}
                placeholder={t("forms.branch.descriptionPlaceholder")}
                defaultValue={description}
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][id]"
                value={
                  !!translations && translations.length > 0
                    ? translations.filter((prod) => prod.att === "description")
                        .length > 0
                      ? parseInt(
                          translations.filter(
                            (prod) => prod.att === "description",
                          )[0].id,
                        )
                      : null
                    : null
                }
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][att]"
                value="description"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][locale]"
                value="es"
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.address")}</label>
              <input
                id="searchAddressInput"
                name="address"
                className="form-control"
                placeholder="Scalabrini Ortiz 3291"
                autocomplete="off"
                ref={ref}
                value={place.address}
                onChange={(e) =>
                  setPlace({ ...place, address: e.target.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </section>
          </section>
          <section className="row">
            {isLoaded && <Map address={place} setPlace={setPlace} />}
          </section>

          <section className="row">
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value={t("commons.buttons.save")}
              />
            </section>
          </section>
        </section>
        <section className="branch-form-right">
          <section className="row">
            <div className="form-title">
              <h3>{t("forms.branch.aditionalInfo")}</h3>
            </div>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.price")}</label>
              <input
                name="configuration_attributes[service_charge]"
                className="form-control"
                defaultValue={configuration?.service_charge}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.website")}</label>
              <input
                name="configuration_attributes[website]"
                className="form-control"
                defaultValue={configuration?.website}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.whatsapp")}</label>
              <input
                name="configuration_attributes[whatsapp]"
                className="form-control"
                defaultValue={configuration?.whatsapp}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.facebook")}</label>
              <input
                name="configuration_attributes[facebook]"
                className="form-control"
                defaultValue={configuration?.facebook}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.instagram")}</label>
              <input
                name="configuration_attributes[instagram]"
                className="form-control"
                defaultValue={configuration?.instagram}
                ref={register}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label>{t("forms.branch.phone")}</label>
              <PhoneInput
                international
                placeholder={t("forms.branch.phonePlaceholder")}
                className="form-control"
                country={
                  loginAsEntity
                    ? loginAsEntity.country_code
                    : loggedUser.country_code
                }
                value={phoneValue}
                onChange={setPhoneValue}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label htmlFor="currency">Moneda</label>
              <Select
                name="currency_id"
                value={currencySelect}
                placeholder="Seleccione"
                options={currencies.map((currency) => ({
                  label: currency.name,
                  value: currency.id,
                }))}
                onChange={(value, action) => {
                  handleChange(value, action, setCurrencySelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
          <section className="">
            <section className="">
              <SchedulesContainer
                scheduleItems={scheduleItems}
                setScheduleItems={setScheduleItems}
              />
            </section>
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(BranchForm);
