//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import PageLoader from "../components/PageLoader";
import LoungeHeader from "../components/LoungeHeader";
import LoungeTables from "../components/LoungeTables";

//Utils
import { filter } from "../utils/services";
import * as actions from "../actions";

const Lounge = ({ setTables, clearTables, loggedUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { restaurant_id, branch_id } = loggedUser;

  useEffect(() => {
    const localStorageItem = localStorage.getItem("table_filter");
    const parsedItem = JSON.parse(localStorageItem);

    clearTables();
    axios
      .get(
        filter.tables(
          restaurant_id,
          branch_id,
          parsedItem ? parsedItem.value : "asc"
        )
      )
      .then((res) => {
        setTables(res.data.tables);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <section className="container">
      {isLoading ? (
        <section className="w-100 d-flex justify-content-center">
          <PageLoader loading={true} />
        </section>
      ) : (
        <>
          <LoungeHeader />
          <LoungeTables />
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(Lounge);
