//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

//Components
import DatatableHeader from "../components/DatatableHeader";
import { default as DatatableComponent } from "react-data-table-component";
import Spinner from "./Spinner";
import { InView } from "react-intersection-observer";

//Assets
import "../stylesheets/datatable.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import * as actions from "../actions";

//Utils
import { isBranchUser } from "../utils/managment.js";
import { sortAttributes, origin } from "../utils/sortTable";
import datatableColumns from "../utils/DatatableColumns";
import { datatableActions } from "../utils/DatatableActions";
import { customStyles } from "../utils/DatatableCustomStyles";
import { DatatableSortRows } from "../utils/DatatableSortRows";
import { DatatableFetchMoreData } from "../utils/DatatableFetchMoreData";
import ButtonToggle from "./ButtonToggle";
import { setCurrentSection } from "../actions/index.js";
import {
  modalCloneEntity,
  modalDeleteEntity,
  modalAssignEntity,
  modalEmailSentSuccessful,
  modalEmailSentError,
  modalProductSuccessful,
} from "../utils/modals";
import { clone, sort, remove, getAll, update, send } from "../utils/services";

const Datatable = (props) => {
  const {
    isLoading,
    filteredItems,
    hasMore,
    entity,
    selectedEntity,
    setFilteredItems,
    setIsLoading,
    setEntity,
    loggedUser,
    showLoadingOverlay,
    setUsers,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  let columns = datatableColumns[entity];
  // se pisa el nombre para las traducciones
  columns = columns.map((column) => ({
    ...column,
    name: t(`dataTableColumns.${entity}.${column.name}`),
  }));
  if (loggedUser.role !== "super_admin" && entity === "branches") {
    columns = columns.filter(
      (column) => column.name !== t(`dataTableColumns.${entity}.isVisible`),
    );
  }
  const menuOptions = datatableActions[entity];
  const [sortDirection, setSortDirection] = useState("asc");
  const [fetchingMoreData, setFetchingMoreData] = useState(false);

  const cloneEntity = (selectedEntity) => {
    axios
      .post(clone.products(selectedEntity.restaurant_id, selectedEntity.id), {
        type: entity,
      })
      .then((res) => {
        const product = res.data[0];
        props.setProducts([product]);
        modalProductSuccessful("creado", selectedEntity.title, () => null);
      })
      .catch(() => Swal.fire("No se pudo clonar el producto", "", "error"))
      .finally(() => showLoadingOverlay(false));
  };

  const handleCopyToClipboard = (dynamicLink) => {
    navigator.clipboard.writeText(dynamicLink).then(() => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: "El texto se ha copiado al portapapeles.",
      });
    });
  };

  const handleSort = () => {
    sortDirection === "asc"
      ? setSortDirection("desc")
      : setSortDirection("asc");

    setFilteredItems([]);
    setIsLoading(true);
    const newId = id || loggedUser.restaurant_id;
    const SORT_DATATABLE_URL = newId
      ? sort[entity](newId, sortDirection)
      : sort[entity](sortDirection);

    DatatableSortRows(entity, props, SORT_DATATABLE_URL, setIsLoading);
  };

  const handleDeleteEntity = (entity, selectedEntity, url) => {
    modalDeleteEntity(entity, selectedEntity, url, props);
  };

  const handleSendEmail = (restaurant_id, user_id, role) => {
    const body = {
      mail_type:
        role === "restaurant_admin" || "super_admin" ? "restaurant" : "branch",
    };

    showLoadingOverlay(true);
    axios
      .post(send.mail(restaurant_id, user_id), body)
      .then(() => {
        setFilteredItems(
          filteredItems.map((u) => {
            if (u.id === user_id) {
              return { ...u, sent_confirmation: true };
            }
            return u;
          }),
        );
        modalEmailSentSuccessful(t);
      })
      .catch(() => modalEmailSentError(t))
      .finally(() => showLoadingOverlay(false));
  };

  const handleAssignEntity = (entity, selectedEntity, url) => {
    const BRANCHES_URL = getAll.branches(loggedUser.restaurant_id);

    axios.get(BRANCHES_URL).then((res) => {
      const { branches } = res.data;
      const menuBranches = selectedEntity.branch_ids
        ? [...selectedEntity.branch_ids, selectedEntity.branch_id]
        : [];
      const availableBranches = branches.filter(
        (b) => !menuBranches.includes(b.id),
      );
      modalAssignEntity(
        entity,
        selectedEntity,
        url,
        props,
        availableBranches,
        t,
      );
    });
  };

  const handleFetchMoreData = (inView) => {
    DatatableFetchMoreData(inView, entity, props, setFetchingMoreData);
  };

  useEffect(() => {
    setEntity(entity);
    setFilteredItems([]);
    dispatch(setCurrentSection(t(`entities.${entity}.title`)));
    if (entity === "restaurants") {
      window.localStorage.removeItem("restaurant");
    }
  }, [entity]);

  const sortFilteredItems = sortAttributes[entity]
    ? filteredItems.sort((a, b) => {
        const nameA = a[sortAttributes[entity]]?.toUpperCase(); // ignore upper and lowercase
        const nameB = b[sortAttributes[entity]]?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
    : filteredItems;

  if (origin.includes(entity) && loggedUser.branch_id) {
    if (columns.findIndex((c) => c.name == "Origen") === -1) {
      columns.splice(columns.length - 2, 0, {
        name: "Origen",
        maxWidth: "200px",
        ignoreRowClick: true,
        cell: (row) => (
          <ButtonToggle data={row} entity={entity} att={"branch_id"} />
        ),
        center: true,
      });
    }
  }

  if (entity == "menus" && !isBranchUser(loggedUser.role)) {
    // const index = columns.findIndex((c) => c.name === "Estado");
    // if (index !== -1) {
    //   columns.splice(index, 1);
    // }
  }

  const canShareLink = (entity) => {
    return !!entity.url_3d_web && !!entity.dynamic_link;
  };

  return (
    <div style={{ width: "100%" }}>
      <DatatableComponent
        columns={columns}
        customStyles={customStyles}
        data={sortFilteredItems}
        filter={true}
        onSort={handleSort}
        progressComponent={<Spinner loading={true} />}
        progressPending={isLoading}
        noDataComponent={
          <h2 className="no-data-msg">No se encontraron resultados</h2>
        }
        sortIcon={<SortIcon />}
        sortServer={true}
        subHeader
        subHeaderComponent={<DatatableHeader entity={entity} />}
      />
      <div className="actions-menu">
        <ul className="actions-menu-list">
          {menuOptions.map((option, index) => {
            const { name, action } = option;
            const readOnly =
              isBranchUser(loggedUser.role) && !selectedEntity.branch_id;
            const cannotAssignMenu =
              isBranchUser(loggedUser.role) && entity === "menus";

            return (
              <li className="actions-menu-item" key={index}>
                {action === "delete" && !readOnly && (
                  <button
                    type="button"
                    onClick={() =>
                      handleDeleteEntity(
                        entity,
                        selectedEntity,
                        remove[entity](
                          selectedEntity.id,
                          loggedUser.restaurant_id,
                          loggedUser.branch_id,
                        ),
                      )
                    }
                  >
                    {t(`entities.${entity}.actions.${name}`)}
                  </button>
                )}
                {!readOnly && action === "assign" && !cannotAssignMenu && (
                  <button
                    type="button"
                    onClick={() =>
                      handleAssignEntity(
                        entity,
                        selectedEntity,
                        clone[entity](
                          id ? id : loggedUser.restaurant_id,
                          selectedEntity.id,
                        ),
                      )
                    }
                  >
                    {t(`entities.${entity}.actions.${name}`)}
                  </button>
                )}
                {!readOnly && action === "send_email" && (
                  <button
                    type="button"
                    style={{
                      display: selectedEntity.send_invitation ? "none" : "flex",
                    }}
                    onClick={() =>
                      handleSendEmail(
                        selectedEntity.restaurant_id,
                        selectedEntity.id,
                        selectedEntity.role,
                      )
                    }
                  >
                    {t(`entities.${entity}.actions.${name}`)}
                  </button>
                )}
                {name === "share" && canShareLink(selectedEntity) && (
                  <button
                    type="button"
                    onClick={() =>
                      handleCopyToClipboard(selectedEntity.dynamic_link)
                    }
                  >
                    {t(`entities.${entity}.actions.${name}`)}
                  </button>
                )}
                {!readOnly && name === "clone" && (
                  <button
                    type="button"
                    onClick={() =>
                      modalCloneEntity(selectedEntity, cloneEntity, t)
                    }
                  >
                    {t(`entities.${entity}.actions.${name}`)}
                  </button>
                )}
                {[
                  "show",
                  "branches",
                  "models3D",
                  "loginAs",
                  "edit",
                  "translations",
                ].includes(name) && (
                  <Link
                    target={name === "loginAs" ? "_blank" : ""}
                    onClick={() => {
                      if (name === "loginAs") {
                        window.localStorage.setItem("loginAs", true);
                        if (entity == "restaurants") {
                          axios.put("/backoffice/v1/users/login_as_language", {
                            login_as_language: selectedEntity.primary_code,
                          });
                          window.localStorage.setItem(
                            "restaurant",
                            JSON.stringify(selectedEntity),
                          );
                        }
                      }
                      if (name === "branches") {
                        window.localStorage.setItem(
                          "restaurant",
                          JSON.stringify(selectedEntity),
                        );
                      }
                    }}
                    to={action(
                      selectedEntity.id,
                      selectedEntity.restaurant_id,
                      entity === "plan_promotions"
                        ? selectedEntity.plan_id
                        : null,
                    )}
                  >
                    {name === "edit" && readOnly
                      ? t(`entities.${entity}.actions.show`)
                      : t(`entities.${entity}.actions.${name}`)}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {!isLoading && hasMore && (
        <InView
          as="div"
          onChange={(inView) => handleFetchMoreData(inView)}
        ></InView>
      )}
      {fetchingMoreData && <Spinner loading={true} />}
    </div>
  );
};

const mapStateToProps = (state, ownState) => {
  if (ownState.entity !== state.entity) {
    return {
      filteredItems: [],
      selectedEntity: state.selectedEntity,
      nextPage: state.nextPage,
      isLoading: state.isLoading,
      hasMore: state.hasMore,
      loggedUser: state.loggedUser,
    };
  }
  return {
    filteredItems: state.filteredItems,
    selectedEntity: state.selectedEntity,
    nextPage: state.nextPage,
    isLoading: state.isLoading,
    hasMore: state.hasMore,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(Datatable);
