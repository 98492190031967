//Dependencies
import React from "react";

//Components
import AdvertisingForm from "../components/AdvertisingForm";

const AdvertisingNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Publicidad</h3>
      </div>
      <AdvertisingForm action={"create"} />
    </section>
  );
};

export default AdvertisingNew;
