//Dependencies
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Assets
import unavailableImage from "../images/unavailable-image.jpg";
import "../stylesheets/imageUploader.css";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import ErrorIcon from "@material-ui/icons/Error";
import { Tooltip, IconButton } from "@material-ui/core";

const VideoUploader = ({
  inputName,
  image,
  handleSetValue,
  setCategoryImage = null,
  readOnly = false,
  required = false,
}) => {
  const [entityImage, setEntityImage] = useState(unavailableImage);
  const [oversize, setOversize] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!image) setEntityImage(image);
  }, [image]);

  const handleImage = (e) => {
    const reader = new FileReader();
    const size = e.target.files[0].size;

    if (size > 5000000) {
      setOversize(true);
    } else {
      setOversize(false);
      reader.onload = () => {
        if (reader.readyState === 2) {
          setEntityImage(URL.createObjectURL(e.target.files[0]));
          handleSetValue(e.target.name, reader.result);

          if (!!setCategoryImage) {
            setCategoryImage(reader.result);
          }
        }
      };

      try {
        reader.readAsDataURL(e.target.files[0]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleRemoveImage = () => {
    if (!!setCategoryImage) {
      setCategoryImage(null);
    }

    const inputFile = document.querySelector(`#upload-${inputName}`);
    inputFile.value = null;

    setEntityImage(unavailableImage);
    handleSetValue(inputName, "");
  };

  return (
    <div>
      <section className="entity-previewimage-container">
        {oversize && (
          <Tooltip
            title="Máximo tamaño permitido 5MB"
            className="button-remove-previewimage"
          >
            <ErrorIcon style={{ color: "red" }} />
          </Tooltip>
        )}
        {!readOnly && entityImage !== unavailableImage && (
          <span
            className="button-remove-previewimage"
            onClick={handleRemoveImage}
          >
            <RemoveIcon />
          </span>
        )}
        <video
          controls
          src={entityImage}
          className="entity-previewimage"
        ></video>
        <input
          type="file"
          id={`upload-${inputName}`}
          name={inputName}
          accept="video/*"
          onChange={handleImage}
          ref={inputRef}
        />
        {!readOnly && (
          <section
            className="upload-previewimage-button"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            AÑADIR VIDEO
          </section>
        )}
      </section>
      {required && (
        <section style={{ width: "30vw", color: "red", fontWeight: "bold", marginTop: "10px" }}>
          <label>Esta imagen es requerida</label>
        </section>
      )}
    </div>
  );
};

export default VideoUploader;
