//Dependencies
import React from "react";

//Components
import ProductForm from "../components/ProductForm";

const ProductNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Producto</h3>
      </div>
      <ProductForm action={"create"} />
    </section>
  );
};

export default ProductNew;
