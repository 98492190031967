const modals = {
  modals: {
    common: {
      errorTitle: "¡Ha ocurrido un error!",
      successfulTitle: "¡Excelente!",
      close: "Cerrar",
    },
    removeProduct: {
      title: "¿Está seguro que desea quitar el producto {{productName}}?",
    },
    removeMenuCategory: {
      title: "¿Está seguro que desea quitar la category{{categoryName}}?",
    },
    updatePrices: {
      title: "Actualizar precios",
      updatePercentage: "Porcentaje de actualización",
    },
    addProduct: {
      title: "Agregar Producto a la Categoria",
      inputPlaceholder: "Selecciona un Producto",
      confirmButton: "Seleccionar",
      emptyOption: "Debes seleccionar un producto para continuar",
      alreadyExistsTitle: "¡Este producto ya ha sido agregado!",
      alreadyExistsContent:
        "Ya existe un producto llamado  <strong>{{productName}}</strong> para esta categoría",
      successfullyAddedTitle: "¡Excelente!",
      successfullyAddedContent:
        "Se ha agregado el producto <strong>{{productName}}</strong>.",
    },
    copyToClipboard: {
      title: "El texto se ha copiado al portapapeles",
    },
    addCategory: {
      title: "Agregar Categoría",
      inputPlaceholder: "Selecciona una Categoria",
      confirmButton: "Seleccionar",
      emptyResult: "Debes seleccionar una categoría para continuar",
      contentHtml:
        "Ya existe una categoría llamada  <strong>${{categoryName}}</strong> para este menú.",
      alreadyExistsTitle: "¡Esta categoría ya ha sido agregada!",
      successfullyAddedTitle: "¡Excelente!",
      successfullyAddedContent:
        "Se ha agregado la categoria <strong>{{categoryName}}</strong>.",
    },
    categorySuccessful: {
      content:
        "La categoría <strong>{{categoryName}}</strong> ha sido {{action}} exitosamente.",
    },
    categoryError: {
      content:
        "No se ha podido {{action}} la categoría <strong>{{categoryName}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    restaurantSuccessful: {
      content:
        "El restaurante <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    restaurantError: {
      content:
        "No se ha podido {{action}} el restaurante <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    allergenSuccessful: {
      content:
        "El alérgeno <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    allergenError: {
      content:
        "No se ha podido {{action}} el alérgeno <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    tagSuccessful: {
      content:
        "La etiqueta <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    tagError: {
      content:
        "No se ha podido {{action}} la etiqueta <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    userSuccessful: {
      content:
        "El usuario <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    changePasswordSuccessful: {
      content: "La contraseña ha sido exitosamente",
    },
    currencySuccessful: {
      content:
        "La divisa <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    currencyError: {
      content:
        "No se ha podido {{action}} la divisa <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    countrySuccessful: {
      content:
        "El país <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    countryError: {
      content:
        "No se ha podido {{action}} el país <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    productSuccessful: {
      content:
        "El producto <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    productError: {
      content:
        "No se ha podido {{action}} el producto <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    toppingSuccessful: {
      content:
        "El topping <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    toppingError: {
      content:
        "No se ha podido {{action}} el topping <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    accompanimentSuccessful: {
      content:
        "El acompañamiento <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    accompanimentError: {
      content:
        "No se ha podido {{action}} el acompañamiento <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    advertisingSuccessful: {
      content:
        "La publicidad <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    advertisingError: {
      content:
        "No se ha podido {{action}} la publicidad <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    variantSuccessful: {
      content:
        "La variante <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    variantError: {
      content:
        "No se ha podido {{action}} la variante <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    planSuccessful: {
      content:
        "El plan <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    planError: {
      content:
        "No se ha podido {{action}} el plan <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    mobileVersionSuccessful: {
      content: "La version ha sido {{action}} exitosamente.",
    },
    mobileVersionError: {
      content:
        "No se ha podido {{action}} la version, inténtelo de nuevo en unos minutos.",
    },
    menuSuccessful: {
      content:
        "El menu <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    menuError: {
      content:
        "No se ha podido {{action}} el menu <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    modelSuccessful: {
      content: "El modelo ha sido {{action}} exitosamente",
    },
    modelError: {
      content:
        "No se ha podido {{action}} el modelo, inténtelo de nuevo en unos minutos",
    },
    branchSuccessful: {
      content:
        "La sucursal <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    branchError: {
      content:
        "No se ha podido {{action}} la sucursal <strong>{{name}}</strong>, por lo siguiente {{listErrors}} inténtelo de nuevo en unos minutos.",
    },
    translationSuccessful: {
      content:
        "La traducción <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    translationError: {
      content:
        "No se ha podido {{action}} la traducción <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    successfulPayment: {
      content: "Su pago ha sido procesado exitosamente.",
    },
    errorPayment: {
      content:
        "No se ha podido procesar su pago, inténtelo de nuevo en unos minutos.",
    },
    notificationSuccessful: {
      content: "La notificación ha sido enviada exitosamente.",
    },
    notificationError: {
      content:
        "No se ha podido enviar la notificación, inténtelo de nuevo en unos minutos.",
    },
    menuClonedSuccessful: {
      content: "El menú ha sido clonado exitosamente",
    },
    menuClonedError: {
      content:
        "No se ha podido clonar el menú, inténtelo de nuevo en unos minutos.",
    },
    menuAsignedSuccessful: {
      content: "El menú ha sido asignado exitosamente",
    },
    menuAssignedError: {
      content:
        "No se ha podido asignado el menú, inténtelo de nuevo en unos minutos.",
    },
    suscriptionSuccessful: {
      content: "La suscripción ha sido creada exitosamente.",
    },
    suscriptionError: {
      content:
        "No se ha podido crear la suscripción, inténtelo de nuevo en unos minutos.",
    },
    cancelSuscriptionSucccessful: {
      content: "La suscripción ha sido cancelada exitosamente.",
    },
    cancelSuscriptionError: {
      content:
        "No se ha podido cancelar la suscripción, inténtelo de nuevo en unos minutos.",
    },
    emailSentSuccessful: {
      content: "El email ha sido enviado exitosamente.",
    },
    emailSentError: {
      content:
        "No se ha podido enviar el email, inténtelo de nuevo en unos minutos.",
    },
    mailTemplateSuccesful: {
      content: "El mail template ha sido guardado exitosamente.",
    },
    mailTemplateError: {
      content:
        "No se ha podido guardar mail template, inténtelo de nuevo en unos minutos.",
    },
    tableSuccessful: {
      content:
        "La mesa <strong>{{name}}</strong> ha sido {{action}} exitosamente.",
    },
    tableError: {
      content:
        "No se ha podido {{action}} la mesa <strong>{{name}}</strong>, inténtelo de nuevo en unos minutos.",
    },
    multiTableSuccessful: {
      content: "La creación masiva de mesas ha sido exitosa.",
    },
    multiTableError: {
      content:
        "No se ha podido realizar la creación masiva de mesas, inténtelo de nuevo en unos minutos.",
    },
    entityAlreadyExist: {
      content:
        "Ya existe una {{entity}} con el nombre <strong>{{name}}</strong>.",
    },
    productClone: {
      title: "Clonar producto",
      content: "Estás seguro de clonar el producto {{title}}?",
      confirm: "Clonar",
    },
    branchAssign: {
      title: "Seleccione Sucursal",
      confirm: "Confirmar",
      validate: "Debes seleccionar al menos una sucursal para continuar",
    },
  },
};

export default modals;
