const lateralMenu = {
  lateralMenu: {
    dashboard: "Dashboard",
    restaurants: "Restaurants",
    paymentMethods: "Payment Methods",
    deliveryMethods: "Delivery Methods",
    currencies: "Currencies",
    countries: "Countries",
    billing: "Billing",
    plans: "Plans",
    mobileVersions: "Mobile Versions",
    devices: "Devices",
    suggestions: "Suggestions",
    emails: "Emails",
    restaurantEmail: "Restaurant User Welcome",
    branchEmail: "Branch User Welcome",
    passwordRecoveryEmail: "Password Recovery",
    subscriptionExpireSoon: "Subscription Expiring Soon",
    subscriptionExpired: "Expired Subscription",
    subscriptionSuccessful: "Successful Subscription",
    subscriptionSuccessfullyRenewed: "Subscription Successfully Renewed",
    allergens: "Allergens",
    tags: "Tags",
    branches: "Branches",
    menus: "Menus",
    categories: "Categories",
    products: "Products",
    toppings: "Toppings",
    accompaniments: "Accompaniments",
    variants: "Variants",
    settings: "Settings",
    users: "Users",
    managment: "MANAGEMENT",
    advertisings: "Advertisings",
    qrCode: "QR Code",
    plansAndPayments: "Plans and Payments",
  },
};

export default lateralMenu;
