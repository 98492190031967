//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import * as actions from "../actions";

const PaymentButton = (props) => {
  const {
    data: { state, id, checkout_url },
    setPaymentId,
  } = props;

  const handleClick = () => {
    document.querySelectorAll(".hire-button")[0].click();
    setPaymentId(id);
  };

  return (
    <>
      <span>
        {state !== "paid" && state !== "pending" ? (
          <section className="create-section p-0" onClick={() => handleClick()}>
            <div className="create-button">PAGAR</div>
          </section>
        ) : state === "pending" && !!checkout_url ? (
          <section className="create-section p-0">
            <a
              href={checkout_url}
              target="_blank"
              className="create-button border-red"
            >
              PAGAR
            </a>
          </section>
        ) : (
          "-"
        )}
      </span>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredItems: state.filteredItems,
  };
};

export default connect(mapStateToProps, actions)(PaymentButton);
