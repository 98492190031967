//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//permissions
import ImageUploader from "../components/ImageUploader";

//Assets
import "../stylesheets/UserForm.css";
import { updateUser } from "../actions";

//Utils
import { getAll, create, update } from "../utils/services";
import { roles_options, send_mail_options } from "../utils/selectValues";
import { modalUserSuccessful, modalUserError } from "../utils/modals";
import cartearSelectStyles from "../utils/cartearSelectStyles";

const ENTITY = "users";

const UserForm = (props) => {
  const {
    id,
    first_name,
    last_name,
    email,
    avatar,
    restaurant_id,
    action,
    updateUser,
    loggedUser,
    role_value,
    branch_id,
    primary_user_branch_id,
    branch_name,
    secondary_branches,
    permission_objects,
  } = props;

  const { t } = useTranslation();
  const BRANCH_USER = 3;
  const RESTAURANT_ADMIN = 1;
  const [restaurants, setRestaurants] = useState([]);
  const [roles, setRoles] = useState([]);
  const [sendMailOptions, setSendMailOptions] = useState([]);
  const [restaurantsSelect, setRestaurantsSelect] = useState({});
  const [rolesSelect, setRolesSelect] = useState({});
  const [sendMailSelect, setSendMailSelect] = useState([]);
  const [isBranchUser, setIsBranchUser] = useState(false);
  const [branches, setBranches] = useState([]);
  const [secondBranches, setSecondBranches] = useState([]);
  const [mainBranchSelect, setMainBranchSelect] = useState([]);
  const [removedBranches, setRemovedBranches] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [secondaryBranchesSelect, setSecondaryBranchesSelect] = useState([]);

  const { register, handleSubmit, setValue, getValues } = useForm();
  const history = useHistory();

  useEffect(() => {
    setUserPermissions(permission_objects);
  }, [permission_objects]);

  useEffect(() => {
    axios
      .get(getAll.permissions, { allowed_objects: "users" })
      .then((res) => {
        setPermissions(res.data.permissions);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = (data, e) => {
    let user = {
      id: id,
      ...data,
      send_invitation: data.send_invitation,
      restaurant_id: restaurantsSelect.value || restaurantsSelect[0]?.value,
      user_branches_attributes: [],
      permission_objects_attributes: userPermissions?.filter(
        (up) => up.value !== false || up.id,
      ),
    };

    if (mainBranchSelect.value) {
      user.user_branches_attributes.push({
        branch_id: mainBranchSelect.value,
        primary: true,
        id: primary_user_branch_id,
      });
    }

    secondaryBranchesSelect.forEach((b) => {
      const id = typeof b.value === "number" ? b.value : b.value.branch_id;
      const user_branch = secondary_branches?.find((ub) => ub.branch_id === id);
      user.user_branches_attributes.push({
        branch_id: id,
        primary: false,
        id: user_branch?.id,
      });
    });

    removedBranches.forEach((b) => {
      const user_branch = secondary_branches?.find(
        (ub) => ub.branch_id === b.value,
      );
      user.user_branches_attributes.push({
        branch_id: b.value,
        _destroy: true,
        id: user_branch?.id,
      });
    });

    if (isBranchUser && mainBranchSelect.length === 0) {
      modalUserError(
        "crear",
        `${data.first_name} ${data.last_name}`,
        ["Debe elegir una sucursal"],
        t,
      );
    } else if (action === "create") {
      axios
        .post(create.users, { user })
        .then(() =>
          modalUserSuccessful(
            "creado",
            `${data.first_name} ${data.last_name}`,
            t,
            () => history.goBack(),
          ),
        )
        .then(() => e.target.reset())
        .catch((err) =>
          modalUserError(
            "crear",
            `${data.first_name} ${data.last_name}`,
            err.response.data.errors,
            t,
          ),
        );
    } else if (action === "edit") {
      axios
        .put(update.users(id, restaurant_id, branch_id), { user })
        .then(() => {
          data.id = id;
          updateUser(data);
        })
        .then(() =>
          modalUserSuccessful(
            "actualizado",
            `${data.first_name} ${data.last_name}`,
            t,
            () => history.goBack(),
          ),
        )
        .catch((err) =>
          modalUserError(
            "actualizar",
            `${data.first_name} ${data.last_name}`,
            err.response.data.errors,
            t,
          ),
        );
    }
  };

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null,
  ) => {
    const inputRef = action.name;
    if (childSelect) {
      setSecondBranches(secondBranches.filter((b) => b.value !== value.value));
      if (secondaryBranchesSelect.map((b) => b.label).includes(value.label)) {
        setSecondaryBranchesSelect(
          secondaryBranchesSelect.filter((b) => b.label !== value.label),
        );
        setRemovedBranches([...removedBranches, { ...value, _destroy: true }]);
      }
    }

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value),
      );
      const removedIds = removedBranches.map((b) => b.value);
      if (action.action == "remove-value") {
        if (!removedIds.includes(action.removedValue.value)) {
          setRemovedBranches([
            ...removedBranches,
            { ...action.removedValue, _destroy: true },
          ]);
        }
      } else {
        if (removedIds.includes(action.option.value.branch_id)) {
          setRemovedBranches(
            removedBranches.filter(
              (b) => b.value !== action.option.value.branch_id,
            ),
          );
        }
      }
    } else setValue(inputRef, value.value);
    setStateFunction(value);
  };

  useEffect(() => {
    register("avatar");
    register("role");
    register("send_invitation");

    let aux = [];
    axios
      .get(getAll.restaurants)
      .then((res) => {
        aux = res.data["restaurants"];
        setRestaurants(aux);
      })
      .then(() => {
        setRoles(roles_options);
        setSendMailOptions(send_mail_options);
      })
      .then(() =>
        axios
          .get(
            loggedUser.role === "super_admin"
              ? getAll.branches(aux[0]?.id)
              : getAll.branches(loggedUser.restaurant_id),
          )
          .then((res) => {
            setBranches(res.data.branches);
            setSecondBranches(res.data.branches);
          }),
      )
      .then(() => {
        if (action === "edit") {
          const currentRole = Number(role_value);
          const roleTransformed = roles_options.find(
            (role) => role.value == currentRole,
          );
          if (currentRole == BRANCH_USER) {
            setIsBranchUser(true);
            setMainBranchSelect({ value: branch_id, label: branch_name });
            setSecondaryBranchesSelect(
              secondary_branches.map((b) => ({
                label: b.name,
                value: b.branch_id,
                id: b.id,
              })),
            );
          }
          setRolesSelect(roleTransformed);
          setSendMailSelect(send_mail_options);
        }
      });
  }, []);

  useEffect(() => {
    if (restaurantsSelect.value) {
      axios.get(getAll.branches(restaurantsSelect.value)).then((res) => {
        setBranches(res.data.branches);
        setSecondBranches(res.data.branches);
      });
    }
  }, [restaurantsSelect]);

  useEffect(() => {
    if (action === "edit" && restaurants.length > 0) {
      const restaurantTransformed = restaurants
        .filter((rest) => rest.id == restaurant_id)
        .map((res) => ({ value: res.id, label: res.name }));
      setRestaurantsSelect(restaurantTransformed);
    } else {
      if (loggedUser.role === "restaurant_admin") {
        setRestaurantsSelect(
          restaurants
            .filter((res) => res.id == loggedUser.restaurant_id)
            .map((res) => ({ value: res.id, label: res.name })),
        );
      }
    }
  }, [restaurants]);

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="user-form-container">
          <section className="row">
            <ImageUploader
              inputName={"avatar"}
              image={avatar}
              handleSetValue={setValue}
            />
            <section className="user-main-info">
              <section className="user-name">
                <label>Nombre</label>
                <input
                  name="first_name"
                  className="form-control"
                  defaultValue={first_name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="user-name">
                <label>Apellido</label>
                <input
                  name="last_name"
                  className="form-control"
                  defaultValue={last_name}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="user-name">
                <label>Email</label>
                <input
                  name="email"
                  className="form-control"
                  defaultValue={email}
                  ref={register({
                    required: true,
                    message: "Este campo es requerido",
                  })}
                />
              </section>
              <section className="row">
                <section className="form-group">
                  <label htmlFor="restaurant_id">Restaurant</label>
                  <Select
                    name="restaurant_id"
                    value={restaurantsSelect}
                    placeholder="Seleccione"
                    options={restaurants?.map((rest) => ({
                      label: rest.name,
                      value: rest.id,
                    }))}
                    onChange={(value, action) => {
                      handleChange(value, action, setRestaurantsSelect);
                    }}
                    styles={cartearSelectStyles}
                    isDisabled={
                      loggedUser.role === "restaurant_admin" ? true : false
                    }
                  />
                </section>
                <section className="separator"></section>
                <section className="form-group">
                  <label htmlFor="role">Rol</label>
                  <Select
                    name="role"
                    value={rolesSelect}
                    placeholder="Seleccione"
                    options={roles?.map((rol) => ({
                      label: rol.label,
                      value: rol.value,
                    }))}
                    onChange={(value, action) => {
                      handleChange(value, action, setRolesSelect);
                      setIsBranchUser(
                        value.value == BRANCH_USER ? true : false,
                      );
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
              <section className="row">
                <section className="form-group">
                  <label htmlFor="send_invitation">
                    Enviar mail de registro
                  </label>
                  <Select
                    name="send_invitation"
                    value={sendMailSelect}
                    placeholder="Seleccione"
                    options={sendMailOptions?.map((op) => ({
                      label: op.label,
                      value: op.value,
                    }))}
                    onChange={(value, action) => {
                      handleChange(value, action, setSendMailSelect);
                    }}
                    styles={cartearSelectStyles}
                  />
                </section>
              </section>
            </section>
          </section>
        </section>

        {isBranchUser && (
          <section className="row">
            <section className="form-group">
              <label>Sucursal Principal</label>
              <Select
                name="mainBranch"
                value={mainBranchSelect}
                placeholder="Seleccione"
                options={branches.map((branch) => ({
                  label: branch.name,
                  value: branch.id,
                }))}
                onChange={(value, action) => {
                  handleChange(
                    value,
                    action,
                    setMainBranchSelect,
                    "secondaryBranches",
                  );
                }}
                styles={cartearSelectStyles}
              />
            <section className="separator"></section>
            <section className="user-permissions-container">
              <h2>Permisos</h2>
              {permissions.map((up) => {
                return (
                  <div className="user-permissions" key={up.id}>
                    <input
                      type="checkbox"
                      defaultChecked={
                        userPermissions?.find((p) => p.name === up.name)
                          ?.value || false
                      }
                      onChange={(e) => {
                        let newUserPermissions = userPermissions?.map((nup) => {
                          if (nup.name === up.name) {
                            return { ...nup, value: e.target.checked };
                          } else {
                            return nup;
                          }
                        });
                        if (
                          !newUserPermissions?.find(
                            (nup) => nup.name === up.name,
                          )
                        ) {
                          const newPermission = {
                            name: up.name,
                            value: e.target.checked,
                            permission_id: up.id,
                          };
                          newUserPermissions = newUserPermissions
                            ? newUserPermissions?.concat(newPermission)
                            : [newPermission];
                        }
                        setUserPermissions(newUserPermissions);
                      }}
                    />
                    <label>{up.name}</label>
                  </div>
                );
              })}
            </section>
            </section>
            {false && (
              <section className="form-group">
                <label>
                  Sucursales Secundarios
                  <span className="helper-text">(Podés elegir más de uno)</span>
                </label>
                <Select
                  name="secondaryBranches"
                  value={secondaryBranchesSelect.filter((b) => !b._destroy)}
                  isMulti
                  placeholder="Seleccione"
                  options={secondBranches
                    .map((branch) => ({
                      label: branch.name,
                      value: { branch_id: branch.id, primary: false },
                    }))
                    .filter((b) => b.label !== mainBranchSelect.label)
                    .filter(
                      (b) =>
                        !secondaryBranchesSelect
                          .filter((b) => !b._destroy)
                          .map((b) => b.label)
                          .includes(b.label),
                    )}
                  onChange={(value, action) => {
                    handleChange(value, action, setSecondaryBranchesSelect);
                  }}
                  styles={cartearSelectStyles}
                />
              </section>
            )}
          </section>
        )}

        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
