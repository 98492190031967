//Dependencies
import React from "react";

//Components
import MenuForm from "../components/MenuForm";

const MenuNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nuevo Menu</h3>
      </div>
      <MenuForm action={"create"} />
    </section>
  );
};

export default MenuNew;
