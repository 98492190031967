//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import ChangePasswordForm from "../components/ChangePasswordForm";

//Assets
import { setSelectedEntity, setCurrentSection } from "../actions";

//Utils
import { get } from "../utils/services";

const UserChangePassword = (props) => {
  const { setSelectedEntity, selectedEntity, setCurrentSection } = props;
  const { id } = useParams();

  useEffect(() => {
    setCurrentSection("Cambiar Contraseña");
    axios.get(get.users(id)).then((res) => {
      setSelectedEntity(res.data);
    });
  }, []);

  return (
    <section className="container">
      <div className="form-title">
        <h3>Cambiar Contraseña</h3>
      </div>
      <ChangePasswordForm action={"edit"} {...selectedEntity} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
  setCurrentSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword);
