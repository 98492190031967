export const datatableActions = {
  restaurants: [
    {
      name: "edit",
      action: (id) => `/restaurants/${id}/edit`,
    },
    {
      name: "models3D",
      action: (id) => `/restaurants/${id}/models`,
    },
    {
      name: "loginAs",
      action: (id) => `/restaurants/${id}/login-as`,
    },
    {
      name: "branches",
      action: (id) => `/restaurants/${id}/branches`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  branches: [
    {
      name: "edit",
      action: (id, restaurant_id) =>
        `/restaurants/${restaurant_id}/branches/${id}/edit`,
    },
    {
      name: "loginAs",
      action: (id, restaurant_id) =>
        `/restaurants/${restaurant_id}/branches/${id}/login-as`,
    },
    {
      name: "delete",
      action: "delete",
    },
    {
      name: "translations",
      action: (id) => `/branches/${id}/translations`,
    },
  ],
  products: [
    {
      name: "edit",
      action: (id) => `/products/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/products/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
    {
      name: "clone",
      action: (id) => `/products/${id}/clone`,
    },
  ],
  allergens: [
    {
      name: "edit",
      action: (id) => `/allergens/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/allergens/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  tags: [
    {
      name: "edit",
      action: (id) => `/tags/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/tags/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  currencies: [
    {
      name: "edit",
      action: (id) => `/currencies/${id}/edit`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  countries: [
    {
      name: "edit",
      action: (id) => `/countries/${id}/edit`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  billing: [
    {
      name: "payed",
      action: (id) => `/billing/${id}/edit`,
    },
    {
      name: "unpayed",
      action: (id) => `/billing/${id}/edit`,
    },
  ],
  payment_methods: [
    {
      name: "translations",
      action: (id) => `/payment_methods/${id}/translations`,
    },
  ],
  delivery_methods: [
    {
      name: "translations",
      action: (id) => `/delivery_methods/${id}/translations`,
    },
  ],
  users: [
    {
      name: "edit",
      action: (id) => `/users/${id}/edit`,
    },
    {
      name: "sendEmail",
      action: "send_email",
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  menus: [
    {
      name: "edit",
      action: (id) => `/menus/${id}/edit`,
    },
    {
      name: "assign",
      action: "assign",
    },
    {
      name: "translations",
      action: (id) => `/menus/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  categories: [
    {
      name: "edit",
      action: (id) => `/categories/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/categories/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  toppings: [
    {
      name: "edit",
      action: (id) => `/toppings/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/toppings/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  advertisings: [
    {
      name: "edit",
      action: (id) => `/advertisings/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/advertisings/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  accompaniments: [
    {
      name: "edit",
      action: (id) => `/accompaniments/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/accompaniments/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  variants: [
    {
      name: "edit",
      action: (id) => `/variants/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/variants/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  models: [
    {
      name: "edit",
      action: (id) => `/models/${id}/edit`,
    },
  ],
  plans: [
    {
      name: "edit",
      action: (id) => `/plans/${id}/edit`,
    },
    {
      name: "Promociones",
      action: (id) => `/plans/${id}/plan_promotions`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  plan_promotions: [
    {
      name: "edit",
      action: (prom_id, na, plan_id) =>
        `/plans/${plan_id}/plan_promotions/${prom_id}/edit`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  mobile_versions: [
    {
      name: "edit",
      action: (id) => `/mobile_versions/${id}/edit`,
    },
    {
      name: "translations",
      action: (id) => `/mobile_versions/${id}/translations`,
    },
    {
      name: "delete",
      action: "delete",
    },
  ],
  suggestions: [
    {
      name: "show",
      action: (id) => `/suggestions/${id}`,
    },
  ],
  notifications: [
    {
      name: "show",
      action: (id) => `/notifications/${id}`,
    },
  ],
  payment_transactions: [],
};
