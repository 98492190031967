import React, { useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { connect } from "react-redux";
import axios from "axios";

//Components
import CreateTableModal from "../components/CreateTableModal";

//Assets
import AddIcon from "@material-ui/icons/Add";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import "../stylesheets/Lounge.css";

//Utils
import * as actions from "../actions";
import { getAll, filter } from "../utils/services";

const swal = withReactContent(Swal);

const LoungeHeader = ({
  loggedUser,
  filteredItems,
  setTables,
  clearTables,
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const { restaurant_id, branch_id } = loggedUser;

  useEffect(() => {
    const localStorageItem = localStorage.getItem("table_filter");
    let parsedItem;

    axios.get(getAll.custom_filters).then((res) => {
      setSortOptions(res.data.map((f) => ({ value: f.value, label: f.es })));
      if (!localStorageItem) {
        localStorage.setItem("table_filter", JSON.stringify(res.data[0]));
        parsedItem = res.data[0];
        setSelectedSortOption({
          value: parsedItem.value,
          label: parsedItem.es,
        });
      } else {
        parsedItem = JSON.parse(localStorageItem);
        setSelectedSortOption(parsedItem);
      }
    });
  }, []);

  const handleCreateTable = () => {
    swal.fire({
      title: "Crear nueva Mesa",
      html: (
        <CreateTableModal
          TableModal
          loggedUser={loggedUser}
          tables={filteredItems}
          setTables={setTables}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleChangeFilter = (value) => {
    const stringifiedItem = JSON.stringify(value);
    localStorage.setItem("table_filter", stringifiedItem);
    setSelectedSortOption(value);

    axios
      .get(filter.tables(restaurant_id, branch_id, value.value))
      .then((res) => {
        clearTables();
        setTables(res.data.tables);
      });
  };

  return (
    <section className="lounge__header">
      <div className="page__title">
        <h3>Gestión de Mesas</h3>
      </div>
      <div className="header__buttons">
        <section className="filter-by__section">
          <label htmlFor="mainLanguages">Ordenar mesas por: </label>
          <Select
            name="translations_attributes[0][locale]"
            placeholder="Seleccione"
            value={selectedSortOption}
            options={sortOptions}
            onChange={(value) => handleChangeFilter(value)}
            styles={cartearSelectStyles}
          />
        </section>
        <section className="create-section">
          <div className="create-button" onClick={() => handleCreateTable()}>
            <AddIcon />
            CREAR MESA
          </div>
        </section>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    filteredItems: state.filteredItems,
  };
};

export default connect(mapStateToProps, actions)(LoungeHeader);
