//Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Assets
import Logo from "../images/logo-white.png";
import "../stylesheets/lateralMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ArrowUp from "@material-ui/icons/KeyboardArrowUp";
import * as actions from "../actions";

//Utils
import "../utils/fontawesome";
import menuItems from "../utils/partials.json";
import icons from "../utils/icons";

const LateralMenu = (props) => {
  const { t } = useTranslation();
  const { loggedUser, setCurrentSection } = props;
  let USER_ROLE = window.localStorage.getItem("userRole") || loggedUser?.role;
  const [showSubItems, setShowSubItems] = useState(false);

  const handleClick = (e, hasSubItems) => {
    const oldElementActived = document.querySelector(".active");
    if (oldElementActived) {
      oldElementActived.classList.remove("active");
    }
    e.target.parentElement.classList.add("active");

    if (e.target.innerText === "Emails") {
      setShowSubItems(!showSubItems);
    } else {
      setCurrentSection(e.target.innerText);
    }
  };

  return (
    <aside className="lateral-menu-container">
      <Link to="/">
        <div className="menu-logo-container">
          <img src={Logo} alt="Cartear Logo" />
        </div>
      </Link>
      <nav className="lateral-menu">
        <ul className="lateral-menu-list">
          {loggedUser &&
            menuItems[USER_ROLE].navItems.map((item, index) => {
              const { icon, sub_items } = item;
              return sub_items && icon ? (
                <li
                  className="lateral-menu-item"
                  key={`${item}-${index}`}
                  onClick={handleClick.bind(this)}
                >
                  <section className="d-flex align-items-center justify-content-space-between">
                    <div>
                      <FontAwesomeIcon icon={["fas", icon]} />
                      <span>{t(`lateralMenu.${item.name}`)}</span>
                    </div>
                    <div className="p-0 w-auto">
                      {showSubItems ? <ArrowUp /> : <ArrowDown />}
                    </div>
                  </section>
                  {showSubItems && (
                    <ul className="lateral-menu-list">
                      {item.sub_items.map((sub_item) => {
                        return (
                          <li
                            className="lateral-menu-item"
                            key={`${sub_item}-${index}`}
                            onClick={handleClick.bind(this)}
                          >
                            <Link to={sub_item.link}>
                              <FontAwesomeIcon icon={["fas", sub_item.icon]} />
                              <span>{sub_item.name}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              ) : icon !== "" ? (
                <li
                  className={`lateral-menu-item ${
                    icon === "home" ? "active" : ""
                  }`}
                  id={`menu-item-${item.name}`} 
                  key={`${item}-${index}`}
                  onClick={handleClick.bind(this)}
                >
                  <Link to={item.link}>
                    {icons[icon] ? (
                      <img src={icons[icon]} />
                    ) : (
                      <FontAwesomeIcon icon={["fas", icon]} />
                    )}
                    <span>{t(`lateralMenu.${item.name}`)}</span>
                  </Link>
                </li>
              ) : (
                <li key={`${item}-${index}`}>
                  <section className="lateral-menu-title d-flex">
                    <span>{item.name} </span>
                  </section>
                </li>
              );
            })}
        </ul>
      </nav>
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(LateralMenu);
