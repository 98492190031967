import React, { useState } from "react";
import axios from "axios";

//Utils
import { close } from "../utils/services";
import { modalTableSuccessful, modalTableError } from "../utils/modals";

const CloseTableModal = ({ loggedUser, table_id, table_name, updateTable }) => {
  const { restaurant_id, branch_id } = loggedUser;

  const handleEditTableName = () => {
    let table = {
      id: table_id,
      name: table_name,
      branch_id: branch_id,
    };

    axios
      .put(close.tables(restaurant_id, branch_id, table_id), { table })
      .then((res) => {
        modalTableSuccessful("cerrada", table.name);
        handleCloseModal();

        updateTable(res.data);
      })
      .catch((e) => {
        modalTableError("cerrar", table.name);
      });
  };

  const handleCloseModal = () => {
    const closeButton = document.querySelector(".swal2-close");
    closeButton.click();
  };

  return (
    <div>
      <section className="form-group d-flex justify-content-center align-items-center pt-40">
        <h4>¿Estás seguro de que deseas cerrar la mesa {table_name}?</h4>
      </section>
      <span className="d-inline-block mb-40">
        Si confirma, la mesa quedará sin pedidos.
      </span>
      <section className="d-flex justify-content-center align-items-center">
        <div className="secondary-button mr-16" onClick={handleEditTableName}>
          Confirmar
        </div>
        <div className="primary-button ml-16" onClick={handleCloseModal}>
          Cancelar
        </div>
      </section>
    </div>
  );
};

export default CloseTableModal;
