//Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Components
import ImageUploader from "./ImageUploader";

//Assets
import "../stylesheets/TagForm.css";
import * as actions from "../actions";

//Utils
import { create, update } from "../utils/services";
import { modalTagSuccessful, modalTagError } from "../utils/modals";

const ENTITY = "tags";

const TagForm = (props) => {
  const { t } = useTranslation();
  const { id, translations, image, action, updateTag, showLoadingOverlay } =
    props;

  const name =
    translations?.filter(({ locale }) => locale === "es")[0]?.description ||
    null;

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  useEffect(() => {
    register("image");
  }, []);

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    if (action === "create") {
      axios
        .post(create[ENTITY], { tag: data })
        .then(() =>
          modalTagSuccessful(
            "creado",
            data.translations_attributes[0].description,
            t,
            () => history.goBack()
          )
        )
        .then(() => e.target.reset())
        .catch(() =>
          modalTagError("crear", data.translations_attributes[0].description, t)
        )
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](null, id), { tag: data })
        .then(() => {
          data.id = id;
          updateTag(data);
        })
        .then(() =>
          modalTagSuccessful(
            "actualizado",
            data.translations_attributes[0].description,
            t,
            () => history.goBack()
          )
        )
        .catch(() =>
          modalTagError(
            "actualizar",
            data.translations_attributes[0].description,
            t
          )
        )
        .finally(() => showLoadingOverlay(false));
    }
  };

  return (
    <section>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="tag-form-container">
          <section className="row">
            <ImageUploader
              inputName={"image"}
              image={image}
              handleSetValue={setValue}
            />
            <div className="tag-images-separator" />
          </section>
        </section>
        <section className="row pb-20">
          <span className="helper-text pl-0">
            Las fotos que cargues deberán ser siempre circulares.
          </span>
        </section>
        <section className="row">
          <section className="tag-main-info">
            <section className="tag-name">
              <label>Nombre la etiqueta</label>
              <input
                name="translations_attributes[0][description]"
                className="form-control"
                defaultValue={name}
                ref={register({
                  required: true,
                  message: "Este campo es requerido",
                })}
              />
              <input
                type="hidden"
                name="translations_attributes[0][att]"
                value="name"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][locale]"
                value="es"
                ref={register}
              />
              <input
                type="hidden"
                name="translations_attributes[0][translatable_type]"
                value="Allergen"
                ref={register}
              />
            </section>
          </section>
        </section>
        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(TagForm);
