//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setBranches,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearBranches,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "branches";

const Branches = (props) => {
  const {
    setHasMore,
    setNextPage,
    setBranches,
    setIsLoading,
    clearBranches,
    loggedUser,
  } = props;

  const { id } = useParams();
  const BRANCHES_URL = getAll.branches(
    id ? id : loggedUser.restaurant_id,
    loggedUser.role == "branch_user" ? loggedUser.id : null
  );
  useEffect(() => {
    clearBranches();
    setIsLoading(true);
    axios
      .get(BRANCHES_URL)
      .then((res) => {
        if (loggedUser.role == "branch_user") {
          const branches = res.data;

          setBranches(branches);

          CheckHasMore(null, null, setHasMore, setNextPage);
          return;
        }

        const { branches } = res.data;

        setBranches(branches);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setBranches,
  clearBranches,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Branches);
