//Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

//Components
import CardPlanType from "../components/CardPlanType";
import Datatable from "../components/Datatable";
import PlanAndPaymentHire from "./PlanAndPaymentHire";
import PageLoader from "../components/PageLoader";

//Assets
import "../stylesheets/dashboard.css";

//Utils
import { getAll, get, remove } from "../utils/services";
import * as actions from "../actions";
import {
  modalCancelSuscriptionSuccessful,
  modalCancelSuscriptionError,
} from "../utils/modals";

const ENTITY = "payment_transactions";

const PlanAndPayment = ({
  loggedUser: { restaurant_id, branch_id },
  payment_transactions,
  setPaymentTransactions,
  clearPaymentTransactions,
  setPaymentId,
  showLoadingOverlay,
}) => {
  const [indexPlan, setIndexPlan] = useState(-1);
  const [plans, setPlans] = useState([]);
  const [expireSoon, setExpireSoon] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showCardPlans, setShowCardPlans] = useState("d-none");
  const [planIdToRenew, setPlanIdToRenew] = useState(null);

  useEffect(() => {
    if (indexPlan === -1) {
      clearPaymentTransactions();
      setPaymentId(null);
      axios
        .get(get.plans(null, restaurant_id))
        .then((res) => setPlans(res.data.plans))
        .then(() => {
          axios
            .get(getAll.payment_transactions(restaurant_id, branch_id))
            .then((res) => {
              const { payment_transactions } = res.data;
              setShowCardPlans(getCardPlansClass(payment_transactions));
              setPaymentTransactions(payment_transactions);

              if (
                payment_transactions[payment_transactions.length - 1]
                  ?.due_date_expired
              ) {
                setExpireSoon(true);
                setPlanIdToRenew(payment_transactions[0].plan_country.plan_id);
              }
            });
        })
        .finally(() => setIsLoading(false));
    }
  }, [indexPlan]);

  const handleRenewSuscription = () => {
    document.querySelectorAll(".hire-button")[0].click();
    setPaymentId(id);
  };

  const getCardPlansClass = (pymt) => {
    if (
      pymt.length > 0 &&
      (pymt.some((pt) => pt.state === "pending") ||
        pymt.some((pt) => pt.state === "no_paid"))
    )
      return "d-none";

    return "";
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="dashboard" id="id-de-prueba">
      {indexPlan === -1 ? (
        <>
          <div className={`dashboard-card-list ${showCardPlans}`}>
            {plans.map((planType, index) => {
              planType.index = index;
              planType.setIndexPlan = setIndexPlan;
              return (
                <CardPlanType
                  key={index}
                  {...planType}
                  planIdToRenew={planIdToRenew}
                  expireSoon={expireSoon}
                />
              );
            })}
          </div>
          <Datatable entity={ENTITY} />
        </>
      ) : indexPlan !== null ? (
        <PlanAndPaymentHire {...plans[indexPlan]} setIndexPlan={setIndexPlan} />
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    payment_transactions: state.payment_transactions,
  };
};

export default connect(mapStateToProps, actions)(PlanAndPayment);
