//Dependencies
import React from "react";
import { connect } from "react-redux";

//Assets
import "../stylesheets/datatableActions.css";
import { setSelectedEntity } from "../actions";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const DatatableActions = (props) => {
  const { entity, setSelectedEntity } = props;

  const handleDropdown = (e) => {
    setSelectedEntity(entity);
    let actions_menu = document.querySelector(".actions-menu");

    actions_menu.style.left = `${e.pageX - 200}px`;
    actions_menu.style.top = `${e.pageY}px`;
    actions_menu.classList.toggle("show");
  };

  window.onclick = function (e) {
    const { target } = e;
    const { parentElement } = target;

    if (!target?.matches(".actions") && !parentElement?.matches(".actions")) {
      const dropdowns = document?.getElementsByClassName("actions-menu");
      if(dropdowns) {
        for (let i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    }
  };

  return (
    <div className="actions" onClick={handleDropdown}>
      <MoreVertIcon className="actions-icon actions" />
    </div>
  );
};

const mapDispatchToProps = {
  setSelectedEntity,
};

export default connect(null, mapDispatchToProps)(DatatableActions);
