//Dependencies
import React from "react";

//Components
import CategoryForm from "../components/CategoryForm";

const CategoryNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva categoría</h3>
      </div>
      <CategoryForm action={"create"} />
    </section>
  );
};

export default CategoryNew;
