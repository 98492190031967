const basePath = "/backoffice/v1/";

export const getAll = {
  permissions: `${basePath}permissions`,
  restaurants: `${basePath}restaurants`,
  branches: (rest_id, user_id) =>
    !rest_id
      ? `${basePath}branches`
      : !user_id
        ? `${basePath}restaurants/${rest_id}/branches`
        : `${basePath}restaurants/${rest_id}/users/${user_id}/branches`,
  allergens: (id) =>
    !id ? `${basePath}allergens` : `${basePath}restaurants/${id}/allergens`,
  tags: (id) => (!id ? `${basePath}tags` : `${basePath}restaurants/${id}/tags`),
  payment_methods: `${basePath}payment_methods`,
  delivery_methods: `${basePath}delivery_methods`,
  users: (rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/users`
      : rest_id
        ? `${basePath}restaurants/${rest_id}/users`
        : `${basePath}users`,
  currencies: (id) =>
    !id ? `${basePath}currencies` : `${basePath}restaurant/${id}/currencies`,
  countries: `${basePath}countries`,
  billing: `${basePath}billing`,
  mobile_versions: `${basePath}mobile_versions`,
  menus: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/menus`
      : `${basePath}restaurants/${id}/menus`,
  categories: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/categories`
      : `${basePath}restaurants/${id}/categories`,
  products: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/products`
      : `${basePath}restaurants/${id}/products`,
  toppings: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/toppings`
      : `${basePath}restaurants/${id}/toppings`,
  accompaniments: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/accompaniments`
      : `${basePath}restaurants/${id}/accompaniments`,
  advertisings: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/advertisings`
      : `${basePath}restaurants/${id}/advertisings`,
  variants: (id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${id}/branches/${branch_id}/variants`
      : `${basePath}restaurants/${id}/variants`,
  suggestions: `${basePath}suggestions`,
  topics: `${basePath}topics`,
  languages: `${basePath}languages`,
  plans: `${basePath}plans`,
  notifications: `${basePath}notifications`,
  payment_transactions: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/payment_transactions`,
  payment_platforms: `${basePath}payment_platforms`,
  tables: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables`,
  custom_filters: `${basePath}tables/custom_filters`,
};

export const filter = {
  restaurants: (query) => `.${basePath}restaurants?filters=${query}`,
  branches: (query, id) =>
    !id
      ? `${basePath}branches?filters=${query}`
      : `${basePath}restaurants/${id}/branches?filters=${query}`,
  menus: (query, rest_id, branch_id) =>
    !branch_id
      ? `${basePath}restaurants/${rest_id}/menus?filters=${query}`
      : `${basePath}restaurants/${rest_id}/branches/${branch_id}/menus?filters=${query}`,
  products: (query, id) =>
    `${basePath}restaurants/${id}/products?filters=${query}`,
  models: (query, id) =>
    `${basePath}restaurants/${id}/models3d?filters=${query}`,
  allergens: (query, id) =>
    !id
      ? `${basePath}allergens?filters=${query}`
      : `${basePath}restaurants/${id}/allergens?filters=${query}`,
  advertisings: (query, rest_id) =>
    `${basePath}restaurants/${rest_id}/advertisings?filters=${query}`,
  tags: (query, id) =>
    !id
      ? `${basePath}tags?filters=${query}`
      : `${basePath}restaurants/${id}/allergens?filters=${query}`,
  payment_methods: (query, id) =>
    !id
      ? `${basePath}payment_methods?filters=${query}`
      : `${basePath}restaurants/${id}/payment_methods?filters=${query}`,
  delivery_methods: (query, id) =>
    !id
      ? `${basePath}delivery_methods?filters=${query}`
      : `${basePath}restaurants/${id}/delivery_methods?filters=${query}`,
  users: (query) => `${basePath}users?filters=${query}`,
  mobile_versions: (query) => `${basePath}mobile_versions?filters=${query}`,
  currencies: (query, id) =>
    !id
      ? `${basePath}currencies?filters=${query}`
      : `${basePath}restaurants/${id}/currencies?filters=${query}`,
  categories: (query, id) =>
    `${basePath}restaurants/${id}/categories?filters=${query}`,
  toppings: (query, id) =>
    `${basePath}restaurants/${id}/toppings?filters=${query}`,
  accompaniments: (query, id) =>
    `${basePath}restaurants/${id}/accompaniments?filters=${query}`,
  variants: (query, id) =>
    `${basePath}restaurants/${id}/variants?filters=${query}`,
  plans: (query) => `${basePath}plans?filters=${query}`,
  suggestions: (query) => `${basePath}suggestions?filters=${query}`,
  countries: (query) => `${basePath}countries?filters=${query}`,
  tables: (rest_id, branch_id, filter) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables?sort=${filter}`,
};

export const sort = {
  restaurants: (dir) => `.${basePath}restaurants?sort[name]=${dir}`,
  branches: (id, dir) =>
    !id
      ? `${basePath}branches?sort[name]=${dir}`
      : `${basePath}restaurants/${id}/branches?sort[name]=${dir}`,
  products: (id, dir) =>
    `${basePath}restaurants/${id}/products?filters[locale]=es&sort[title]=${dir}`,
  allergens: (dir) =>
    `${basePath}allergens?filters[locale]=es&sort[name]=${dir}`,
  tags: (dir) => `${basePath}tags?filters[locale]=es&sort[name]=${dir}`,
  payment_methods: (dir) =>
    `${basePath}payment_methods?filters[locale]=es&sort[value]=${dir}`,
  delivery_methods: (dir) =>
    `${basePath}delivery_methods?filters[locale]=es&sort[value]=${dir}`,
  users: (rest_id, dir) =>
    rest_id
      ? `${basePath}/restaurants/${rest_id}/users?sort[first_name]=${dir}`
      : `${basePath}users?sort[first_name]=${dir}`,
  mobile_versions: (dir) =>
    `${basePath}mobile_versions?sort[first_name]=${dir}`,
  currencies: (dir) =>
    `${basePath}currencies?filters[locale]=es&sort[value]=${dir}`,
  categories: (id, dir) =>
    `${basePath}restaurants/${id}/categories?filters[locale]=es&sort[name]=${dir}`,
  menus: (id, dir) =>
    `${basePath}restaurants/${id}/menus?filters[locale]=es&sort[name]=${dir}`,
  toppings: (id, dir) =>
    `${basePath}restaurants/${id}/toppings?sort[value]=${dir}`,
  accompaniments: (id, dir) =>
    `${basePath}restaurants/${id}/accompaniments?sort[value]=${dir}`,
  variants: (id, dir) =>
    `${basePath}restaurants/${id}/variants?sort[value]=${dir}`,
  plans: (dir) => `${basePath}plans?sort[value]=${dir}`,
  countries: (dir) => `${basePath}countries?sort[name]=${dir}`,
};

export const create = {
  restaurants: `${basePath}restaurants`,
  branches: (id) => `${basePath}restaurants/${id}/branches/`,
  allergens: `${basePath}allergens/`,
  tags: `${basePath}tags/`,
  currencies: `${basePath}currencies/`,
  countries: `${basePath}countries`,
  users: `${basePath}users/`,
  categories: (rest_id, prod_id) =>
    !prod_id
      ? `${basePath}restaurants/${rest_id}/categories`
      : `${basePath}restaurants/${rest_id}/product/${prod_id}/categories`,
  products: (rest_id) => `${basePath}restaurants/${rest_id}/products`,
  import_products: (rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/products/import`
      : `${basePath}restaurants/${rest_id}/products/import`,
  toppings: (rest_id, prod_id) =>
    !prod_id
      ? `${basePath}restaurants/${rest_id}/toppings`
      : `${basePath}restaurants/${rest_id}/product/${prod_id}/toppings`,
  accompaniments: (rest_id, prod_id) =>
    !prod_id
      ? `${basePath}restaurants/${rest_id}/accompaniments`
      : `${basePath}restaurants/${rest_id}/product/${prod_id}/accompaniments`,
  advertisings: (rest_id, branch_id) =>
    !branch_id
      ? `${basePath}restaurants/${rest_id}/advertisings`
      : `${basePath}restaurants/${rest_id}/branches/${branch_id}/advertisings`,
  variants: (rest_id, prod_id) =>
    !prod_id
      ? `${basePath}restaurants/${rest_id}/variants`
      : `${basePath}restaurants/${rest_id}/product/${prod_id}/variants`,
  plans: `${basePath}plans`,
  mobile_versions: `${basePath}mobile_versions`,
  menus: (rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/menus`
      : `${basePath}restaurants/${rest_id}/menus`,
  notifications: `${basePath}notifications`,
  payment_transactions: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/payment_transactions`,
  paypal_billing: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/paypal_billings`,
  mail_template: () => `${basePath}mail_templates`,
  tables: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables`,
};

export const clone = {
  menus: (rest_id, menu_id) =>
    `${basePath}restaurants/${rest_id}/menus/${menu_id}/clone`,
  products: (rest_id, product_id) =>
    `${basePath}restaurants/${rest_id}/products/${product_id}/clone`,
};

export const get = {
  loggedUser: `${basePath}users/logged_user`,
  restaurants: (id) => `${basePath}restaurants/${id}`,
  branches: (branch_id, rest_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}`,
  allergens: (id) => `${basePath}allergens/${id}`,
  tags: (id) => `${basePath}tags/${id}`,
  currencies: (id) => `${basePath}currencies/${id}`,
  countries: (id) => `${basePath}countries/${id}`,
  users: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}/restaurants/${rest_id}/branches/${branch_id}/users/${id}`
      : rest_id
        ? `${basePath}/restaurants/${rest_id}/users/${id}`
        : `${basePath}/users/${id}`,
  categories: (id, rest_id, branch_id) =>
    !!id
      ? `${basePath}restaurants/${rest_id}/categories/${id}`
      : branch_id
        ? `${basePath}restaurants/${rest_id}/branches/${branch_id}categories/${id}`
        : `${basePath}restaurants/${rest_id}/categories/`,
  products: (id, rest_id) => `${basePath}restaurants/${rest_id}/products/${id}`,
  menus: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/menus/${id}`
      : `${basePath}restaurants/${rest_id}/menus/${id}`,
  toppings: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/toppings/${id}`
      : `${basePath}restaurants/${rest_id}/toppings/${id}`,
  advertisings: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/advertisings/${id}`
      : `${basePath}restaurants/${rest_id}/advertisings/${id}`,
  accompaniments: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/accompaniments/${id}`
      : `${basePath}restaurants/${rest_id}/accompaniments/${id}`,
  variants: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/variants/${id}`
      : `${basePath}restaurants/${rest_id}/variants/${id}`,
  plans: (id, rest_id) =>
    !rest_id
      ? `${basePath}plans/${id}`
      : `${basePath}plans?restaurant_id=${rest_id}`,
  mobile_versions: (id) => `${basePath}mobile_versions/${id}`,
  restaurants_stadistics: (rest_id, branch_id) =>
    !!branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/dashboard`
      : !!rest_id
        ? `${basePath}restaurants/${rest_id}/resume`
        : `${basePath}restaurants/dashboard`,
  payment_methods: (id) => `${basePath}payment_methods/${id}`,
  delivery_methods: (id) => `${basePath}delivery_methods/${id}`,
  models: (id) => `${basePath}restaurants/${id}/models3d`,
  active_subscriptions: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/active_subscriptions`,
  mail_template: (mail_type) => `${basePath}mail_templates/${mail_type}`,
  recover_password: "/recover_password",
  tables: (rest_id, branch_id, table_id) =>
    `${basePath}/restaurants/${rest_id}/branches/${branch_id}/tables/${table_id}`,
};

export const update = {
  restaurants: (na, id) => `${basePath}restaurants/${id}`,
  branches: (rest_id, id) => `${basePath}restaurants/${rest_id}/branches/${id}`,
  allergens: (na, id) => `${basePath}allergens/${id}`,
  tags: (na, id) => `${basePath}tags/${id}`,
  users: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/users/${id}`
      : rest_id
        ? `${basePath}restaurants/${rest_id}/users/${id}`
        : `${basePath}users/${id}`,
  currencies: (na, id) => `${basePath}currencies/${id}`,
  countries: (na, id) => `${basePath}countries/${id}`,
  categories: (rest_id, id) =>
    `${basePath}restaurants/${rest_id}/categories/${id}`,
  products: (rest_id, prod_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/products/${prod_id}`
      : `${basePath}restaurants/${rest_id}/products/${prod_id}`,
  menus: (rest_id, id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/menus/${id}`
      : `${basePath}restaurants/${rest_id}/menus/${id}`,
  toppings: (rest_id, id) => `${basePath}restaurants/${rest_id}/toppings/${id}`,
  advertisings: (rest_id, id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/advertisings/${id}`
      : `${basePath}restaurants/${rest_id}/advertisings/${id}`,
  accompaniments: (rest_id, id) =>
    `${basePath}restaurants/${rest_id}/accompaniments/${id}`,
  variants: (rest_id, id) => `${basePath}restaurants/${rest_id}/variants/${id}`,
  plans: (id) => `${basePath}plans/${id}`,
  mobile_versions: (id) => `${basePath}mobile_versions/${id}`,
  payment_methods: (na, id) => `${basePath}payment_methods/${id}`,
  delivery_methods: (na, id) => `${basePath}delivery_methods/${id}`,
  payment_transactions: (rest_id, branch_id, payment_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/payment_transactions/${payment_id}`,
  mail_template: (mail_type) => `${basePath}mail_templates/${mail_type}`,
  password: "/update_password",
  password_from_profile: "/update_password_from_profile",
  tables: (rest_id, branch_id, table_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables/${table_id}`,
  orders: (rest_id, branch_id, table_id, order_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables/${table_id}/orders/${order_id}`,
};

export const remove = {
  restaurants: (id) => `${basePath}restaurants/${id}`,
  branches: (id, rest_id) => `${basePath}restaurants/${rest_id}/branches/${id}`,
  allergens: (id) => `${basePath}allergens/${id}`,
  tags: (id) => `${basePath}tags/${id}`,
  users: (id) => `${basePath}users/${id}`,
  currencies: (id) => `${basePath}currencies/${id}`,
  countries: (id) => `${basePath}countries/${id}`,
  menus: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/menus/${id}`
      : `${basePath}restaurants/${rest_id}/menus/${id}`,
  advertisings: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/advertisings/${id}`
      : `${basePath}restaurants/${rest_id}/advertisings/${id}`,
  categories: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/categories/${id}`
      : `${basePath}restaurants/${rest_id}/categories/${id}`,
  toppings: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/toppings/${id}`
      : `${basePath}restaurants/${rest_id}/toppings/${id}`,
  products: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/products/${id}`
      : `${basePath}restaurants/${rest_id}/products/${id}`,
  accompaniments: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/products/${id}`
      : `${basePath}restaurants/${rest_id}/accompaniments/${id}`,
  variants: (id, rest_id, branch_id) =>
    branch_id
      ? `${basePath}restaurants/${rest_id}/branches/${branch_id}/variants/${id}`
      : `${basePath}restaurants/${rest_id}/variants/${id}`,
  plans: (id) => `${basePath}plans/${id}`,
  plan_promotions: (plan_id) => `${basePath}plans/${plan_id}`,
  mobile_versions: (id) => `${basePath}mobile_versions/${id}`,
  cancel_subscription: (rest_id, branch_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/payment_transactions/cancel`,
  tables: (rest_id, branch_id, table_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables/${table_id}`,
};

export const send = {
  mail: (rest_id, user_id) =>
    `${basePath}restaurants/${rest_id}/users/${user_id}/send_email`,
  recovery_pass_mail: "/send_recover_password_email",
};

export const close = {
  tables: (rest_id, branch_id, table_id) =>
    `${basePath}restaurants/${rest_id}/branches/${branch_id}/tables/${table_id}/clear`,
};
