//Dependencies
import React, { useState, useEffect } from "react";

//Assets
import unavailableImage from "../images/unavailable-image.jpg";
import "../stylesheets/ProfileImageUploader.css";
import RemoveIcon from "@material-ui/icons/DeleteOutline";

const ProfileImageUploader = ({
  inputName,
  image,
  handleSetValue,
  setCategoryImage = null,
}) => {
  const [entityImage, setEntityImage] = useState(unavailableImage);

  useEffect(() => {
    if (!!image) setEntityImage(image);
  }, [image]);

  const handleUpload = () => {
    const inputFile = document.querySelector(`#upload-${inputName}`);
    inputFile.click();
  };

  const handleImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setEntityImage(reader.result);
        handleSetValue(e.target.name, reader.result);

        if (!!setCategoryImage) {
          setCategoryImage(reader.result);
        }
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleRemoveImage = () => {
    setEntityImage(unavailableImage);

    if (!!setCategoryImage) {
      setCategoryImage(null);
    }

    const inputFile = document.querySelector(`#upload-${inputName}`);
    inputFile.value = null;
    handleSetValue("avatar", "");
  };

  return (
    <section className="profile-container">
      <section className="container-outline">
        <section className="profile-previewimage-container">
          <img
            src={entityImage}
            alt="Entity Image"
            id={`entity-${inputName}`}
            className="profile-previewimage"
          />
          <input
            type="file"
            id={`upload-${inputName}`}
            name={inputName}
            accept=".jpeg, .jpg, .png"
            onChange={handleImage}
          />
        </section>
      </section>
      {entityImage !== unavailableImage && (
        <span
          className="button-remove-profile-previewimage"
          onClick={handleRemoveImage}
        >
          <RemoveIcon />
        </span>
      )}
      <section
        className="profile-upload-previewimage-button"
        onClick={handleUpload}
      >
        +
      </section>
    </section>
  );
};

export default ProfileImageUploader;
