//Dependencies
import React from "react";

//Components
import MobileVersionForm from "../components/MobileVersionForm";

const MobileVersionNew = () => {
  return (
    <section className="container">
      <div className="form-title">
        <h3>Nueva Version</h3>
      </div>
      <MobileVersionForm action={"create"} />
    </section>
  );
};

export default MobileVersionNew;
