import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Components
import Table from "../components/Table";
import EditTableModal from "./EditTableModal";
import CloseTableModal from "./CloseTableModal";

//Assets
import "../stylesheets/LoungeTables.css";

//Utils
import * as actions from "../actions";
import { remove } from "../utils/services";
import { modalDeleteEntity } from "../utils/modals";

const swal = withReactContent(Swal);

const LoungeTables = (props) => {
  const { t } = useTranslation();
  const { filteredItems, selectedEntity, loggedUser, updateTable } = props;
  const { restaurant_id, branch_id } = loggedUser;
  const { id, name, is_active, is_removable } = selectedEntity;

  const handleEditTableName = () => {
    swal.fire({
      title: "Editar Mesa",
      html: (
        <EditTableModal
          TableModal
          loggedUser={loggedUser}
          table_name={name}
          table_id={id}
          tables={filteredItems}
          updateTable={updateTable}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleRemoveTable = () => {
    modalDeleteEntity(
      "tables",
      selectedEntity,
      remove.tables(restaurant_id, branch_id, selectedEntity.id),
      props
    );
  };

  const handleCloseTable = () => {
    swal.fire({
      title: "Cerrar Mesa",
      html: (
        <CloseTableModal
          TableModal
          loggedUser={loggedUser}
          table_id={id}
          table_name={name}
          updateTable={updateTable}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="tables__count">
          <label className="text">Número total de Mesas</label>
          <label className="quantity">{filteredItems.length}</label>
        </div>
        <div className="tables__container">
          {filteredItems.map((table) => (
            <Table table={table} />
          ))}
        </div>
      </div>
      <div className="actions-table">
        <ul className="actions-table-list">
          {is_active ? (
            <>
              <li className="actions-menu-item">
                <Link to={`./lounge/tables/${id}/summary`}>Ver Resumen</Link>
              </li>
              <li className="actions-menu-item">
                <Link to={`./lounge/tables/${id}/orders`}>Ver Pedidos</Link>
              </li>
              <li className="actions-menu-item">
                <button type="button" onClick={() => handleCloseTable()}>
                  Cerrar Mesa
                </button>
              </li>
              {is_removable && (
                <li className="actions-menu-item">
                  <button type="button" onClick={() => handleRemoveTable()}>
                    Borrar Mesa
                  </button>
                </li>
              )}
            </>
          ) : (
            <>
              <li className="actions-menu-item">
                <button type="button" onClick={() => handleEditTableName()}>
                  Editar Mesa
                </button>
              </li>
              {is_removable && (
                <li className="actions-menu-item">
                  <button type="button" onClick={() => handleRemoveTable()}>
                    Borrar Mesa
                  </button>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredItems: state.filteredItems,
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(LoungeTables);
