//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setAdvertisings,
  setHasMore,
  setNextPage,
  setIsLoading,
  setActiveMenu,
  clearAdvertisings,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "advertisings";

const Advertisings = (props) => {
  const {
    setHasMore,
    setNextPage,
    setAdvertisings,
    setIsLoading,
    clearAdvertisings,
    setActiveMenu,
    loggedUser,
  } = props;

  const { id } = useParams();
  const ADVERTISINGS_URL = getAll.advertisings(
    id ? id : loggedUser.restaurant_id,
    loggedUser.role == "branch_user" ? loggedUser.branch_id : null,
  );

  useEffect(() => {
    clearAdvertisings();
    setIsLoading(true);
    axios
      .get(ADVERTISINGS_URL)
      .then((res) => {
        const { advertisings } = res.data;

        setAdvertisings(advertisings);
        setActiveMenu(
          advertisings.find(
            (m) => m.is_visible && m.branch_id?.toString() === loggedUser.branch_id,
          ),
        );

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setAdvertisings,
  clearAdvertisings,
  setHasMore,
  setNextPage,
  setIsLoading,
  setActiveMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Advertisings);
