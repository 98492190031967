import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Components
import OrderStatus from "../components/OrderStatus";
import UpdateOrderStatusModal from "../components/UpdateOrderStatusModal";
import ChangeOrderTableModal from "../components/ChangeOrderTableModal";

//Assets
import "../stylesheets/TableSummary.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import cartearSelectStyles from "../utils/cartearSelectStyles";

//Utils
import { order_status_options } from "../utils/selectValues";
import { update } from "../utils/services";

const swal = withReactContent(Swal);

const TableOrderInfo = ({ order, orderStatus, table }) => {
  const [orderStatusSelected, setOrderStatusSelected] = useState(orderStatus);
  const [collapseIsOpened, setCollapseIsOpened] = useState(true);
  const [allowEditNotes, setAllowEditNotes] = useState(false);
  const [ownerDescription, setOwnerDescription] = useState("");
  const { restaurant_id, branch_id } = useSelector((state) => state.loggedUser);
  const tables = useSelector((state) => state.filteredItems);
  const { table_id } = table.id;

  const handleUpdateOrderStatus = (
    oldStatus,
    newStatus,
    setStateFunction,
    table_id,
    order_id
  ) => {
    swal.fire({
      title: "Actualizar estado del Pedido",
      html: (
        <UpdateOrderStatusModal
          oldStatus={oldStatus}
          newStatus={newStatus}
          setNewStatus={setStateFunction}
          restaurant_id={restaurant_id}
          branch_id={branch_id}
          table_id={table_id}
          order_id={order_id}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleChangeOrderTableModal = (table_id, order_id) => {
    swal.fire({
      title: "Cambiar pedido a otra mesa",
      html: (
        <ChangeOrderTableModal
          tables={tables}
          restaurant_id={restaurant_id}
          branch_id={branch_id}
          table_id={table_id}
          order_id={order_id}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleChange = (value, setStateFunction, table_id, order_id) => {
    handleUpdateOrderStatus(
      orderStatusSelected.value,
      value,
      setStateFunction,
      table_id,
      order_id
    );
  };

  const handleGetTotal = (order) => {
    let table_total = 0;

    order.consumables.map((consumable) => {
      if (consumable.dish) {
        table_total =
          parseFloat(table_total) +
          parseFloat((consumable.dish.price * consumable.quantity).toFixed(2));
      } else if (consumable.dish_topping) {
        table_total =
          parseFloat(table_total) +
          parseFloat(
            (
              consumable.dish_topping.topping.price * consumable.quantity
            ).toFixed(2)
          );
      } else if (consumable.dish_variant) {
        table_total =
          parseFloat(table_total) +
          parseFloat(
            (
              consumable.dish_variant.variant.price * consumable.quantity
            ).toFixed(2)
          );
      } else if (consumable.dish_accompaniment) {
        table_total =
          parseFloat(table_total) +
          parseFloat(
            (
              consumable.dish_accompaniment.accompaniment.price *
              consumable.quantity
            ).toFixed(2)
          );
      }
    });

    return `$ ${table_total.toFixed(2)}`;
  };

  const handleSaveOwnerDescription = (table_id, order_id) => {
    const order = {
      owner_description: ownerDescription,
    };

    axios
      .put(update.orders(restaurant_id, branch_id, table_id, order_id), order)
      .then(() => setAllowEditNotes(false));
  };

  return (
    <>
      <section
        className="order-header d-flex align-items-center justify-content-space-between"
        onClick={() => setCollapseIsOpened((prevState) => !prevState)}
      >
        <section
          className={`collapse-icon-${collapseIsOpened ? "close" : "open"}`}
        >
          {collapseIsOpened ? <RemoveIcon /> : <AddIcon />}
        </section>
        <section className="order-name">
          <h2>Pedido {order.id}</h2>
        </section>
        <span className="fs-24 text-bold"> - </span>
        <OrderStatus status={orderStatusSelected.value} />
        <section className="order-separator"></section>
      </section>
      <Collapse isOpened={collapseIsOpened}>
        <section className="order__body">
          <section className="order-info">
            <section className="order-info__title d-flex justify-content-space-between align-items-center">
              <h2>Información</h2>
              <section className="separator"></section>
            </section>
            <section className="row justify-content-end w-80 ml-32">
              <div
                className="secondary-button "
                onClick={() => handleChangeOrderTableModal(table.id, order.id)}
              >
                Cambiar pedido a otra mesa
              </div>
            </section>
            <section className="order-info__body">
              <sections className="order__container">
                <section className="row">
                  <section className="w-40 mr-16">
                    <section className="form-group">
                      <label htmlFor="name">
                        Nombre y Apellido del Comensal
                      </label>
                      <input
                        name="name"
                        className="form-control"
                        value={`${order.mobile_user.firstname} ${order.mobile_user.lastname}`}
                        readOnly={true}
                      />
                    </section>
                  </section>
                  <section className="w-40 ml-16">
                    <section className="form-group">
                      <label htmlFor="name">Fecha y Hora</label>
                      <input
                        name="name"
                        className="form-control order-date"
                        value={table.last_time_active}
                        readOnly={true}
                      />
                    </section>
                  </section>
                </section>
                <section className="row">
                  <section className="w-40 d-flex mr-16">
                    <section className="form-group mr-16">
                      <label htmlFor="name">ID de Pedido</label>
                      <input
                        name="name"
                        className="form-control"
                        value={order.id}
                        readOnly={true}
                      />
                    </section>
                    <section className="form-group ml-16">
                      <label htmlFor="name">Mesa</label>
                      <input
                        name="name"
                        className="form-control"
                        value={table.name}
                        readOnly={true}
                      />
                    </section>
                  </section>
                  <section className="w-40 ml-16">
                    <section className="form-group">
                      <label htmlFor="name">Estado</label>
                      <Select
                        name="order-status"
                        placeholder="Seleccione"
                        value={order_status_options.filter(
                          (status) => status.value === orderStatusSelected.value
                        )}
                        options={order_status_options}
                        onChange={(value) => {
                          handleChange(
                            value,
                            setOrderStatusSelected,
                            table.id,
                            order.id
                          );
                        }}
                        styles={cartearSelectStyles}
                      />
                    </section>
                  </section>
                  <section className="mx-auto">
                    <section className="d-flex justify-content-center">
                      <span>Precio Total</span>
                    </section>
                    <section className="d-flex justify-content-center">
                      <span className="text-bold mt-24 fs-24">
                        {handleGetTotal(order)}
                      </span>
                    </section>
                  </section>
                </section>
                <section className="row">
                  <section className="w-40 d-flex mr-16">
                    <section className="form-group">
                      <label>Aclaraciones del Cliente</label>
                      <textarea
                        name="customer-clarifications"
                        className="form-control customer-clarifications"
                        value={order.customer_description}
                        readOnly={true}
                      />
                    </section>
                  </section>
                  <section className="w-40 d-flex ml-16 flex-column">
                    <section className="form-group">
                      <label>Cambios y notas adicionales del Restaurant</label>
                      <textarea
                        name="customer-clarifications"
                        className="form-control customer-clarifications"
                        defaultValue={order.owner_description}
                        value={
                          ownerDescription != ""
                            ? ownerDescription
                            : order.owner_description
                        }
                        onChange={(e) => setOwnerDescription(e.target.value)}
                        readOnly={!allowEditNotes}
                      />
                    </section>
                    <section className="d-flex justify-content-end align-items-center">
                      {!allowEditNotes && (
                        <div
                          className="secondary-button"
                          onClick={() => setAllowEditNotes(true)}
                        >
                          Editar notas
                        </div>
                      )}
                      {allowEditNotes && (
                        <>
                          <div
                            className="secondary-button mx-16"
                            onClick={() =>
                              handleSaveOwnerDescription(table.id, order.id)
                            }
                          >
                            Guardar notas
                          </div>
                          <div
                            className="primary-button ml-16"
                            onClick={() => setAllowEditNotes(false)}
                          >
                            Cancelar
                          </div>
                        </>
                      )}
                    </section>
                  </section>
                </section>
              </sections>
            </section>
          </section>
          <section className="order-info">
            <section className="order-info__title d-flex justify-content-space-between align-items-center">
              <h2>Productos</h2>
              <section className="separator"></section>
            </section>
            <section className="order-info__body">
              <sections className="order__container">
                <section className="row">
                  <section className="w-40 mr-16">
                    <section className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <input
                        name="name"
                        className="form-control"
                        value={order.consumables[0].name}
                        readOnly={true}
                      />
                    </section>
                  </section>
                  <section className="w-20 ml-16">
                    <section className="form-group">
                      <label htmlFor="name">Cantidad</label>
                      <input
                        name="name"
                        className="form-control order-date"
                        value={order.consumables[0].quantity}
                        readOnly={true}
                      />
                    </section>
                  </section>
                  <section className="w-20 ml-16">
                    <section className="form-group">
                      <label htmlFor="name">Precio</label>
                      <input
                        name="name"
                        className="form-control order-date"
                        value={order.consumables[0].price}
                        readOnly={true}
                      />
                    </section>
                  </section>
                </section>
                {order.consumables[1]?.dish_topping && (
                  <section className="row">
                    <section className="w-40">
                      <section className="form-group">
                        <label htmlFor="name">Toppings</label>
                        <Select
                          name="order-status"
                          placeholder="Seleccione"
                          value={[
                            {
                              value:
                                order.consumables[1]?.dish_topping.topping.id,
                              label:
                                order.consumables[1]?.dish_topping.topping.name,
                            },
                          ]}
                          styles={cartearSelectStyles}
                          isDisabled={true}
                          isMulti
                        />
                      </section>
                    </section>
                  </section>
                )}
                {order.consumables[2]?.dish_variant && (
                  <section className="row">
                    <section className="w-40">
                      <section className="form-group">
                        <label htmlFor="name">Variante</label>
                        <Select
                          name="order-status"
                          placeholder="Seleccione"
                          value={[
                            {
                              value:
                                order.consumables[2]?.dish_variant.variant.id,
                              label:
                                order.consumables[2]?.dish_variant.variant.name,
                            },
                          ]}
                          styles={cartearSelectStyles}
                          isDisabled={true}
                          isMulti
                        />
                      </section>
                    </section>
                  </section>
                )}
                {order.consumables[3]?.dish_accompaniment && (
                  <section className="row">
                    <section className="w-40">
                      <section className="form-group">
                        <label htmlFor="name">Acompañamientos</label>
                        <Select
                          name="order-status"
                          placeholder="Seleccione"
                          value={[
                            {
                              value:
                                order.consumables[3]?.dish_accompaniment
                                  .accompaniment.id,
                              label:
                                order.consumables[3]?.dish_accompaniment
                                  .accompaniment.name,
                            },
                          ]}
                          styles={cartearSelectStyles}
                          isDisabled={true}
                          isMulti
                        />
                      </section>
                    </section>
                  </section>
                )}
                <section className="row">
                  <section className="w-40 d-flex">
                    <section className="form-group">
                      <label>Comentario</label>
                      <textarea
                        name="customer-clarifications"
                        className="form-control customer-clarifications"
                        value={order.comments}
                        readOnly={true}
                      />
                    </section>
                  </section>
                </section>
              </sections>
            </section>
          </section>
        </section>
      </Collapse>
    </>
  );
};

export default TableOrderInfo;
