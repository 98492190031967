import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import cartearSelectStyles from "../utils/cartearSelectStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 500,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    height: "100px !important",
    overflowY: "auto",
  },
}));

export default function MultipleSelect({
  options,
  setSelectedProducts,
  groupBy = null,
}) {
  const classes = useStyles();

  const handleChange = (value) => {
    setSelectedProducts(value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="multiselectAutocomplete"
          options={options}
          multiple
          getOptionLabel={(option) => option.title}
          style={{ width: 500 }}
          onChange={(_, v) => handleChange(v)}
          groupBy={groupBy}
          disableCloseOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.textField}
            />
          )}
        />
      </FormControl>
    </div>
  );
}
